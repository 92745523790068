import {useCallback, useMemo, useRef, useState} from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";
import { useEffect } from "react";

import { AskResponse, ButtonFollowupAnswer, citationsApi, citationsCompareApi, CitationsRequest, CitationsRequestHotIssue, getCitationFilePath, issue_buttons, ITrackSearch, multiple_data_points } from "../../api";
import { parseAnswerToHtml, stripHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { AnswerInteract } from "./AnswerInteract";
import { AnswerFeedback } from "./AnswerFeedback";
import close from "../../assets/close.png"

import SpeechPlayer from "./SpeechPlayer";
import { PopoverAnswer } from "../Common";
import { filter, forEach, isEmpty, replace } from "lodash";
import { substringUrl } from "../../utils/substringUrl";
import { isShowConfiguration } from "../../utils/isShowConfiguration";
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";
import React from "react";
import { parseSupportingContentItem } from "../SupportingContent/SupportingContentParser";
import { multiIncludes, NOT_FOUND } from "../../utils/multiIncludes";
import { AnswerFollowup } from "./AnswerFollowup";
import { openWindowToUrl } from "../../utils/openWindowUrl";
import { useParams } from "react-router-dom";
import { Dismiss20Regular } from "@fluentui/react-icons";
import { TransitionSearchingDone } from "./generate_searching/TransitionSearchingDone";
import { Card, Tab, Tabs } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";
import { AnswerInteractCitation } from "./AnswerInteractCitation";
import AnswerInteractRate from "./AnswerInteractRate";
import showToasty from "../../hooks/useNotify";
import Charts from "./charts/Charts";
import GalleryImages from "./images/GalleryImages";

export const colorStatus = (string?: string) => {
    switch (string) {
        case "Chưa xử lý":
            return "var(--color-date-message)";
        case "Đang xử lý":
            return "var(--color-date-message)";
        case "Chờ duyệt":
            return "#fa9427";
        case "Đã xử lý":
            return "#6bc20e";
    }
};

const CITATION_DAFAULT_COUNT = 5

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string | CitationsRequestHotIssue) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onSend?: (q: string) => void
    showFollowupQuestions?: boolean;
    isPlayVoive?: any;
    disable_switch_history?: boolean | undefined,
    is_used_history?: boolean | undefined,
}

export const Answer = React.memo(observer(({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    isPlayVoive,
    onSend,
    disable_switch_history,
    is_used_history
}: Props) => {
    const {
        rootStore: { citationsStore, sessionChatsStore },
      } = useStore();
      const params = useParams();
    const [openFeedback, setOpenFeedback] = useState<boolean>( false)
    const [actionFeedback, setActionFeedback] = useState<string>('')
    const [openThankFeedback, setOpenThankFeedback] = useState<boolean>( false)
    const [isExperience, setIsExperience] = useState<boolean>( false)
    const [citationLinks, setCitationLinks] = useState<any>(citationsStore.getCitationLink)
    const [suggestQuestion, setSuggestQuestion] = useState<string[]>([])
    const dataCitition = useRef<any>(null);
    const [keyActiveCitation, setKeyActiveCitation] = useState('relatedIssueFaqs');
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [showAllCitations, setShowAllCitations] = useState(false);

    const { use_list_citation, multiple_data_points } = answer || {};

    const displayedDataPoints: multiple_data_points[] = useMemo(() => 
        showAllCitations ? multiple_data_points || [] : (multiple_data_points?.slice(0, CITATION_DAFAULT_COUNT) || []),
        [showAllCitations, multiple_data_points]
    );

    const toggleShowAll = () => {
        setShowAllCitations(!showAllCitations);
    };
    const handleHidePopover = () => {
        setShowPopover(false)
        };
    const handleShowPopover = () => {
        setShowPopover(true)
    }

    const parsedAnswer = useMemo(() => {
        // Gợi ý - followup question
        let myArray: any[] = [];
        let recommends : string[] = answer && answer.recommends && answer.recommends?.length ? answer.recommends : []
        setSuggestQuestion(recommends)
        
        const result = parseAnswerToHtml(answer.answer, onCitationClicked, answer.data_points, answer.use_fixed_citation, !isEmpty(answer?.working_process_steps), answer?.reference_urls, answer?.reference_titles, handleShowPopover, handleHidePopover, showPopover)
        if (result.answerText) {
            result.answerText = stripHtml(result.answerText)
        }
    
        return  result
    },[answer]);

    const handleClickTree = (e: any) => {
        const pattern = /data-citation-styles/;
        const checkPattern = pattern.test(e.target.className);
        if (checkPattern) {
            let dataType = e?.target?.dataset?.type;
            let idTree = e?.target?.dataset?.id;
            e.preventDefault();
            e.stopPropagation();
            const citationRequest = {
                type: dataType,
                id: idTree,
                content: ""
            };
            onCitationClicked(citationRequest);
        }
    };

    const handleSendRecommentButtons = async (v: ButtonFollowupAnswer) => {
        await v.type === 'text' ? sessionChatsStore.changeQuestionFollowup(v.text) : sessionChatsStore.SET_GENERIC_BUTTON(v.text);
        onsendFollowupQuestion(v.text_display)
    }


    useEffect(() => {
        setCitationLinks(citationsStore.getCitationLink)
    }, [citationsStore.getCitationLink])

    const sanitizedAnswerHtml = DOMPurify.sanitize(( parsedAnswer).answerHtml);
    const closeThankFeedback = () => {
        setOpenThankFeedback(false)
    }

    const firstUpdate = useRef(false);
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        const url = window.location.href;
        if (url && url.endsWith('experience/chat')) {
            setIsExperience(true)
        } else {
            setIsExperience(false)
        }

        firstUpdate.current = true;
        return () => {};
    }, []);


    const getTopDataPoints = (datapoints: any, scores: any[]) => {

        if (datapoints?.length > 0) {
            let topDatapoins: any = [];
            for (let i = 0; i < scores.length; i++) {
                if (scores[i] >= 0.8) { 
                    if (topDatapoins.length === 3) {
                        break;
                    }
                    topDatapoins.push(datapoints[i]);
                }
            }  
            if(topDatapoins.length === 0) {
                for (let i = 0; i < scores.length; i++) {
                    if (scores[i] >= 0.75) {
                        if (topDatapoins.length === 2) {
                            break;
                        }
                        topDatapoins.push(datapoints[i]);
                    }
                }  
            }
            if (topDatapoins.length === 0) {
                topDatapoins = [datapoints[0]];
            }                      
            return topDatapoins;
        }
    }

    const UNDEFINED_REGEX_REFERNCE = /(đang trích dẫn|đang cập nhật)/i;

    const checkUndefinedreference = (reference?: string) => {
        if(reference) {
            const checkCitation = UNDEFINED_REGEX_REFERNCE.test(reference.toLowerCase());
            return checkCitation
        }
        return false
    }

    const checkreference = (urls: string[]) => {
        let count = 0
        if(urls && urls?.length) {
            urls.forEach((v: any) => {
                if(!checkUndefinedreference(v)) {
                    count = count + 1
                }
            })
        }
        return count
    }
    const handleClickStepSearch = (continue_step_search: number) => {
        if (!isEmpty(sessionChatsStore.getMessageRating)) {
            showToasty("Vui lòng hoàn thành đánh giá!", "error");
            return;
        }
        sessionChatsStore.SET_STEP_SEARCH(continue_step_search)
        if (onSend) {
            onSend(answer.question)
        }
    }
    const handleClickExpandedSearch = () => {
        if (!isEmpty(sessionChatsStore.getMessageRating)) {
            showToasty("Vui lòng hoàn thành đánh giá!", "error");
            return;
        }
        sessionChatsStore.SET_EXPANDED_SEARCH(true)
        if (onSend) {
            onSend(answer.question)
        }
    }

    const onsendFollowupQuestion = async(q: string) => {
        if (!isEmpty(sessionChatsStore.getMessageRating)) {
            showToasty("Vui lòng hoàn thành đánh giá!", "error");
            return;
        }
        await sessionChatsStore.changeNeedSearchSpecific(answer?.need_search_specific)
        await sessionChatsStore.changeIsClickSpecific(true)

        if(onSend) {
            onSend(q)
        }
    }
    const getNormalizedName = (normalizedName: string, name: string) => {
        return (
            <>
                { name && (
                    <div className="document-type">
                        <span className="document-type-text">{name}</span>
                    </div>
                )}
            </>
        )
    }
    const onClickStacticCitation = (content: string, title: string) => {
        if(keyActiveCitation === 'relatedIssueFaqs') {
            sessionChatsStore.SET_CLICK_EXPANDED_FAQ(
                {isExpandedFaq: true,
                title: content, 
                content: title,
                isSearchFaq: false
                }
            )
        }else {
            sessionChatsStore.SET_CLICK_EXPANDED_FAQ(
                {isExpandedFaq: false,
                title: content, 
                content: title,
                isSearchFaq: true
                }
            )
        }
        onCitationClicked(content)
    }


    const rederStacticCitationHotIssue = (expanded: any, content: string, type: string, answer: any) => {
        return (
            <Card className="reference-citation-card">
                <Card.Body>
                    {/* {getNormalizedName(expanded?.normalizedName, content)} */}
                    <div className="document-name" onClick={(e: any) => {
                        if (e.target?.className === 'show-more-less-clickable') return
                        onClickStacticCitation(content, expanded?.articleContent)
                    }}>
                        <ShowMoreText
                            lines={3}
                            more="Xem thêm"
                            less="Ẩn bớt"
                            className="content-css"
                            anchorClass="show-more-less-clickable"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                        >
                            {content}
                        </ShowMoreText>

                    </div>
                    <div className="document-tags">
                        {expanded.field && (
                            <span className="document-tag">{expanded.field}</span>
                        )}
                        {expanded.meetingOrder && (
                            <span className="document-tag">{expanded.meetingOrder}</span>
                        )}
                        {expanded.issueGroup && (
                            <span className="document-tag">Nhóm vấn đề: {expanded.issueGroup}</span>
                        )}
                        {expanded.documentType && (
                            <span className="document-tag">Loại văn bản: {expanded.documentType}</span>
                        )}
                         {expanded.documentCode && (
                            <span className="document-tag">Mã văn bản: {expanded.documentCode}</span>
                        )}
                        {expanded.petitionAgency && (
                            <span className="document-tag">Đối tượng kiến nghị: {expanded.petitionAgency}</span>
                        )}
                        {expanded.petitionDate && (
                            <span className="document-tag">Ngày kiến nghị: {expanded.petitionDate}</span>
                        )}
                        {expanded.dateIssued && (
                            <span className="document-tag">Ngày ban hành: {expanded.dateIssued}</span>
                        )}
                        {expanded.petitionType && (
                            <span className="document-tag">Loại kiến nghị: {expanded.petitionType}</span>
                        )}
                        {expanded.validityStatus && (
                            <span className="document-tag">Trạng thái: {expanded.validityStatus}</span>
                        )}
                        {expanded.topic && (
                            <span className="document-tag">Chủ đề: {expanded.topic}</span>
                        )}
                        {expanded.unit && (
                            <span className="document-tag">Đơn vị: {expanded.unit}</span>
                        )}
                    </div>
                    <Stack.Item>
                        <AnswerInteractCitation
                            messageId={expanded.id}
                            answer={answer} sanitizedAnswerHtml={undefined} answerText={""} onSetActionFeedback={function (a: string): void {
                                throw new Error("Function not implemented.");
                            }} />
                    </Stack.Item>

                </Card.Body>
            </Card>
        )
    }

    const referenceUrls = useCallback(() => {
        let referencesDatapoints: any[] = []
        const isCheckReference = parsedAnswer.citations?.length === 0 && answer.reference_urls?.length === 0 && answer.reference_docs?.length === 0 && answer?.is_legal && !multiIncludes(answer?.answer, NOT_FOUND)
        if(isCheckReference) {
            referencesDatapoints = getTopDataPoints(answer.data_points, answer.semantic_scores)     
            referencesDatapoints = filter(referencesDatapoints, (x: any) => {
                const path = parseSupportingContentItem(x);
                return path.title.trim()
            })
        }
        return referencesDatapoints
    }, [parsedAnswer.citations])

    useEffect(() => {
        if(params?.id) {
            setOpenFeedback(false)
            setOpenThankFeedback(false)
        }
    }, [params])

    useEffect(() => {
        if(actionFeedback) {
            setOpenThankFeedback(false)
        }
    }, [actionFeedback])

    return (
        <>
            {answer.track_search && answer.track_search.length ?
                <Stack.Item className="box-transition">
                    {answer.track_search.map((trackSearch: ITrackSearch) => (
                        <TransitionSearchingDone trackSearch={trackSearch} />
                    ))}
                </Stack.Item> : null}

            <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        {/* <div className="" style={{backgroundColor: "#dbeefa", borderRadius: '50%', padding: '2px'}}>
                            <AnswerIcon />
                        </div> */}
                        <div className="" style={{ padding: '2px'}}>
                            <AnswerIcon />
                        </div>
                        <div>
                            {isShowConfiguration() ? (
                                <>
                                    <IconButton
                                        style={{ color: "var(--text-primary-color)" }}
                                        iconProps={{ iconName: "Lightbulb" }}
                                        title="Show thought process"
                                        className="iconFluentUi"
                                        ariaLabel="Show thought process"
                                        onClick={() => onThoughtProcessClicked()}
                                        disabled={!answer?.thoughts}
                                    />
                                    <IconButton
                                        style={{ color: "var(--text-primary-color)" }}
                                        iconProps={{ iconName: "ClipboardList" }}
                                        title="Show supporting content"
                                        className="iconFluentUi"
                                        ariaLabel="Show supporting content"
                                        onClick={() => onSupportingContentClicked()}
                                        disabled={!answer?.data_points?.length}
                                    />
                                </>
                            ) : null}
                            <SpeechPlayer text={parsedAnswer.answerText} isPlayVoive={isPlayVoive}/>
                        </div>
                    </Stack>
                </Stack.Item>

                <Stack.Item grow>
                    <div
                        className={styles.answerText}
                        id="idAnswerText"
                        dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
                        onClick={e => handleClickTree(e)}
                    ></div>
                </Stack.Item>
                {

                (answer.data_chart && answer.data_chart?.length) ?

                    <Charts dataChart={answer.data_chart} title_chart={answer.title_chart} type_chart={answer.type_chart}/> : null

                }

                {/* <Charts dataChart={answer.data_chart}/> */}

                {

                (answer.process_images && answer.process_images?.length) ?

                    <GalleryImages images={answer.process_images}/> : null

                }
                {
                    !!(answer?.working_process_steps && answer?.working_process_steps?.length) && (
                        <Stack.Item>
                            <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                <>
                                    <span className={styles.citationLearnMore}>Quy trình: </span>
                                    {answer?.working_process_steps.map((x, i) => {
                                        return (
                                            <div key={`quytrinh_${i}`} className={styles.quoteContainer}>
                                                <a className={styles.citation} title={x?.title} onClick={() => onCitationClicked(JSON.stringify(x ?? {}))}>
                                                    {`${x?.title}`}
                                                </a>
                                            </div>
                                        );
                                    })}
                                </>
                            </Stack>
                        </Stack.Item>
                    )
                }
                {answer?.recommend_buttons && answer.recommend_buttons.length ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }} className="custom-stack">
                            {answer.recommend_buttons.map((x, i) => {
                                // const path = parseSupportingContentItem(x);
                                return (
                                    <a key={i} className={styles.citation} style={{width: 'max-content'}} title={x.text_display} onClick={() => handleSendRecommentButtons(x)}>
                                        {`${i + 1}. ${x.text_display}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                ) : null}
                <>
                {answer?.reference_docs && answer.reference_docs.length ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <>
                                <span className={styles.citationLearnMore}>Trích dẫn:</span>
                                {answer?.reference_docs.map((x, i) => {
                                    return (
                                        <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(x)}>
                                            {`${i + 1}. ${x}`}
                                        </a>
                                    );
                                })}
                            </>
                        </Stack>
                    </Stack.Item>
                ) : 
                <>
                {!!parsedAnswer.citations.length && !answer.not_show_citation && !answer.reference_titles?.length ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        {
                            answer?.use_fixed_citation ?
                                <>
                                    <span className={styles.citationLearnMore}>Trích dẫn:</span>
                                    {parsedAnswer.citations.map((x, i) => {

                                        const path = parseSupportingContentItem(x)
                                        return (
                                            <div key={i} className={styles.quoteContainer}>
                                                <a className={styles.citation} title={path?.title} onClick={() => onCitationClicked(x)}>
                                                    {`${i + 1}. ${path?.title}`}
                                                </a>
                                                {
                                                    citationLinks?.length &&
                                                        citationLinks[i]?.noteContents?.length ?
                                                        <PopoverAnswer noteContents={citationLinks[i]?.noteContents} className={styles.popoverButtonBox} /> : null
                                                }
                                            </div>
                                        );
                                    })}
                                </> :
                                <>
                                    <span className={styles.citationLearnMore}>Trích dẫn:</span>
                                    {parsedAnswer.citations.map((x, i) => {
                                        const path = getCitationFilePath(x);
                                        return (
                                            <div key={i} className={styles.quoteContainer}>
                                                <a className={styles.citation} title={x} onClick={() => onCitationClicked(answer?.use_fixed_citation ? x : path)}>
                                                    {`${i + 1}. ${x}`}
                                                </a>
                                                {
                                                    citationLinks?.length &&
                                                        citationLinks[i]?.noteContents?.length ?
                                                        <PopoverAnswer noteContents={citationLinks[i]?.noteContents} className={styles.popoverButtonBox} /> : null
                                                }
                                            </div>
                                        );
                                    })}
                                </>
                            }

                        </Stack>
                    </Stack.Item>
                ) : null}</>
                }

                {answer?.reference_titles && answer.reference_titles.length ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Tham khảo:</span>
                            {answer.reference_titles.map((x, i) => {
                                // const path = parseSupportingContentItem(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(x)}>
                                        {`${i + 1}. ${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                ) : null}

                {use_list_citation && displayedDataPoints.length ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Tham khảo:</span>
                            {displayedDataPoints.map((x, i) => {
                                return (
                                    <a key={i} className={styles.citation} title={x.title} onClick={() => onCitationClicked(x.title)}>
                                        {`${i + 1}. ${x.title}`}
                                    </a>
                                );
                            })}
                        </Stack>
                        {
                            !!(multiple_data_points.length > CITATION_DAFAULT_COUNT) &&
                            <button onClick={toggleShowAll} className={styles.seeMoreButton}>
                                {showAllCitations ? 'Ẩn bớt' : 'Xem thêm'}
                            </button>
                        }
                    </Stack.Item>
                ) : null}
              
                {referenceUrls()?.length > 0 && !answer.not_show_citation && !answer.reference_titles?.length && !answer?.use_list_citation ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Tham khảo:</span>
                            {referenceUrls().map((x, i) => {
                                const path = parseSupportingContentItem(x);
                                return (
                                    <a key={i} className={styles.citation} title={path.title} onClick={() => onCitationClicked(answer?.use_fixed_citation ? x : path.title)}>
                                        {`${i + 1}. ${path.title}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                ) : null}

                {answer?.reference_urls && answer.reference_urls.length && !answer.reference_titles?.length && checkreference(answer.reference_urls) ? (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Tham khảo:</span>
                            {answer.reference_urls.map((x, i) => {
                                const path = x;
                                return (
                                    <div key={i}  className="">
                                        {!checkUndefinedreference(x) &&
                                            <a className={styles.citation} title={x} onClick={() => window.open(path)}>
                                                {`${++i}. ${substringUrl(x)}`}
                                            </a>
                                        }
                                    </div> 
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                ) : null}
                </>

                {/* {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked ? (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }} style={{paddingTop: '6px'}}>
                            <span className={styles.followupQuestionLearnMore}>Gợi ý:</span>
                            {parsedAnswer.followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                ) : null} */}

                {/* {!!suggestQuestion.length && onFollowupQuestionClicked ? (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }} style={{paddingTop: '6px'}}>
                            <span className={styles.followupQuestionLearnMore}>Gợi ý:</span>
                            {suggestQuestion.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => {sessionChatsStore.changeIsClickFollowupQuestion(true) ,onFollowupQuestionClicked(x)}}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                ) : null} */}
                {
                    answer && answer?.is_expanded_search ? (
                        <Tabs
                            id="citation-tabs"
                            activeKey={keyActiveCitation}
                            onSelect={(k: any) => setKeyActiveCitation(k)}
                            className="mb-3 reference-citation-tabs"
                        >


                            <Tab eventKey="relatedIssueFaqs" title={`Câu hỏi tình huống (${answer.faqs?.length})`}>
                                {answer.faqs && answer.faqs.length ? answer.faqs.map((expanded: any, key: any) => {
                                    return (
                                        <>
                                            {expanded.displayArticleTitle && (
                                                rederStacticCitationHotIssue(expanded, expanded.displayArticleTitle, 'expanded_faq', answer)
                                            )}
                                        </>
                                    )
                                }) :
                                    <div>Không có dữ liệu...</div>}
                            </Tab>

                            <Tab eventKey="relatedMinisterAnswerFaqs" title={`Văn bản của Tỉnh (${answer.global_articles?.length})`}>
                                {answer.global_articles && answer.global_articles.length ? answer.global_articles.map((expanded: any, key: any) => {
                                    return (
                                        <>
                                            {expanded.displayArticleTitle && (
                                                rederStacticCitationHotIssue(expanded, expanded.displayArticleTitle, 'expanded_global_articles', answer)
                                            )}
                                        </>
                                    )
                                }) :
                                    <div>Không có dữ liệu...</div>}
                            </Tab>

                            <Tab eventKey="relatedPetitionFaqs" title={`Văn bản của Trung ương (${answer.specific_articles?.length})`}>
                                {answer.specific_articles && answer.specific_articles.length ? answer.specific_articles.map((expanded: any, key: any) => {
                                    return (
                                        <>
                                            {expanded.displayArticleTitle && (
                                                rederStacticCitationHotIssue(expanded, expanded.displayArticleTitle, 'expanded_specific_articles', answer)
                                            )}
                                        </>
                                    )
                                }) :
                                    <div>Không có dữ liệu...</div>}
                            </Tab>

                        </Tabs>
                    ) : null
                }


                { !isExperience ? (
                    <div>
                        <div>
                            {
                                answer && !answer?.is_expanded_search &&

                                <Stack.Item>
                                    <AnswerInteractRate
                                        answerText={parsedAnswer.answerText}
                                        messageId={answer.id}
                                        answer={answer}
                                        setOpenFeedback={setOpenFeedback}
                                        actionFeedback={actionFeedback}
                                        onSetActionFeedback={(_action: string) => setActionFeedback(_action)}
                                        disable_switch_history={disable_switch_history} 
                                        is_used_history={is_used_history}
                                    />
                                </Stack.Item>
                            }
                            {openFeedback &&
                                <Stack.Item>
                                    <AnswerFeedback
                                        messageId={answer.id}
                                        sanitizedAnswerHtml={sanitizedAnswerHtml}
                                        setOpenThankFeedback={setOpenThankFeedback}
                                        setOpenFeedback={setOpenFeedback}
                                        actionFeedback={actionFeedback}
                                        genQuery={answer?.question ? answer?.question : answer?.gen_query}
                                        setActionFeedback={setActionFeedback}

                                    />
                                </Stack.Item>
                            }
                            {openThankFeedback &&
                            <div className={styles.feedbackContainer}>
                                <div className={styles.boxFeedHeader}>
                                    <span className={`${styles.textFeedTitleThank}`}>Cảm ơn bạn đã phản hồi</span>
                                    <div className={styles.closeIconBox} onClick={closeThankFeedback}>
                                        <Dismiss20Regular />
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                ) : null}
            </Stack>
            {
                answer && answer?.buttons?.length && onSend ? 
                <Stack>
                    <AnswerFollowup answerFollowup = {answer?.buttons} onSendFollowupAnswer={question => onsendFollowupQuestion(question)}
                    />
                </Stack> : null
            }
            {!!answer.continue_step_search &&
                <div className="reference-citation-buttons" style={{marginTop: '10px'}}>
                    <>
                        {answer.continue_step_search !== 0 &&
                        <div
                            className={`issue-button-multiple`}
                            onClick={() => handleClickStepSearch(answer.continue_step_search)}
                        >
                            {answer?.continue_step_search_title || 'Tìm kiếm với nguồn khác'}
                        </div>}

                    </>
                </div>
            }
            {answer.expanded_search &&
                <div className="reference-citation-buttons" style={{marginTop: '10px'}}>
                    <>
                        {answer.expanded_search &&
                        <div
                            className={`issue-button-multiple`}
                            onClick={() => handleClickExpandedSearch()}
                        >
                            Tìm kiếm tất cả các nguồn
                        </div>}

                    </>
                </div>
            }
        </>
    );
}));
