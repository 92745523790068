import DropdownCitationType from "@components/Ui/DropdownMenu/DropdownCitationType";
import CitationColumn from "./CitationColumn";


interface Props {
    setDocumentCitation: any;
    documentCitation: any[];
}
export const CitationForm = (props: Props) => {
    const {documentCitation, setDocumentCitation} = props

    const handleChangeType = (type: string) => {
        if (type === 'law') {
            setDocumentCitation((prev: { law: null; article: null; clause: string; point: string; type: string; }[]) => [...prev, {
                law: null,
                article: null,
                clauseTitle: '',
                pointTitle: '',
                type
            }])
        }
        if (type === 'url') {
            setDocumentCitation((prev: { urlDisplay: string; type: string; }[]) => [...prev, {
                urlDisplay: '',
                type
            }])
        }
    }

    return (
        <>
            <DropdownCitationType handleChangeType={type => handleChangeType(type)} />
            <CitationColumn setDocumentCitation={setDocumentCitation} documentCitation ={documentCitation}/>
        </>
    )
};

