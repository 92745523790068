import { action, computed, makeObservable, observable } from "mobx";
import { IRootStore } from "./RootStore";


export interface ISessionChats {
    active: boolean
    createdAt: number
    firstMessage: string
    id: string
    pinned: boolean
    sessionName: string
  }
export class CitationsStore {
  citationLinks: any = [];
  citationPopup: any = []
  citationUrl: string = "";
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      citationLinks: observable,
      citationPopup: observable,
      citationUrl: observable,
      addCitationLinks: action,
      updateCitationLinks: action,
      updateCitationUrl: action,
      getCitationLink: computed,
      getCitationUrl: computed
    });
    this.rootStore = rootStore;
  }

  addCitationLinks = (instruction: any) => {
    this.citationLinks = instruction
  }
  addCitationPopup = (instruction: any) => {
    this.citationPopup = instruction
  }

  updateCitationLinks = (instruction: any) => {
    this.citationLinks[instruction.idx] = instruction['_result']
  }

  updateCitationUrl = (url: string) => {
    this.citationUrl = url
  }

  get getCitationLink() {
    return this.citationLinks;
  } 
  get getCitationPopup() {
    return this.citationPopup;
  }

  get getCitationUrl() {
    return this.citationUrl;
  }

}