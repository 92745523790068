import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import './styles.css';
import { Add20Regular } from '@fluentui/react-icons';

interface Props {
    handleChangeType: (type: string) => void;
}
const DropdownCitationType = ({handleChangeType}: Props) => {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <div className="addCitationFaq" style={{width: '200px'}}>
                    <Add20Regular/><span>Thêm trích dẫn</span>
                </div>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5} style={{zIndex: '9999999999999 !important'}}>
                    <DropdownMenu.Item className="DropdownMenuItem" onClick={() => handleChangeType('law')}>
                        <div className="boxItemDropdown" style={{ display: 'flex', alignItems: 'center' }}>
                            Văn bản
                        </div>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item className="DropdownMenuItem" onClick={() => handleChangeType('url')}>
                        <div className="boxItemDropdown" style={{ display: 'flex', alignItems: 'center' }}>
                            Đường dẫn
                        </div>
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default DropdownCitationType;