import { Text } from "@fluentui/react";
import * as Switch from '@radix-ui/react-switch';
import * as Popover from '@radix-ui/react-popover';
import './styles.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@hooks/useStore';

const svg_switch = () => {
    return (
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 0H6C2.69 0 0 2.69 0 6C0 9.31 2.69 12 6 12H16C19.31 12 22 9.31 22 6C22 2.69 19.31 0 16 0ZM16 10H6C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2H16C18.21 2 20 3.79 20 6C20 8.21 18.21 10 16 10ZM6 3C4.34 3 3 4.34 3 6C3 7.66 4.34 9 6 9C7.66 9 9 7.66 9 6C9 4.34 7.66 3 6 3Z" fill="white"/> </svg>
    )
}
export const SwitchContext = observer(() => {
    const {
        rootStore: { configurationStore },
      } = useStore()
      
    const [open, setOpen] = useState(false);

    const handleMouseEnter = () => {
        setOpen(true);
    };

    const handleMouseLeave = () => {
        // setOpen(false);
    };
    return (
        <>
            <div className="box-popover-switch">

                <Popover.Root open={open} onOpenChange={setOpen}>
                    <Popover.Trigger asChild onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <form>
                            <div className="Switch_box" style={{ display: 'flex', alignItems: 'center' }}>
                                <Switch.Root
                                    className="SwitchRoot"
                                    id="airplane-mode"
                                    onCheckedChange={(e) => {
                                        configurationStore.SET_IS_HISTORY(e)
                                    }}
                                >
                                    <Switch.Thumb className="SwitchThumb" />
                                </Switch.Root>

                            </div>
                        </form>
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content className="PopoverContent" sideOffset={5}>
                            <div className='po-content' style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                <p className="Text" style={{ marginBottom: 10 }}>
                                    {svg_switch()}
                                    Bật/ Tắt Ngữ Cảnh Của Câu
                                </p>
                                <p>
                                    Tính năng cấu hình ngữ cảnh, cho phép người dùng bật ngữ cảnh để liên kết nội dung với câu hỏi trước đó
                                </p>
                                <p style={{ color: '#919191', marginBottom: '4px', cursor: 'pointer' }} onClick={() => setOpen(false)}>
                                    Đóng
                                </p>
                            </div>
                            <Popover.Arrow className="PopoverArrow" />
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
                <Text className="text-layout-chat">{"Ngữ cảnh của câu"}</Text>
            </div>

        </>
    )
});

