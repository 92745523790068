export const defaultConfiguration = (): any => {
    const defaultConfig = {
        semantic_ranker: false,
        semantic_captions: false,
        top: 5,
        temperature: 0.2,
        temperature_gen_query: 0,
        suggest_followup_questions: false,
        semantic_law_retriever: false,
        fulltext_search_retriever: true,
        chatgpt_model: 'gpt-35-turbo',
        chatgpt_model_gen_query: 'gpt-35-turbo',
        no_prompt: false,
        no_search_engine: false,
        rerank_retrieve: false,
        use_llm_filter: true,
        confirm_decision: true,
        suggestion_by_llm: true,
        no_gen_query: false,
        retrieval_mode: 'text',
        conversation_id: null,
        use_whitelist: true,
        google_seo: true,
        use_knowledge_graph: false,
        // knowledge_graph_option: 'option_1',
        graph_have_relationship: false,
        rule_search: 20,
        level_search: 20,
    }
    return defaultConfig
}