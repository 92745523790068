import { SessionChatsStore } from "./sessionChatsStore";
import { CitationsStore } from "./citationStore";
import { ConfigurationStore } from "./configurationStore";
import { DraftStore } from "./draftStore";




export interface IRootStore {
  sessionChatsStore: SessionChatsStore;
  citationsStore: CitationsStore;
  configurationStore: ConfigurationStore
  draftStore: DraftStore

}

export class RootStore implements IRootStore {
  sessionChatsStore: SessionChatsStore;
  citationsStore: CitationsStore
  configurationStore: ConfigurationStore
  draftStore: DraftStore

  constructor() {
    this.sessionChatsStore = new SessionChatsStore(this);
    this.citationsStore = new CitationsStore(this);
    this.configurationStore = new ConfigurationStore(this);
    this.draftStore = new DraftStore(this)
  }
}