import { io } from 'socket.io-client';
import { defaultHeader } from '../../utils/localStorage';

const URL = 'https://trolyao.tech.gov.vn/';

export const chatSocket = io(URL, {
  autoConnect: false,
  extraHeaders: {
    ...defaultHeader()
  }
});