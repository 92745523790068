import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { charts } from "../../../api/models";
import ChartOptions from "./ChartsOptions";

interface Props {
    dataChart: charts[]
    title_chart: string,
    type_chart: string
}

const colors = [
    '#ee0033',
    '#6b8abc',
    '#38b3f8',
    '#5ac9c9',
    '#acda7e',
    '#ff8a60'
]

const Charts = ({ dataChart, title_chart, type_chart }: Props) => {
    const countColumn = dataChart?.length ?? 0
    const widthScrollbar = countColumn * 40
    const options = ChartOptions({
        dataChart: dataChart,
        title_chart,
        type_chart,
        countColumn,
        widthScrollbar,
        colors,
    });
    return (
        <div className="" style={{ marginBottom: '12px' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default Charts;
