import { useEffect, useRef, useState } from "react";
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import "react-tooltip/dist/react-tooltip.css";
import styles from "./PromptModel.module.css";
import { Label, TextField } from "@fluentui/react";
import { DropDownPrompt } from "./DropDownPrompt";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import {defaultHeader} from "../../utils/localStorage";


interface Props {
    promptArr?: any;
    setShowCreatePrompt: any;
    showCreatePrompt?: boolean;
    makeApiGetPrompt: () => void
}

export const CreatePrompt = ({ showCreatePrompt, setShowCreatePrompt, promptArr, makeApiGetPrompt }: Props) => {
    const firstUpdate = useRef(false);
    const [user, setUser] = useState<any>(localStorage.getItem('_user'))
    const [promptName, setPromptName] = useState<string>("")
    const [prefixPrompt, setPrefixPrompt] = useState<string>("")
    const [followUpPrompt, setFollowUpPrompt] = useState<string>("")
    const [queryPrompt, setQueryPrompt] = useState<string>("")
    const [graphPrompt, setGraphPrompt] = useState<string>("")
    const [validated, setValidated] = useState(false);
    const [messageErrorText, setMessageErrorText] = useState<string>("")
    const [showError, setShowError] = useState(false);

    const [validation, setValidation] = useState<any>({
        promptName: undefined,
        prefixPrompt: undefined,
        followUpPrompt: undefined,
        queryPrompt: undefined,
        graphPrompt: undefined
    })
    const [promptSelected, setPromptSelected] = useState<any>(null)
    const [optionsPrompt, setOptionsPrompt] = useState([])

    const handleClose = () => {
        setShowCreatePrompt(false)
        setPrefixPrompt("")
        setFollowUpPrompt("")
        setPromptName("")
        setQueryPrompt("")
        setPromptSelected(null)
        setShowError(false)
        setMessageErrorText("")
        setValidation((prev: any) => ({ ...prev, prefixPrompt: undefined, followUpPrompt: undefined, queryPrompt: undefined }))
    }
    const onPromptNameChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptName(newValue || "");
        setValidation((prev: any) => ({ ...prev, promptName: newValue ? undefined : "Tên Prompt là bắt buộc" }))
    };
    const onPromptPrefixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPrefixPrompt(newValue || "");
        setValidation((prev: any) => ({ ...prev, prefixPrompt: newValue ? undefined : "Prompt tạo phản hồi là bắt buộc" }))
    };

    const onPromptFollowUpChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFollowUpPrompt(newValue || "");
        setValidation((prev: any) => ({ ...prev, followUpPrompt: newValue ? undefined : "Prompt tạo phản hồi là bắt buộc" }))

    };

    const onPromptQueryChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQueryPrompt(newValue || "");
        setValidation((prev: any) => ({ ...prev, queryPrompt: newValue ? undefined : "Prompt tạo phản hồi là bắt buộc" }))
    };

    const onPromptGraphChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setGraphPrompt(newValue || "");
        setValidation((prev: any) => ({ ...prev, graphPrompt: newValue ? undefined : "Prompt đồ thị tri thức là bắt buộc" }))
    };

    const checkValidate = (promptName: string, prefixPrompt: string, followUpPrompt: string, queryPrompt: string, graphPrompt: string) => {
        setValidation((prev: any) => ({
            ...prev,
            prefixPrompt: prefixPrompt ? undefined : "Promp tạo phản hồi là bắt buộc",
            followUpPrompt: followUpPrompt ? undefined : "Promp tạo phản hồi là bắt buộc",
            queryPrompt: queryPrompt ? undefined : "Promp tạo phản hồi là bắt buộc",
            graphPrompt: graphPrompt ? undefined : "Prompt đồ thị tri thức là bắt buộc",
            promptName: promptName ? undefined : "Tên Prompt là bắt buộc",
        }))

    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        checkValidate(promptName, prefixPrompt, followUpPrompt, queryPrompt, graphPrompt)
        if (!promptName || !prefixPrompt || !followUpPrompt || !queryPrompt || !graphPrompt) {
            setValidated(true);
            return;
        } else {
            setValidated(false);
        }
        const requestData = {
            name: promptName,
            prefix_prompt: prefixPrompt,
            follow_up_prompt: followUpPrompt,
            query_prompt: queryPrompt,
            graph_prompt: graphPrompt
        };
        try {
            const { data } = await axios.post('https://trolyao.tech.gov.vn/prompts', requestData, {
                headers: {
                    ...defaultHeader()
                }
            });

            if (data) {
                notify("Thêm mới Prompt thành công")
                makeApiGetPrompt()
                handleClose()
            }
        } catch (e: any) {
            console.log(e);

            if (e && e.response && e.response.data && e.response.data.error) {
                setShowError(true)
                setMessageErrorText(e.response.data.error);
            }

        }
    };
    const notify = (e: any) => toast.success(`${e}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        rtl: false
    });
    useEffect(() => {
        if (promptSelected) {
            checkValidate(promptSelected.name, promptSelected["prefix_prompt"], promptSelected["follow_up_prompt"], promptSelected["query_prompt"], promptSelected["graph_prompt"])
            setPromptName(promptSelected.name || "");
            setPrefixPrompt(promptSelected["prefix_prompt"] || "");
            setFollowUpPrompt(promptSelected["follow_up_prompt"] || "");
            setQueryPrompt(promptSelected["query_prompt"] || "");
            setGraphPrompt(promptSelected["graph_prompt"] || "");
        } else if (validated && !promptSelected) {
            checkValidate("", "", "", "", "")
            setPromptName("")
            setPrefixPrompt("");
            setFollowUpPrompt("");
            setQueryPrompt("");
            setGraphPrompt("")
        }
    }, [promptSelected])
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        if (user) {
            const userTmp = JSON.parse(user)
            setUser(userTmp)
        }
        firstUpdate.current = true;
        return () => { };
    }, []);
    useEffect(() => {
        let tem = promptArr
        if (promptArr && promptArr.length) {
            promptArr.map((v: any, ind: any) => (
                tem[ind].value = v.name,
                tem[ind].label = v.name
            ))
        }
        setOptionsPrompt(tem)
    }, [promptArr])

    return (
        <>
            <div className="">
                <Modal
                    show={showCreatePrompt}
                    onHide={handleClose}
                    dialogClassName="modal-90w"
                    className="modal-bootstrap"
                    size={'lg'}
                    centered
                    aria-labelledby="contained-modal-title-center"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Thêm mới Prompt
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <Form noValidate validated={validated}>

                                {showError && (
                                    <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                        <div>{messageErrorText}</div>
                                    </Alert>
                                )}
                                <div className="" style={{ marginBottom: '15px' }}>
                                    <Label className={styles.chatSettingsSeparator}>
                                        Copy Prompt (Thêm mới dựa trên Prompt đã có)
                                    </Label>
                                    <div>
                                        <DropDownPrompt optionsPrompt={optionsPrompt} setPromptSelected={setPromptSelected} promptSelected={promptSelected} />

                                    </div>
                                </div>
                                <TextField
                                    className={styles.chatSettingsSeparator}
                                    defaultValue={promptName}
                                    value={promptName}
                                    required={true}
                                    label="Tên Prompt"
                                    onChange={onPromptNameChange}
                                    errorMessage={validation.promptName}
                                />
                                <TextField
                                    className={styles.chatSettingsSeparator}
                                    defaultValue={prefixPrompt}
                                    value={prefixPrompt}
                                    required={true}
                                    label="Promp tạo phản hồi"
                                    multiline
                                    autoAdjustHeight
                                    onChange={onPromptPrefixChange}
                                    errorMessage={validation.prefixPrompt}
                                />
                                <TextField
                                    className={styles.chatSettingsSeparator}
                                    defaultValue={followUpPrompt}
                                    value={followUpPrompt}
                                    required={true}
                                    label="Promt tạo câu gợi ý tiếp theo"
                                    multiline
                                    autoAdjustHeight
                                    onChange={onPromptFollowUpChange}
                                    errorMessage={validation.followUpPrompt}
                                />
                                <TextField
                                    className={styles.chatSettingsSeparator}
                                    defaultValue={queryPrompt}
                                    value={queryPrompt}
                                    required={true}
                                    label="Promt tạo câu truy vấn"
                                    multiline
                                    autoAdjustHeight
                                    onChange={onPromptQueryChange}
                                    errorMessage={validation.queryPrompt}
                                />
                                <TextField
                                    className={styles.chatSettingsSeparator}
                                    defaultValue={graphPrompt}
                                    value={graphPrompt}
                                    required={true}
                                    label="Prompt đồ thị tri thức"
                                    multiline
                                    autoAdjustHeight
                                    onChange={onPromptGraphChange}
                                    errorMessage={validation.graphPrompt}
                                />
                            </Form>
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'light'} onClick={handleClose}>
                            Hủy
                        </Button>
                        <Button onClick={handleSubmit}>
                            Thêm mới
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
};
