import { Stack } from "@fluentui/react";
import "./tabDraft.css";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../hooks/useStore";
import { RuleChat } from "../../../../api/models";
import { SessionChatSvg } from "../../draftIcon/sessionChatSvg";
import { SessionDraftSvg } from "../../draftIcon/sessionDraftSvg";


export const TabHistory = observer(() => {
    const {
        rootStore: { sessionChatsStore },
    } = useStore();
    const onClickRuleChat = (rule: string) => {
        sessionChatsStore.SET_RULE_CHAT(rule)
    }
    return (
        <Stack className='tabs-draft'>
            <Stack.Item grow className={`tab-draft ${sessionChatsStore.ruleChat === RuleChat.chat ? 'active-draft' : null}`} onClick={() => onClickRuleChat(RuleChat.chat)}>
                <SessionChatSvg color={sessionChatsStore.ruleChat === RuleChat.chat ? '#FFF' : '#EE0033'} />

                <span>
                    Trò chuyện
                </span>
            </Stack.Item>

            <Stack.Item grow className={`tab-draft ${sessionChatsStore.ruleChat === RuleChat.draft ? 'active-draft' : null}`} onClick={() => onClickRuleChat(RuleChat.draft)}>
                <SessionDraftSvg color={sessionChatsStore.ruleChat === RuleChat.draft ? '#FFF' : '#EE0033'} />

                <span>
                    Đóng góp
                </span>
            </Stack.Item>
        </Stack>
    );
});

