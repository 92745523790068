import moment from "moment";

export const eventDateConvertedV2 = (dateIncoming: any) => {
    if (dateIncoming) {
        moment.locale('vi')
        const dateConverted = new Date(dateIncoming)
        const dt = moment(dateConverted, 'YYYY-MM-DD HH:mm:ss')
        const dayName = dt.format('dddd')
        const dayNameShort = dayName.substring(0, 3)
        const fullDate = dt.format('DD/MM/YYYY')
        const timeDate = dt.format('HH:mm')
        return { date: fullDate, dayName: dayNameShort, time: timeDate }
    }
}

export const eventDateConverted = (dateIncoming: any) => {
    if (dateIncoming) {
        moment.locale("vi");
        const dateConverted = new Date(dateIncoming);
        const dt = moment(dateConverted, "YYYY-MM-DD HH:mm:ss");
        const dayName = dt.format("dddd");
        const dayNameShort = dayName.substring(0, 3);
        const fullDate = dt.format("DD/MM/YYYY");
        const timeDate = dt.format("HH:mm");
        const tempTimeAgo = moment(dt).fromNow()
        const timeAgo =
          tempTimeAgo === 'một phút tới'
            ? 'vừa xong'
            : tempTimeAgo === 'vài giây tới'
            ? 'vài giây'
            : tempTimeAgo === 'một phút trước'
            ? '1 phút'
            : tempTimeAgo.replace(' trước', '')
        return { date: fullDate, dayName: dayNameShort, time: timeDate, timeAgo };
    }
};

export const eventTime = () => {
    moment.locale("vi");
    const hour = moment().get("hour");
    const minute = moment().get("minute");
    return { hour: hour, minute: minute };
};