import React, { useEffect, useState } from "react";
import {DefaultButton, Stack, TextField} from "@fluentui/react";
import styles from "./Answer.module.css";
import close from "../../assets/close.png";
import axios from "axios";
import {Dismiss20Regular} from "@fluentui/react-icons";


interface Props {

    messageId: string
    answer: any;
    setOpenFeedback?: any;
    sanitizedAnswerHtml: any;
    answerText: string;
    onSetActionFeedback: (a: string) => void
}

const Svg_like = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66732 7.33337L7.33398 1.33337C7.86442 1.33337 8.37313 1.54409 8.7482 1.91916C9.12327 2.29423 9.33398 2.80294 9.33398 3.33337V6.00004H13.1073C13.3006 5.99785 13.492 6.03771 13.6684 6.11685C13.8447 6.196 14.0017 6.31253 14.1286 6.45838C14.2554 6.60424 14.349 6.77592 14.4029 6.96154C14.4568 7.14716 14.4697 7.34228 14.4407 7.53337L13.5207 13.5334C13.4724 13.8513 13.3109 14.1411 13.0659 14.3494C12.8209 14.5576 12.5089 14.6703 12.1873 14.6667H4.66732M4.66732 7.33337V14.6667M4.66732 7.33337H2.66732C2.3137 7.33337 1.97456 7.47385 1.72451 7.7239C1.47446 7.97395 1.33398 8.31309 1.33398 8.66671V13.3334C1.33398 13.687 1.47446 14.0261 1.72451 14.2762C1.97456 14.5262 2.3137 14.6667 2.66732 14.6667H4.66732" stroke="var(--color_ic_interact)" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
const Svg_dislike = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3334 8.6667L8.66669 14.6667C8.13625 14.6667 7.62755 14.456 7.25247 14.0809C6.8774 13.7058 6.66669 13.1971 6.66669 12.6667V10H2.89335C2.70008 10.0022 2.50864 9.96237 2.33231 9.88323C2.15597 9.80408 1.99894 9.68755 1.87211 9.54169C1.74529 9.39584 1.65168 9.22416 1.59779 9.03854C1.5439 8.85292 1.53102 8.6578 1.56002 8.4667L2.48002 2.4667C2.52824 2.14877 2.68973 1.85897 2.93475 1.6507C3.17977 1.44243 3.4918 1.32974 3.81335 1.33337H11.3334M11.3334 8.6667V1.33337M11.3334 8.6667H13.1134C13.4907 8.67338 13.8573 8.54128 14.1436 8.29549C14.43 8.0497 14.6161 7.70733 14.6667 7.33337V2.6667C14.6161 2.29274 14.43 1.95037 14.1436 1.70458C13.8573 1.45879 13.4907 1.3267 13.1134 1.33337H11.3334" stroke="var(--color_ic_interact)" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
const Ic_like_active = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66732 7.33337L7.33398 1.33337C7.86442 1.33337 8.37313 1.54409 8.7482 1.91916C9.12327 2.29423 9.33398 2.80294 9.33398 3.33337V6.00004H13.1073C13.3006 5.99785 13.492 6.03771 13.6684 6.11685C13.8447 6.196 14.0017 6.31253 14.1286 6.45838C14.2554 6.60424 14.349 6.77592 14.4029 6.96154C14.4568 7.14716 14.4697 7.34228 14.4407 7.53337L13.5207 13.5334C13.4724 13.8513 13.3109 14.1411 13.0659 14.3494C12.8209 14.5576 12.5089 14.6703 12.1873 14.6667H4.66732M4.66732 7.33337V14.6667M4.66732 7.33337H2.66732C2.3137 7.33337 1.97456 7.47385 1.72451 7.7239C1.47446 7.97395 1.33398 8.31309 1.33398 8.66671V13.3334C1.33398 13.687 1.47446 14.0261 1.72451 14.2762C1.97456 14.5262 2.3137 14.6667 2.66732 14.6667H4.66732" stroke="#ee0033" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
const Ic_dislike_active = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3334 8.6667L8.66669 14.6667C8.13625 14.6667 7.62755 14.456 7.25247 14.0809C6.8774 13.7058 6.66669 13.1971 6.66669 12.6667V10H2.89335C2.70008 10.0022 2.50864 9.96237 2.33231 9.88323C2.15597 9.80408 1.99894 9.68755 1.87211 9.54169C1.74529 9.39584 1.65168 9.22416 1.59779 9.03854C1.5439 8.85292 1.53102 8.6578 1.56002 8.4667L2.48002 2.4667C2.52824 2.14877 2.68973 1.85897 2.93475 1.6507C3.17977 1.44243 3.4918 1.32974 3.81335 1.33337H11.3334M11.3334 8.6667V1.33337M11.3334 8.6667H13.1134C13.4907 8.67338 13.8573 8.54128 14.1436 8.29549C14.43 8.0497 14.6161 7.70733 14.6667 7.33337V2.6667C14.6161 2.29274 14.43 1.95037 14.1436 1.70458C13.8573 1.45879 13.4907 1.3267 13.1134 1.33337H11.3334" stroke="#ee0033" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const AnswerInteractCitation = ({ messageId, answer }: Props) => {
    const [openFeedback, setOpenFeedback] = useState<boolean>(false)
    const [action, setAction] = useState<string>('')
    const [reason, setReason] = useState<string>('')
    const [openThankFeedback, setOpenThankFeedback] = useState<boolean>(false)


    const likeMessage = async (actionData: string) => {
        setOpenThankFeedback(false)
        if (!action || (action !== actionData)) {
            setAction(actionData);
            setOpenFeedback(true);
        } else {
            setAction('');
            setOpenFeedback(false);
        }
    }
    const closeFeedback = () => {
        setReason("")
        setOpenFeedback(false)
    }

    const closeThankFeedback = () => {
        setOpenThankFeedback(false)
    }

    const onReasonDislikeChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setReason(newValue || "");
    };

    const sendFeedback = async () => {
        const request: any = {
            reaction: action,
            dataId: messageId,
            messageId: answer.id,
            reason: reason,
        }

        try {
            const {data} = await axios.post('https://gateway-congchuc.trolyao.org/api/chatSession/reactionRecommend', request);
            if (data) {
                setOpenThankFeedback(true)
            }
        } catch (e: any) {
            setOpenThankFeedback(true)
        }

        setOpenFeedback(false)
        setReason('')
    }

    return (
        <Stack>
            <Stack.Item>
                <div className={styles.interactContainer}>
                    <div className={`${styles.boxIcon} ${action === 'Like' ? styles.boxStyle : ''}`} onClick={() => likeMessage('Like')}>
                        {action === 'Like' ? <Ic_like_active/> : <Svg_like/>}
                        
                    </div>
                    <div className={`${styles.boxIcon} ${action === 'Dislike' ? styles.boxStyle : ''}`} onClick={() => likeMessage('Dislike')}>
                        {action === 'Dislike' ? <Ic_dislike_active/> : <Svg_dislike/>}
                    </div>
                </div>
            </Stack.Item>

            { openFeedback && (
                <Stack className={styles.feedbackContainer}>
                    <Stack.Item className={`${styles.boxFeedHeader} mb-2`}>
                        <div>
                            <span className={`${styles.textFeedTitle}`}>
                                Tại sao bạn đánh giá như vậy?
                            </span>
                            {/*<span className={`${styles.textFeedTitleDes}`}>{` (không bắt buộc)`}</span>*/}
                        </div>
                        <div className={styles.closeIconBox} onClick={closeFeedback}>
                            <img className={styles.closeIcon} src={close} />
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            className={`${styles.chatSettingsSeparator} textfieldCustom`}
                            defaultValue={reason}
                            value={reason}
                            placeholder='Đưa ra lý do'
                            multiline
                            autoAdjustHeight
                            onChange={onReasonDislikeChange}
                        />
                    </Stack.Item>
                    <Stack.Item className={`${styles.buttonFeedback}`}>
                        <DefaultButton className='buttonConfirm'
                                       onClick={sendFeedback}
                        >Gửi</DefaultButton>

                    </Stack.Item>
                </Stack>
            )}

            {openThankFeedback &&
                <div className={styles.feedbackContainer}>
                  <div className={styles.boxFeedHeader}>
                    <span className={`${styles.textFeedTitleThank}`}>Cảm ơn bạn đã phản hồi</span>
                    <div className={styles.closeIconBox} onClick={closeThankFeedback}>
                      <Dismiss20Regular />
                    </div>
                  </div>
                </div>
            }
        </Stack>
    );
};
