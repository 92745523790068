import isEmpty from 'lodash/isEmpty'
import styles from "./Configuration.module.css";
import { Label, PrimaryButton, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { forEach } from 'lodash';
import { DefaultQuestionRequest } from '../../api/models';
import { EditDefaultQuestion } from '../../api';


interface Props {
    className?: string;
    getDefaultQuestion: any;
    notify: () => void;
    reload: () => void
}

export const QuestionInit = ({ getDefaultQuestion, notify, reload }: Props) => {
    const [isChangeQuestion, setIsChangeQuestion] = useState<boolean>(false)
    const [defaultQuestion, setDefaultQuestion] = useState({
        question1: '',
        question2: '',
        question3: '',
    })
    const onDefaultFirstQuestionChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const newQuestion = newValue || ""
        setDefaultQuestion((prev: any) => ({ ...prev, question1: newQuestion }));
    };

    const onDefaultSecondQuestionChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const newQuestion = newValue || ""
        setDefaultQuestion((prev: any) => ({ ...prev, question2: newQuestion }));
    };

    const onDefaultThirdQuestionChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const newQuestion = newValue || ""
        setDefaultQuestion((prev: any) => ({ ...prev, question3: newQuestion }));
    };

    const updateDefaultQuestion = () => {
        const arrQuestion = [defaultQuestion.question1, defaultQuestion.question2, defaultQuestion.question3]
        const request: DefaultQuestionRequest = {
            type: 'MIC',
            data: arrQuestion,
            id: getDefaultQuestion?.id
        };
        EditDefaultQuestion(request)
            .then(result => {
                notify()
                reload()
            })
            .catch(e => {
                console.log(e);
            })
    }

    useEffect(() => {
        if (getDefaultQuestion && getDefaultQuestion.data) {
            forEach(getDefaultQuestion.data, (v, ind: number) => {
                switch (ind) {
                    case 0:
                        setDefaultQuestion((prev: any) => ({ ...prev, question1: v }));
                        break;
                    case 1:
                        setDefaultQuestion((prev: any) => ({ ...prev, question2: v }));
                        break;
                    case 2:
                        setDefaultQuestion((prev: any) => ({ ...prev, question3: v }));
                        break;
                }
            })
        }

    }, [getDefaultQuestion])

    useEffect(() => {
        const questionList = getDefaultQuestion?.data
        if (defaultQuestion.question1 === questionList[0] &&
            defaultQuestion.question2 === questionList[1] &&
            defaultQuestion.question3 === questionList[2]) {
            setIsChangeQuestion(false)
        } else {
            setIsChangeQuestion(true)
        }
    }, [defaultQuestion])

    return (
        <>
            <Label className={styles.chatSettingsSeparator}>
                Default question
            </Label>
            <TextField
                // className={styles.chatSettingsSeparator}
                defaultValue={defaultQuestion.question1}
                value={defaultQuestion.question1}
                // label="Promp tạo phản hồi"
                multiline
                autoAdjustHeight
                onChange={onDefaultFirstQuestionChange}
            />
            <TextField
                className={styles.chatSettingsSeparator}
                defaultValue={defaultQuestion.question2}
                value={defaultQuestion.question2}
                // label="Promt tạo câu gợi ý tiếp theo"
                multiline
                autoAdjustHeight
                onChange={onDefaultSecondQuestionChange}
            />
            <TextField
                className={styles.chatSettingsSeparator}
                defaultValue={defaultQuestion.question3}
                value={defaultQuestion.question3}
                // label="Promt tạo câu truy vấn"
                multiline
                autoAdjustHeight
                onChange={onDefaultThirdQuestionChange}
            />
            <div className={`${styles.chatSettingsSeparator} ${styles.flexEnd}`}>

                <PrimaryButton
                    disabled={!isChangeQuestion ? true : false}
                    onClick={updateDefaultQuestion}
                >
                    Apply
                </PrimaryButton>
            </div>
        </>
    )
};
