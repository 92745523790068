import { action, computed, makeObservable, observable } from "mobx";
import { DraftStatus, ItemsDraftStatus } from "../api/models";
import { IRootStore } from "./RootStore";


export class DraftStore {
  _active_draft = DraftStatus.sent
  _key_active_approve = ItemsDraftStatus.all
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      //observable
      _active_draft: observable,
      _key_active_approve: observable,


      //action
      SET_ACTIVE_DRAFT: action,
      SET_KEY_ACTIVE: action,

      
      //computed
      activeDraft: computed,
      keyActiveApprove: computed

    });
    this.rootStore = rootStore;
  }

  SET_ACTIVE_DRAFT(status: string) {
    if (status === DraftStatus.unsent) {
      this._active_draft = DraftStatus.unsent;
    } else if (status === DraftStatus.sent) {
      this._active_draft = DraftStatus.sent;
    }
  }

  SET_KEY_ACTIVE (key: string) {
    if (key === ItemsDraftStatus.all) {
      this._key_active_approve = ItemsDraftStatus.all;
    } else if (key === ItemsDraftStatus.pending) {
      this._key_active_approve = ItemsDraftStatus.pending;
    } else if (key === ItemsDraftStatus.processing) {
      this._key_active_approve = ItemsDraftStatus.processing;
    } else if (key === ItemsDraftStatus.processed) {
      this._key_active_approve = ItemsDraftStatus.processed;
    }
  }

  get activeDraft() {
    return this._active_draft;
  }

  get keyActiveApprove() {
    return this._key_active_approve
  }

}