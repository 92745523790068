import { map } from "lodash";
import CreatableSelect from 'react-select/creatable';
import { ISelectedField } from "../../api/models";
import customData from './field.json';
import fs from 'fs';


interface Props {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    fieldSelected?: ISelectedField | null;
    setFieldSelected: (option: ISelectedField | null) => void;
}
export const DropDownField = ({ setFieldSelected, fieldSelected }: Props) => {

    const id = "react-select_" + Math.random().toFixed(8).slice(2);
    const optionsPrompt: ISelectedField[] = map(customData?.data, (item: any) => {
        return {
            value: item.id,
            label: item.name,
        };
    })
    const handleInputChange = () => {
        setTimeout(() => {
            const menuEl = document.querySelector(`#${id} [class*="-menu"]`);
            const menuListEl = document.querySelector(
                `#${id} [class*="MenuList"]`
            );

            if (menuListEl && menuEl) {
                if (menuListEl.children.length === 1 && menuListEl.children[0].innerHTML === "") {
                    (menuEl as HTMLElement).style.display = "none";
                } else {
                    (menuEl as HTMLElement).style.display = "block";
                }
            }
        });
    }
    const handleSelectChange = (selected: ISelectedField | null) => {
        setFieldSelected(selected)
    };

    return (
        <>
            <CreatableSelect
                id={id}
                className="select-custom"
                required={true}
                value={fieldSelected}
                options={optionsPrompt}
                onChange={handleSelectChange}
                placeholder={"Chọn lĩnh vực...."}
                formatCreateLabel={() => undefined}
                onInputChange={handleInputChange}
                isMulti={false}
                isClearable
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--select-primary)',
                        primary: 'var(--select-primary)',
                    },
                })}
                styles={{
                    control: base => ({
                        ...base,
                        fontSize: '14px',
                        color: 'var(--color-text-panel)'
                    }),
                    option: base => ({
                        ...base,
                        fontSize: '14px',
                        color: 'var(--color-text-panel)'
                    }),
                    singleValue: (base) => ({ ...base, color: 'var(--color-text-panel)' }),
                }}
            />
        </>
    )
};

