import ic_chat_v2 from "../assets/uiv2/ic_messages_v2.svg"
import ic_chat_close_v2 from "../assets/uiv2/ic_messages_close_v2.svg"
export const toggleSession = () => {
    const temp = document.querySelector(".navigation")
    temp?.classList.add("addAnimationChatBar");
    temp?.classList.toggle("activeNav");
    const bar = document.querySelector(".sessionChatBar")
    bar?.classList.toggle("activeNav");
    const icon = document.querySelector(".chatActiveIcon")
    icon?.classList.toggle("activeIconSession");
    icon?.classList.toggle("chatActiveIconChange");
    const iconActive = document.querySelector(".activeIconSession")
    // const imgmsAcitve = document.getElementById("svgImg1") as HTMLImageElement
    // imgmsAcitve.src = iconActive ? `${ic_chat_close_v2}` : `${ic_chat_v2}`        
    const buttonToggle = document.querySelector(".buttonToggleClass")
    buttonToggle?.classList.toggle("flipVerticalRight");
    const textList = document.querySelector(".textListAll")
    textList?.classList.toggle("textListAllCloseSidebar");
    const svgIcAciveCitation = document.querySelector(".svg-rule-citaion")
    svgIcAciveCitation?.classList.toggle("fill-svg-rule-citaion");
    const svgIcAciveCitationFaq = document.querySelector(".svg-rule-citaion-faq")
    svgIcAciveCitationFaq?.classList.toggle("fill-svg-rule-citaion");
}