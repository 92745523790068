import React, { ChangeEvent, useEffect, useState } from "react";
import { DefaultButton, Label, Stack, TextField } from "@fluentui/react";
import { IFeedback, RuleSearch, likeUnlikeChatSession, rateMessageChatSession } from "../../api";
import { LuCopyCheck } from "react-icons/lu";
import { IconContext } from "react-icons";
import styles from "./Answer.module.css";
import { IconStar } from "./icon";
import { getDislikeList, getRateList } from "./Reason";
import { Dismiss20Regular, DocumentText20Regular } from "@fluentui/react-icons";
import close from "../../assets/close.png";
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";
import { isEmpty } from "lodash";
import { useRedirectChat } from "../../hooks/useRedirectChat";
import showToasty from "../../hooks/useNotify";
import { SwitchContext } from "./context/SwitchContext";
import { CitationForm } from "./citation-feedback/CitationForm";
import { FlagContext } from "./context/FlagContext";

const Svg_copy = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.335" y="5.335" width="9.33" height="9.33" rx="1.665" stroke="var(--color_ic_interact)" strokeWidth="1.33" strokeLinejoin="round" />
            <path
                d="M2.6875 10H2C1.44772 10 1 9.55228 1 9V2C1 1.44772 1.44772 1 2 1H9C9.55228 1 10 1.44772 10 2V2.6875"
                stroke="var(--color_ic_interact)"
                strokeWidth="1.33"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

interface Props {
    messageId: string;
    answer: any;
    setOpenFeedback?: any;
    actionFeedback: string;
    answerText: string;
    onSetActionFeedback: (a: string) => void;
    disableCopyButton?: boolean;
    disable_switch_history: boolean | undefined
    is_used_history: boolean | undefined,
}

const AnswerInteractRate = observer(({
    messageId,
    answer,
    setOpenFeedback,
    actionFeedback,
    answerText,
    onSetActionFeedback,
    disableCopyButton,
    disable_switch_history,
    is_used_history
}: Props) => {
    const {
        rootStore: { sessionChatsStore }
    } = useStore();

    const [answerDetail, setAnswerDetail] = useState<any>();
    const [rate, setRate] = useState<number>(answerDetail?.star || 0);

    const [anotherReason, setAnotherReason] = useState<string>("");
    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

    const [showFeedback, setShowFeedback] = useState<boolean>(false);
    const [showThanks, setShowThanks] = useState<boolean>(false);

    const [copied, setCopied] = useState<boolean>(false);

    const [documentCitation, setDocumentCitation] = useState<any>([]);

    const isWarning = sessionChatsStore.getMessageRating;

    useEffect(() => {
        setAnswerDetail(answer);
        setRate(answer?.star);
    }, [answer]);

    const resetAllState = () => {
        sessionChatsStore.SET_MESSAGE_RATING("");
        setAnotherReason("");
        setSelectedReasons([]);
        setShowFeedback(false);
        setShowThanks(false);
        setDocumentCitation([]);
    };
    useEffect(() => {
        resetAllState();
        return () => {
            resetAllState();
        };
    }, []);

    useEffect(() => {
        resetAllState();
    }, [sessionChatsStore.idRouter]);

    const rateMessage = async (_rate: number) => {
        if (!isEmpty(sessionChatsStore.getMessageRating) && messageId !== sessionChatsStore.getMessageRating) {
            showToasty("Vui lòng hoàn thành đánh giá!", "error");
            return;
        }
        if (rate !== _rate) {
            setRate(_rate);
            setShowFeedback(true);
            setShowThanks(false);
            if (_rate !== 0 && _rate <= 3) {
                sessionChatsStore.SET_MESSAGE_RATING(messageId);
            } else {
                sessionChatsStore.SET_MESSAGE_RATING("");
            }
            const body = {
                messageId,
                star: _rate
            };
            const result = await rateMessageChatSession(body);
            setAnswerDetail((prev: any) => ({ ...prev, star: result.data?.star }));
            if (result?.data && _rate === 5) {
                setShowThanks(true);
            }
        } else {
            resetAllState();
            setRate(0);
            const body = {
                messageId,
                star: 0
            };
            await rateMessageChatSession(body);
            setAnswerDetail((prev: any) => ({ ...prev, star: 0 }));
        }
    };

    const checkDisableSubmit = () => {
        const result = anotherReason || selectedReasons.length > 0 ? false : true;
        return result;
    };
    let isDisable = rate < 4 && checkDisableSubmit();

    const onReasonDislikeChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setAnotherReason(newValue || "");
    };

    const convertReqDocCitation = () => {
        if (isEmpty(documentCitation)) {
            return "";
        }
        let value = [];
        for (const docCitation of documentCitation) {
            const isEmptyDocTitle = docCitation?.type === "law" && isEmpty(docCitation?.law?.documentId);
            const isEmptyUrl = docCitation?.type === "url" && isEmpty(docCitation?.urlDisplay);
            console.log('!(isEmptyDocTitle || isEmptyUrl)', !(isEmptyDocTitle || isEmptyUrl))
            if (!(isEmptyDocTitle || isEmptyUrl)) {
                let tmpItem = {
                    ...docCitation,
                    ...docCitation.law,
                    ...docCitation.article
                };
                delete tmpItem["law"];
                delete tmpItem["article"];
                delete tmpItem["value"];
                value.push(tmpItem);
            }
        }

        return JSON.stringify(value);
    };

    const sendFeedback = async (data?: IFeedback) => {
        const request: any = {
            messageId: messageId,
            star: rate,
            reasonType: [...selectedReasons],
            description: anotherReason
        };
        const _documentCitationsAsJson = convertReqDocCitation()
        if (!isEmpty(_documentCitationsAsJson)) {
            request.documentCitationsAsJson = _documentCitationsAsJson;
        }
        const result = await rateMessageChatSession(request);
        if (result?.data) {
            setAnswerDetail((prev: any) => ({ ...prev, star: result.data?.star }));
            setShowThanks(true);
            closeFeedback();
            sessionChatsStore.SET_MESSAGE_RATING("");
        }
    };

    const onCoppy = () => {
        navigator.clipboard.writeText(answerText);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 15 * 100);
    };

    const closeThankFeedback = () => {
        setShowThanks(false);
    };

    const closeFeedback = () => {
        setAnotherReason("");
        setSelectedReasons([]);
        setShowFeedback(false);
        setShowThanks(true);
    };

    const onChangeProfileCheckbox = (ev?: ChangeEvent<HTMLInputElement>, reason?: string) => {
        const value = reason;
        const checked = ev?.target?.checked;
        if (checked && value) {
            let _selectedReasons = selectedReasons.slice();
            _selectedReasons.push(value);
            setSelectedReasons(_selectedReasons);
        } else {
            setSelectedReasons(prev => prev.filter(x => x !== value));
        }
        checkDisableSubmit();
    };

    const getProfileCheckboxes = () => {
        const feedback = getDislikeList();
        const Feedbacks = rate < 5 ? feedback : [];
        const checkboxes = Feedbacks.map((feedback, ind) => (
            <li key={messageId + "_" + ind}>
                <input
                    type="checkbox"
                    id={messageId + feedback.id}
                    value={feedback.reason}
                    defaultChecked={false}
                    onChange={(ev: any) => onChangeProfileCheckbox(ev, feedback.reason)}
                ></input>
                <label htmlFor={messageId + feedback.id}>
                    <span style={{ lineHeight: "24px" }}>{feedback.name}</span>
                </label>
            </li>
        ));
        return checkboxes;
    };

    const reasonsDom = () => {
        const dom = (
            <div className={styles.expMembers}>
                <ul>{getProfileCheckboxes()}</ul>
            </div>
        );
        return dom;
    };

    const openContribute = () => {
        setOpenFeedback(true);
        onSetActionFeedback("contribute");
    };

    return (
        <Stack>
            <Stack.Item>
                <div className={styles.answerRateBox}>
                    {/* <SwitchContext disable_switch_history={disable_switch_history} is_used_history={is_used_history} />                     */}
                    <div className={styles.interactContainer}>
                        <div className={styles.rateContainer}>
                            {[...Array(5)].map((_star: number, index: number) => {
                                const current = index + 1;
                                return (
                                    <div className={`${styles.boxRateIcon}`} onClick={() => rateMessage(current)} key={`rate${current}`}>
                                        {<IconStar isActive={current <= rate} />}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={`${styles.boxIcon} ${actionFeedback === "contribute" ? styles.boxStyle : ""}`} onClick={openContribute}>
                            <DocumentText20Regular />
                        </div>
                        {!disableCopyButton && (
                            <div onClick={onCoppy} className={`${styles.boxIcon} ${copied ? styles.boxStyle : ""}`}>
                                <IconContext.Provider value={{ style: { color: "var(--color_ic_interact)" }, className: styles.likeIcon }}>
                                    {copied ? <LuCopyCheck /> : <Svg_copy />}
                                </IconContext.Provider>
                            </div>
                        )}
                    </div>
                    {/* <FlagContext is_used_history={is_used_history}/> */}
                </div>
            </Stack.Item>
            {rate < 5 && rate !== 0 && showFeedback && (
                <Stack className={`${styles.feedbackRateContainer} ${isWarning && styles.borderFeedbackWaring}`}>
                    <Stack.Item className={styles.boxFeedHeader}>
                        <div>
                            <span className={`${styles.textFeedTitle}`}>{"Vui lòng cho biết điều bạn không hài lòng?"}</span>
                            <span className={`${styles.textFeedTitleDes}`}>{rate === 4 ? " (Không bắt buộc)" : " (Bắt buộc)"}</span>
                        </div>
                        {rate === 4 && (
                            <div className={styles.closeIconBox} onClick={closeFeedback}>
                                <img className={styles.closeIcon} src={close} />
                            </div>
                        )}
                    </Stack.Item>

                    <Stack.Item className={styles.boxCheckbox}>{reasonsDom()}</Stack.Item>
                    <Stack.Item>
                        <TextField
                            className={`${styles.chatSettingsSeparator} textfieldCustom`}
                            defaultValue={anotherReason}
                            value={anotherReason}
                            label="Gợi ý câu trả lời đúng"
                            placeholder="Vui lòng chỉ rõ Điều, khoản, điểm, tên văn bản (bao gồm loại văn bản, mã văn bản) liên quan. Trong trường hợp câu trả lời bị thừa ý hoặc thiếu ý thì cũng nêu cụ thể."
                            multiline
                            autoAdjustHeight
                            onChange={onReasonDislikeChange}
                        />
                    </Stack.Item>
                    <Stack.Item className="" style={{ marginTop: 10 }}>
                        <Label>Văn bản liên quan</Label>
                        <CitationForm documentCitation={documentCitation} setDocumentCitation={setDocumentCitation} />
                    </Stack.Item>
                    {/* <Stack.Item className={`${styles.buttonContribute}`}>
                        <DefaultButton className="buttonConfirm" onClick={openContribute}>
                            Đóng góp câu hỏi tình huống (FAQ)
                        </DefaultButton>
                    </Stack.Item> */}
                    <Stack.Item className={`${styles.buttonRateFeedback}`}>
                        <DefaultButton disabled={isDisable} className={`${styles.buttonRate}`} onClick={() => sendFeedback()}>
                            Gửi đánh giá
                        </DefaultButton>
                    </Stack.Item>
                </Stack>
            )}

            {showThanks && (!showFeedback || rate === 5) && (
                <div className={styles.feedbackContainer}>
                    <div className={styles.boxFeedHeader}>
                        <span className={`${styles.textFeedTitleThank}`}>Cảm ơn bạn đã phản hồi</span>
                        <div className={styles.closeIconBox} onClick={closeThankFeedback}>
                            <Dismiss20Regular />
                        </div>
                    </div>
                </div>
            )}
        </Stack>
    );
});

export default AnswerInteractRate;
