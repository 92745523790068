import {
  ComponentProps
} from "react";
import "./itemtabs.css";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../hooks/useStore";
import { ItemsDraftStatus } from "../../../../api/models";
import { Tab, Tabs } from "react-bootstrap";

type Props<S> = Omit<ComponentProps<"div">, "children"> & {
  children: any,
  draftSent: any[]
};

const ALL = ItemsDraftStatus.all
const PENDING = ItemsDraftStatus.pending
const PROCESSING = ItemsDraftStatus.processing
const PROCESSED = ItemsDraftStatus.processed
const NOPROCESSED = ItemsDraftStatus.noprocess

export const TabItemDraft = observer(function TabItemDraft<S>(props: Props<S>) {
  const { children, draftSent, ...rest } = props;
  const {
    rootStore: { draftStore },
  } = useStore();
  const draftsByStatus = (statusApprove: string) => {
    const filterByStatus = (status: string) => status === ALL ? draftSent : draftSent.filter((draft) => draft?.status === status);

    switch (statusApprove) {
      case ALL:
        return {
          total: filterByStatus(ALL).length,
        };
      case PENDING:
        return {
          total: filterByStatus(PENDING).length,
        };
      case PROCESSED:
        return {
          total: filterByStatus(PROCESSED).length,
        };
      case PROCESSING:
        return {
          total: filterByStatus(PROCESSING).length + filterByStatus(NOPROCESSED).length,
        };
      default:
        return { total: 0 };
    }
  }

  const allCount = draftsByStatus(ALL).total;
  const pendingCount = draftsByStatus(PENDING).total;
  const processedCount = draftsByStatus(PROCESSED).total;
  const processingCount = draftsByStatus(PROCESSING).total;

  const rederTabTitle = (name: string, total: number) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <span>
          {name}
        </span>
        {
          !!total &&
          <span>
            ({total})
          </span>
        }
      </div>
    )

  }
  console.log(draftStore.keyActiveApprove)
  return (
    <Tabs
      id="items-draft-tabs"
      activeKey={draftStore.keyActiveApprove}
      onSelect={(k: any) => draftStore.SET_KEY_ACTIVE(k)}
      className="mb-2 items-drafts-tabs"
    >

      <Tab eventKey={ALL} title={rederTabTitle(ALL, allCount)}>
        {children}
      </Tab>

      <Tab eventKey={PROCESSING} title={rederTabTitle(PROCESSING, processingCount)}>
        {children}
      </Tab>

      <Tab eventKey={PENDING} title={rederTabTitle(PENDING, pendingCount)}>
        {children}
      </Tab>

      <Tab eventKey={PROCESSED} title={rederTabTitle(PROCESSED, processedCount)}>
        {children}
      </Tab>

    </Tabs>
  );
});
