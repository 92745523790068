import { debounce, map } from "lodash";
import AsyncSelect from 'react-select/async';
import { ISelectedField } from "../../api/models";
import { getArticleSelectList } from "@api/api";


interface Props {
    fieldSelected?: ISelectedField | null;
    setFieldSelected: (option: ISelectedField | null) => void;
    documentId: string | null
}
export const AsyncDropDownArticle = ({ setFieldSelected, fieldSelected, documentId }: Props) => {
    const id = "react-select_" + Math.random().toFixed(8).slice(2);
    const loadOptions = debounce((inputText, callback) => {
            getArticleSelectList({ keyword: inputText, documentId: documentId }).then((options) => {
                const optionsPrompt: any[] = map(options.data, (item: any) => {
                    return {
                        articleId: item.id,
                        articleTitle: item.articleTitle,
                        value: item.articleTitle,
                        label: item.articleTitle,
                    };
                })
                return callback(optionsPrompt)
            });
        }, 500)

    const handleInputChange = () => {
        setTimeout(() => {
            const menuEl = document.querySelector(`#${id} [class*="-menu"]`);
            const menuListEl = document.querySelector(
                `#${id} [class*="MenuList"]`
            );

            if (menuListEl && menuEl) {
                if (menuListEl.children.length === 1 && menuListEl.children[0].innerHTML === "") {
                    (menuEl as HTMLElement).style.display = "none";
                } else {
                    (menuEl as HTMLElement).style.display = "block";
                }
            }
        });
    }
    const handleSelectChange = (selected: ISelectedField | null) => {
        setFieldSelected(selected)
    };

    return (
        <>
            <AsyncSelect
                key={documentId}
                id={id}
                className="select-custom"
                required={true}
                value={fieldSelected}
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onChange={handleSelectChange}
                placeholder={"Chọn điều...."}
                onInputChange={handleInputChange}
                isMulti={false}
                isClearable
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--select-primary)',
                        primary: 'var(--select-primary)',
                    },
                })}
                styles={{
                    control: base => ({
                        ...base,
                        fontSize: '14px',
                        color: 'var(--color-text-panel)'
                    }),
                    option: base => ({
                        ...base,
                        fontSize: '14px',
                        color: 'var(--color-text-panel)'
                    }),
                    singleValue: (base) => ({ ...base, color: 'var(--color-text-panel)' }),
                }}
            />
        </>
    )
};

