export const IconStar = ({ isActive = false }: { isActive: boolean }) => {
    if (isActive) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.38211 1.15534L10.79 3.9943C10.9819 4.3895 11.4939 4.76856 11.9258 4.84115L14.4775 5.2686C16.1094 5.54282 16.4933 6.73647 15.3175 7.914L13.3337 9.91417C12.9977 10.2529 12.8137 10.9062 12.9177 11.374L13.4857 13.85C13.9336 15.8098 12.9017 16.568 11.1819 15.5437L8.79017 14.1162C8.35822 13.8581 7.6463 13.8581 7.20635 14.1162L4.81461 15.5437C3.1028 16.568 2.06292 15.8018 2.51087 13.85L3.07881 11.374C3.18279 10.9062 2.99881 10.2529 2.66285 9.91417L0.679071 7.914C-0.4888 6.73647 -0.112841 5.54282 1.51898 5.2686L4.0707 4.84115C4.49465 4.76856 5.00659 4.3895 5.19857 3.9943L6.60642 1.15534C7.37433 -0.385114 8.62219 -0.385114 9.38211 1.15534Z"
                    fill="#EE0033"
                />
            </svg>
        );
    }
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.20935 2.01092L10.4412 4.49501C10.6092 4.84081 11.0571 5.17249 11.4351 5.236L13.6679 5.61003C15.0957 5.84997 15.4317 6.89442 14.4028 7.92475L12.667 9.6749C12.373 9.9713 12.212 10.5429 12.303 10.9522L12.7999 13.1187C13.1919 14.8336 12.289 15.497 10.7842 14.6007L8.6914 13.3516C8.31344 13.1258 7.69051 13.1258 7.30556 13.3516L5.21279 14.6007C3.71495 15.497 2.80505 14.8266 3.19701 13.1187L3.69395 10.9522C3.78494 10.5429 3.62396 9.9713 3.33 9.6749L1.59419 7.92475C0.5723 6.89442 0.901264 5.84997 2.32911 5.61003L4.56186 5.236C4.93282 5.17249 5.38077 4.84081 5.54875 4.49501L6.78061 2.01092C7.45254 0.663025 8.54442 0.663025 9.20935 2.01092Z"
                stroke="#5C5F62"
                strokeWidth="1.33"
                strokeLinejoin="round"
            />
        </svg>
    );
};
