import { useEffect, useState } from "react";
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import {
    Captions,
    Download,
    Fullscreen,
    Thumbnails,
    Zoom,
} from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Masonry from "react-responsive-masonry"
import { map } from "lodash";

interface Props {
    images: string[]
}
export default function GalleryImages({ images }: Props) {
    const slides = map(images, (image) => {
        return {
            src: image
        }
    })
    const imagesLength = slides.length
    const columnsCount = imagesLength === 1 ? 1 : imagesLength === 2 ? 2 : 3

    const [index, setIndex] = useState<number>(-1);

    return (
        <div style={{marginBottom: '10px'}}>
            <Masonry columnsCount={columnsCount} gutter="10px">
                {slides.map((image, i: number) => (
                    <img
                        key={i}
                        src={image.src}
                        style={{ cursor: 'pointer', display: "block", width: '100%' }}
                        onClick={() => setIndex(i)}
                    />
                ))}
                <Lightbox
                    plugins={[Zoom, Thumbnails]}
                    captions={{
                        showToggle: true,
                        descriptionTextAlign: 'end',
                    }}
                    index={index}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    slides={slides}
                    zoom={{
                        scrollToZoom:true,
                        maxZoomPixelRatio:5
                      }}
                />
            </Masonry>
        </div>
    );
}
