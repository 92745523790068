import { Button, Modal } from 'react-bootstrap';
import styles from "./Layout.module.css";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/useStore';
import { useEffect } from 'react';
import icwarning from "../../assets/warning_icon_light_mode.svg"


interface Props {
    className?: string;
    closeLogoutSsoModal: () => void;
    openLogoutSsoModal?: boolean
}

export const ModelLogoutSso = observer(({ openLogoutSsoModal, closeLogoutSsoModal }: Props) => {
    const {
        rootStore: { sessionChatsStore },
    } = useStore();
    const refresh_token = sessionChatsStore.tokenHind
    const redirectLogout = () => {
        window.location.href = `https://beta-sso.mic.gov.vn/connect/endsession?id_token_hint=${refresh_token}&post_logout_redirect_uri=https://trolyao.tech.gov.vn`;
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.href = `https://beta-sso.mic.gov.vn/connect/endsession?id_token_hint=${refresh_token}&post_logout_redirect_uri=https://trolyao.tech.gov.vn`;
        }, 2000);
        return () => clearTimeout(timeout);
    }, [])
    return (
        <>
            <Modal
                show={openLogoutSsoModal}
                onHide={closeLogoutSsoModal}
                className="modal-bootstrap"
                size={'lg'}
                centered
                aria-labelledby="contained-modal-title-center"
            >
                <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Thông báo
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                    <>
                    <div className={styles.boxWarningLogout}>
                            <div className="">
                                <img src={icwarning}/>
                            </div>
                            <p>
                                Tài khoản của bạn không nằm trong danh sách đăng ký thử nghiệm.
                            </p>
                    </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={redirectLogout}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
});
