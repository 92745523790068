import React, { useRef, useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";

const Callback = observer(({ setToken, setHeader }: any) => {
    const {
        rootStore: { sessionChatsStore },
      } = useStore();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect( () => {
        const sessionState = searchParams.get('session_state')
        const code = searchParams.get('code')
        if (code) {
            verifyCode({ code, session_state: sessionState })
        } else {
            let message = 'error sso'
            // window.location.replace(`/#/?message_sso=${encodeURI(message)}`);
            window.location.replace(`/#/?message_sso=${encodeURI('Bạn không nằm trong danh sách đăng ký thử nghiệm')}`);
        }
    }, [searchParams]);

    const verifyCode = async (postData: any) => {
        try {
            // code: "e8ccb932-6562-4db7-8f2a-72430041ec5f.5bb0d894-1aaa-4369-99db-1f27bda377a1.1db38377-c4ee-407a-b16a-868836d4bdb6"
            // sessionState: "5bb0d894-1aaa-4369-99db-1f27bda377a1"
            const {data} = await axios.post('https://trolyao.tech.gov.vn/auth/verify-code', postData)
            if (data && data.status === 'success') {
                setHeader(false)
                const tokenType = data.token_type ? data.token_type : 'Bearer'
                localStorage.setItem("_token", `${tokenType} ${data.access_token}`);
                localStorage.setItem("_refresh_token", `${data.refresh_token}`);
                localStorage.setItem("_user", JSON.stringify(data.user));
                localStorage.setItem("_typeAuthorization", 'OTHER');
                setToken(data.access_token);
                setHeader(false);
                window.location.replace('/#/chat');
            } else if(data && data.status === 'error') {
                sessionChatsStore.tokenChange(data.refresh_token)
                window.location.replace(`/#/?account_sso=${encodeURI('Tài khoản của bạn không nằm trong danh sách đăng ký thử nghiệm')}`);
                // window.location.href = `https://beta-sso.mic.gov.vn/connect/endsession?id_token_hint=${data.refresh_token}&post_logout_redirect_uri=https://trolyao.tech.gov.vn`;
            }
        } catch (e: any) {
            let message = 'error sso'
            if (e && e.response && e.response.data && e.response.data.messages) {
                message = e.response.data.messages
            }
            // window.location.replace(`/#/?message_sso=${encodeURI(message)}`);
            window.location.replace(`/#/?message_sso=${encodeURI('Bạn không nằm trong danh sách đăng ký thử nghiệm')}`);
        }
    };

    return (
        <></>
    )
});

export default Callback;
