import { ComponentProps, ReactElement, ReactNode } from "react";
import { Modal, Button } from 'react-bootstrap';

type Props<S> = Omit<ComponentProps<"div">, "children"> & {
    children: any,
    show: boolean,
    title?: string,
    size?: "sm" | "lg" | "xl" | undefined,
    disabled?: boolean,
    closeModalConfirm: () => void,
    handleConfirm: () => void,
    footer?: ReactNode,
    header?: ReactNode
};

export const ModalConfirm = function ModalConfirm<S>(props: Props<S>) {
    const { children, show, title, closeModalConfirm, handleConfirm, size, disabled, footer, header, ...rest } = props;
    const formattedTitle = typeof title === 'string' ? title : <>{title}</>;

    return (
        <>
            <div className="">
                <Modal
                    show={show}
                    onHide={closeModalConfirm}
                    dialogClassName="modal-90w"
                    className="modal-bootstrap"
                    centered
                    size={size}
                    {...rest}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                        {
                            header ? header : title
                        }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            {children}
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            footer ? footer : 
                            <>
                                <Button variant={'light'} onClick={closeModalConfirm}>
                                    Hủy
                                </Button>
                                <Button disabled={disabled} onClick={handleConfirm}>
                                    Xác nhận
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
};
