import ic_pause_chat from "@assets/ic_pause_chat.svg";
import styles from "./Chat.module.css";

interface IProps {
    onClick: () => void;
}
const ButtonCancelChat = ({ onClick }: IProps) => {
    return (
        <div className={styles.cancelChatButton} onClick={onClick}>
            <img src={ic_pause_chat} />
            <span className={styles.textCancelChatButton}>{"Dừng phản hồi"}</span>
        </div>
    );
};

export default ButtonCancelChat;
