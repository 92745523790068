import { Pivot, PivotItem, DocumentCard, getDocument, safeSetTimeout } from "@fluentui/react";
import { forEach, isEmpty, split, trim, uniqBy } from 'lodash'
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { AskResponse, CitationsByIdRequest, CitationsRequest, DocumentCitation, citationsApi, citationsByIdApi, citationsCompareApi } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { createLogger } from "vite";
import { useEffect, useState } from "react";
import { PopoverAnswer } from "../Common";
import { openWindowToUrl } from "../../utils/openWindowUrl";
import { isShowConfiguration } from "../../utils/isShowConfiguration";
import { Modal, Button, Accordion, Card, Row, Col } from "react-bootstrap";
import { parseSupportingContentItem } from "../SupportingContent/SupportingContentParser";
import { useStore } from "../../hooks/useStore";
import { observer } from "mobx-react-lite";
import ControlledTabs from "../Tabs/Tabs";
import { PopupCitationPanel } from "./PopupCitationPanel";
import { validURL } from "../../utils/string";
import { substringUrl } from "../../utils/substringUrl";



interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: AskResponse;
    loadingCitation: boolean;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = observer(({ loadingCitation, answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {
    const {
        rootStore: { citationsStore, sessionChatsStore }
    } = useStore();
    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.data_points?.length;
    const isDisabledCitationTab: boolean = !activeCitation;

    const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

    const [lawName, setLawName] = useState<string>("");
    const [lawPointName, setLawPointName] = useState<string>("");

    const [showHighlightCitation, setShowHighlightCitation] = useState<boolean>(false);
    const [noteMetadatasHighlight, setNoteMetadatasHighlight] = useState<any>(null);

    const [answerLawDetails, setAnswerLawDetails] = useState(null)
    const [openInfoLawDetails, setOpenInfoLawDetails] = useState<boolean>(false)

    const [lawPopupData, setLawPopupData] = useState(null)
    const [openLawPopup, setOpenLawPopup] = useState<boolean>(false)

    const openEditor = async (sfdt: string) => {
        const event = new CustomEvent('OPEN_EDITOR', {
            detail: {
                data: sfdt
            },
        });
        window.dispatchEvent(event);
    }

    const isHighlightCitation = (noteMetadatas: any) => {
        let isHighlight = false
        if (noteMetadatas && noteMetadatas.length) {
            forEach(noteMetadatas, function (metadata: any, key: any) {
                if (metadata.guide) {
                    isHighlight = true
                    return false
                }
            })
        }

        return isHighlight
    }

    const setHighlightCitation = (citation: any) => {
        if (citation && citation.noteChildContents && citation.noteChildContents.length) {
            forEach(citation.noteChildContents, function (note: any, keyNote: any) {
                const word = citation.noteChildContents[keyNote].word
                let wordHtml = word
                if (note.noteMetadatas && note.noteMetadatas.length) {
                    const noteMetadatas = uniqBy(note.noteMetadatas, 'to')
                    if (isHighlightCitation(noteMetadatas)) {
                        forEach(noteMetadatas, function (metadata: any, keyMetadata: any) {
                            if (metadata.to) {
                                const wordHighlight = word.substring(metadata.from, metadata.to + 1);
                                if (wordHighlight) {
                                    const wordHighlightHtml = `<span class="is-highlight citation-highlight" data-key-note="${keyNote}" data-key-metadata="${keyMetadata}">${wordHighlight}</span>`;
                                    wordHtml = wordHtml.replace(wordHighlight, wordHighlightHtml)
                                }
                            }
                        });
                    }
                }
                citation.noteChildContents[keyNote].wordHtml = wordHtml
            });
        }
        return citation
    };

    const makeApiCitationRequest = async (content: string) => {
        var dataResult = null;
        const request: CitationsRequest = { content: content };
        const result = await citationsApi(request);
        if (result && result.data) {
            dataResult = result.data;
        } else {
            if (answer && answer["data_points"]) {
                const textList = answer["data_points"];
                const requestCompare: any = { word: content, textList: textList };
                const resultCompare = await citationsCompareApi(requestCompare);
                if (resultCompare && resultCompare.data) {
                    const request: CitationsRequest = { content: resultCompare.data };
                    const result = await citationsApi(request);
                    dataResult = result && result.data ? result.data : null;
                }
            }
        }
        return dataResult;
    };

    const make_citation = async (content: string) => {
        try {
            const result = await makeApiCitationRequest(content)
            let citation = result ? result : null
            setAnswerLawDetails(null)
            if (citation?.sfdt) {
                openEditor(citation?.sfdt)
                setOpenInfoLawDetails(false)
                setOpenLawPopup(false)
                return
            }
            if (citation && citation.isSearchTitle && citation.url) {
                setAnswerLawDetails(citation)
                setOpenInfoLawDetails(true)
                setOpenLawPopup(false)
                return;
            }

            if (citation?.noteChildContent && typeof citation?.noteChildContent === "string") {
                citation.noteChildContent = JSON.parse(citation.noteChildContent)
            }
            citation = setHighlightCitation(citation)
            setLawPopupData(citation)
            setOpenInfoLawDetails(false)
            setOpenLawPopup(true)
        } catch (e) {
            console.log('error r', e)
        } finally {}
    }

    const onClickCitation = (content: string) => {
        if (validURL(content)) {
            openWindowToUrl(content);
        } else {
            make_citation(content);
        }
    };

    const makeCitationById = async (id: string, typeArticle : string) => {
        let dataResult = null
        const request: CitationsByIdRequest = {
            id,
            typeArticle
        };
        const result = await citationsByIdApi(request);
        if (result && result?.sfdt) {
            dataResult = result
        } else if (result && result?.data) {
            dataResult = result.data
        }
        return dataResult
    }

    const make_citation_as_id = async (id: string, typeArticle : string) => {
        try {
            const result = await makeCitationById(id, typeArticle)
            let citation = result ? result : null
            setAnswerLawDetails(null)
            if (citation?.sfdt) {
                openEditor(citation?.sfdt)
                setOpenInfoLawDetails(false)
                setOpenLawPopup(false)
                return
            }
            if (citation && citation.isSearchTitle && citation.url) {
                setAnswerLawDetails(citation)
                setOpenInfoLawDetails(true)
                setOpenLawPopup(false)
                return;
            }

            if (citation?.noteChildContent && typeof citation?.noteChildContent === "string") {
                citation.noteChildContent = JSON.parse(citation.noteChildContent)
            }
            citation = setHighlightCitation(citation)
            setLawPopupData(citation)
            setOpenInfoLawDetails(false)
            setOpenLawPopup(true)
        } catch (e) {
            console.log('error r', e)
        } finally {}
    }


    const onClickDocumentCitation = (document: DocumentCitation) => {
        if (document.type === 'url' && document?.url_display) {
            openWindowToUrl(document.url_display);
        } else {
            make_citation_as_id(document.id, document.type);
        }
    };



    const handleCloseHighlightCitation = () => {
        setShowHighlightCitation(false);
    };
    const handleShowHighlightCitation = () => {
        setShowHighlightCitation(true);
    };

    const onClickHighlightCitation = (keyNote: any, keyMetadata: any) => {
        if (answer && answer.citation && answer.citation.noteChildContents && answer.citation.noteChildContents[keyNote]) {
            handleShowHighlightCitation();
            if (answer.citation.noteChildContents[keyNote].noteMetadatas && answer.citation.noteChildContents[keyNote].noteMetadatas.length) {
                setNoteMetadatasHighlight(answer.citation.noteChildContents[keyNote].noteMetadatas);
            }
        }
    };

    const navigateLaw = (url: string) => {
        if (url && typeof url === "string") {
            openWindowToUrl(url);
        }
    };

    useEffect(() => {
        if (answer && answer.citation && answer.citation.nameDisplay) {
            const namePiece = answer.citation.nameDisplay.split("\n");
            if (namePiece && namePiece.length) {
                if (namePiece[0]) {
                    setLawName(namePiece[0]);
                }
                if (namePiece[1]) {
                    setLawPointName(namePiece[1]);
                }
            }
        }

        if (answer && answer.citation && answer.citation.noteChildContents && answer.citation.noteChildContents.length) {
            setTimeout(() => {
                const highlightCitations = document.getElementsByClassName("citation-highlight");
                if (highlightCitations && highlightCitations.length) {
                    for (let i = 0; i < highlightCitations.length; i++) {
                        highlightCitations[i].removeEventListener("click", function () {}, false);
                        highlightCitations[i].addEventListener("click", function (e: any) {
                            if (e && e.target && e.target.dataset && e.target.dataset.hasOwnProperty("keyMetadata")) {
                                const keyMetadata = e.target.dataset["keyMetadata"];
                                const keyNote = e.target.dataset["keyNote"];
                                onClickHighlightCitation(keyNote, keyMetadata);
                            }
                        });
                    }
                }
            });
        }
    }, [answer, loadingCitation]);

    const expandedFaq = (x: string) => {
        const sanitizedAnswerHtml = DOMPurify.sanitize(sessionChatsStore.isClickExpandedFaq?.content);
        return (
            <div className={styles.card}>
                <li className={styles.supportingContentItem}>
                    <h4 className={styles.supportingContentItemHeader}>{sessionChatsStore.isClickExpandedFaq.title}</h4>
                    {/* <p className={styles.supportingContentItemText}>{parsed.content}</p> */}
                    <div className={styles.supportingContentItemText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </li>
            </div>
        );
    };
    const thamkhao = (x: string) => {
        const parsed = parseSupportingContentItem(x);
        const sanitizedAnswerHtml = DOMPurify.sanitize(parsed.content);
        return (
            <div className={styles.card}>
                <li className={styles.supportingContentItem}>
                    <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                    {/* <p className={styles.supportingContentItemText}>{parsed.content}</p> */}
                    <div className={styles.supportingContentItemText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </li>
            </div>
        )
    }
    const parsedFaq = (title: string, content: string, document_citations?: DocumentCitation[]) => {
        const sanitizedAnswerHtml = DOMPurify.sanitize((content));
        let index = 0
        return (
            <div className={styles.card}>
                <li className={styles.supportingContentItem}>
                    <h4 className={styles.supportingContentItemHeader}>
                        {title}
                    </h4>
                    {   !!(document_citations?.length && document_citations.length > 0) &&
                        <div style={{marginTop: '1rem', cursor: 'pointer'}}>
                            {document_citations.map((document_citation: DocumentCitation) => {
                                const name_display = 
                                    document_citation.type === "url" ? substringUrl(document_citation.url_display || "") : document_citation.name_display;
                                    if (isEmpty(name_display)) {
                                        return <></>
                                    }
                                return (
                                    <div key={`${document_citation.id}`} style={{marginBottom: 6}}>
                                        <a
                                            onClick={() => onClickDocumentCitation(document_citation)}
                                            className={styles.textCitation}
                                        >
                                            {`${++index}. ${name_display}`}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    }
                    <div className={styles.supportingContentItemText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </li>
            </div>
        )
    }

    const quytrinh = (x: string) => {
        try {
            const parsed = JSON.parse(x);
            const sanitizedAnswerHtml = DOMPurify.sanitize((parsed.content));
            return (
                <div className={styles.card}>
                    <li className={styles.supportingContentItem}>
                        <h4 className={styles.supportingContentItemHeader}>
                            {parsed.title}

                        </h4>
                        {/* <p className={styles.supportingContentItemText}>{parsed.content}</p> */}
                        <div className={styles.supportingContentItemText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>

                    </li>
                </div>
            )
        } catch(e) {
            return <></>
        }
        
    }

    return (
        <>
        {openInfoLawDetails ? (
                <ControlledTabs
                    answer={answerLawDetails}
                    openDialog={openInfoLawDetails}
                    onCloseModal={() => setOpenInfoLawDetails(false)}
                />
            ) : null}

            {openLawPopup && (
                <Modal
                    show={openLawPopup}
                    onHide={() => setOpenLawPopup(false)}
                    size="xl"
                    centered
                    className="modal-citation-container"
                    dialogClassName="modal-highlight-citation"
                    scrollable
                >
                    <Modal.Header
                        closeButton
                        // style={{ background: '#e5e7eb' }}
                    ></Modal.Header>
                    <Modal.Body>
                        <PopupCitationPanel answer={{ citation: lawPopupData }} />
                    </Modal.Body>
                </Modal>
            )}
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            {isShowConfiguration() && (
                // <PivotItem
                //     itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                //     headerText="Thought process"
                //     headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
                // >
                //     <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
                // </PivotItem>

                <PivotItem
                    style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                    itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                    headerText="Thought process"
                    headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
                >
                    <div className="accordion-thoughts-container">
                        {answer &&
                            answer.debugs &&
                            answer.debugs.length &&
                            answer.debugs.map((thought: any, index: any) => (
                                <>
                                    <Accordion className="mb-2 accordion-thoughts">
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>
                                                <div className="button-header">
                                                    <span>{thought.title}</span>
                                                    <span className="me-3">{thought.time_process}s</span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>{thought.content}</Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                            ))}

                        {answer && (!answer.debugs || (answer.debugs && !answer.debugs.length)) && (
                            <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
                        )}
                    </div>
                </PivotItem>
            )}
            {isShowConfiguration() && (
                <PivotItem
                    itemKey={AnalysisPanelTabs.SupportingContentTab}
                    headerText="Supporting content"
                    headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
                >
                    <SupportingContent
                        supportingContent={answer.data_points}
                        supportingScore={answer.semantic_scores}
                        rerankScore={answer.hasOwnProperty("rerank_scores") ? answer.rerank_scores : []}
                    />
                </PivotItem>
            )}
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                {
                    !isEmpty(answer?.working_process_steps) && (
                        quytrinh(answer?.citation || '{}')
                    )
                }
                {answer &&
                    answer.citation &&
                    Array.isArray(answer.citation?.children) &&
                    answer.citation.children.length > 0 &&
                    !answer?.use_fixed_citation && (
                        <div className={styles.card}>
                            <div className={styles.cardBody}>
                                <div className={styles.quoteContainer}>
                                    <div className={`${styles.textBold}`} style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}>
                                        {answer.citation.fullName}
                                    </div>
                                </div>
                            </div>
                            {answer.citation.children.map((child: any, index: any) => (
                                <>
                                    <div key={index} style={{ marginBottom: "0.25rem", marginLeft: "20px" }}>
                                        <span dangerouslySetInnerHTML={{ __html: child.fullName }}></span>
                                    </div>
                                    {child.children &&
                                        Array.isArray(child.children) &&
                                        child.children.map((child: any, index: any) => (
                                            <>
                                                <div key={index} style={{ marginBottom: "0.25rem", marginLeft: "40px" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: child.fullName }}></span>
                                                </div>
                                            </>
                                        ))}
                                </>
                            ))}
                        </div>
                    )}
                {answer && answer.citation && answer.use_expanded_faq && answer?.use_expanded_faq && expandedFaq(answer.citation)}
                {answer && answer.citation && answer.use_fixed_citation && answer?.use_fixed_citation && thamkhao(answer.citation)}
                {
                    answer && answer.citation && answer.citation?.isFaqParsed && !answer?.use_fixed_citation && (
                        parsedFaq(answer.citation?.title, answer.citation?.content, answer.citation?.document_citations)
                    )
                }
                {answer && answer.citation && answer.citation.noteChildContents && !answer?.use_fixed_citation && !answer?.use_expanded_faq && (
                    <div className={styles.card}>
                        <div className={styles.cardBody}>
                            {lawName && (
                                <div className={styles.quoteContainer}>
                                    <div
                                        className={`${styles.textBold} ${styles.textBoldLink}`}
                                        style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}
                                        onClick={() => (answer?.citation?.url ? navigateLaw(answer.citation.url) : null)}
                                    > 
                                        {lawName}
                                        {answer?.citation?.url ? (
                                            <Button
                                                className={`${styles.detailButton}`}
                                                variant="success"
                                                onClick={() => (answer?.citation?.url ? navigateLaw(answer.citation.url) : null)}
                                            >
                                                Xem văn bản gốc
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            <div className={styles.quoteContainer}>
                                <div className={`${styles.textBold}`} style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}>
                                    {answer.citation.chapterName}
                                </div>
                            </div>
                            {/*<div className={`${styles.textBold}`} style={{ marginBottom: '0.25rem', textDecoration: "underline" }}>Vị trí điều:</div>*/}

                            {lawPointName && (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <div className={`${styles.textLight} ${styles.text}`} style={{ display: "inline" }}>
                                        {lawPointName}
                                    </div>
                                    {answer.citation.noteContents && answer.citation.noteContents.length ? (
                                        <div style={{ display: "inline-block", marginLeft: "5px" }}>
                                            <PopoverAnswer noteContents={answer.citation.noteContents} />
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            <div style={{ marginBottom: "1rem" }}>
                                {answer.citation.noteChildContents.map((note: any, index: any) => (
                                    <>
                                        <div key={index} style={{ marginBottom: "0.25rem" }}>
                                            <span
                                                style={{ backgroundColor: note.highlight ? "var(--bg-is-hightlight_not_active)" : "none" }}
                                                dangerouslySetInnerHTML={{ __html: note.wordHtml ? note.wordHtml : note.word }}
                                            ></span>
                                        </div>
                                        {/*<div key={index} style={{ marginBottom: '0.25rem', cursor: (note.highlight) ? 'pointer' : 'initial' }}><span style={{ backgroundColor: (note.highlight) ? '#fce93a' : 'none' }}>{note.wordHtml ? note.wordHtml : note.word}</span></div>*/}
                                    </>
                                ))}
                            </div>

                            <div className={styles.tag}>
                                {answer.citation.type && <span className={styles.tagItem}>{answer.citation.type}</span>}
                                {answer.citation.field && <span className={styles.tagItem}>{answer.citation.field}</span>}
                                {answer.citation.courtType && <span className={styles.tagItem}>{answer.citation.courtType}</span>}
                                {answer.citation.level && <span className={styles.tagItem}>{answer.citation.level}</span>}
                                {answer.citation.agencyIssued && <span className={styles.tagItem}>{answer.citation.agencyIssued}</span>}
                                {answer.citation.signedBy && <span className={styles.tagItem}>Người ký: {answer.citation.signedBy}</span>}
                                {answer.citation.expirationDate && <span className={styles.tagItem}>Ngày hết hiệu lực: {answer.citation.expirationDate}</span>}
                                {answer.citation.legalRelation && <span className={styles.tagItem}>{answer.citation.legalRelation}</span>}
                                {answer.citation.publicStatus && <span className={styles.tagItem}>{answer.citation.publicStatus}</span>}
                                {answer.citation.applyDate && <span className={styles.tagItem}>Áp dụng: {answer.citation.applyDate}</span>}
                                {answer.citation.applyPrecedent && <span className={styles.tagItem}>{answer.citation.applyPrecedent}</span>}
                                {answer.citation.validityStatus && <span className={styles.tagItem}>{answer.citation.validityStatus}</span>}
                                {answer.citation.delict && <span className={styles.tagItem}>{answer.citation.delict}</span>}
                                {answer.citation.judgmentDate && <span className={styles.tagItem}>Ngày công bố: {answer.citation.judgmentDate}</span>}
                            </div>
                        </div>
                        <Modal
                            show={showHighlightCitation}
                            onHide={handleCloseHighlightCitation}
                            size="xl"
                            centered
                            className="modal-citation-container"
                            dialogClassName="modal-highlight-citation  modal-amendments"
                            scrollable
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Các nội dung sửa đổi, hướng dẫn</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Accordion defaultActiveKey="0" alwaysOpen>
                                    {noteMetadatasHighlight &&
                                        noteMetadatasHighlight.map((meta: any, index: any) => (
                                            <>
                                                {meta.guide && (
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header>{meta.guide}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col lg={6} md={12} className="accordionColMobile">
                                                                    <div>
                                                                        <div className={styles.cardHeaderContent}>Nội dung gốc</div>
                                                                        <div className={styles.cardHeaderHightlight}></div>

                                                                        <div className={styles.cardBodyContent}>
                                                                            <div className={styles.cardTextContent}>{meta.originalContent}</div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6} md={12}>
                                                                    <div>
                                                                        <div className={styles.cardHeaderContent}>Nội dung sửa đổi, hướng dẫn</div>
                                                                        <div className={styles.cardHeaderHightlight}></div>

                                                                        <div className={styles.cardBodyContent}>
                                                                            <div className={styles.cardTextContent}>{meta.editContent}</div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )}
                                            </>
                                        ))}
                                </Accordion>
                            </Modal.Body>
                        </Modal>
                    </div>
                )}
            </PivotItem>
        </Pivot>
        </>
    );
});
