import { useEffect, useRef, useState } from "react";
import Select from "react-select";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}
export const DropDownPrompt = ({ optionsPrompt, setPromptSelected, promptSelected }: any) => {

    const [selectedOption, setSelectedOption] = useState("none");
    const handleSelectChange = (selected: any | null) => {
        setSelectedOption(selected)
        setPromptSelected(selected)
    };
    useEffect(() => {
        setSelectedOption(promptSelected)
    }, [promptSelected])
    return (
        <>
            <Select
                className="select-custom"
                value={selectedOption}
                options={optionsPrompt}
                onChange={handleSelectChange}
                placeholder={"Chọn Prompt...."}
                isMulti={false}
                isClearable
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--select-primary)',
                        primary: 'var(--select-primary)',
                    },
                })}
                styles={{
                    control: base => ({
                        ...base,
                        fontSize: '14px',
                        // backgroundColor: 'transparent',
                        color: 'var(--color-text-panel)'
                    }),
                    option: base => ({
                        ...base,
                        fontSize: '14px',
                        // backgroundColor: 'var(--bg-option-select)',
                        color: 'var(--color-text-panel)'
                    }),
                    singleValue: (base) => ({ ...base, color: 'var(--color-text-panel)' }),
                }}
            />
        </>
    )
};

