import styles from "./Configuration.module.css";
import { Label, PrimaryButton, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import {forEach} from "lodash";
import axios from "axios";
import set = Reflect.set;

interface Props {
    className?: string;
    notify: () => void;
    reload: () => void
}

export const WhitelistInit = ({ notify, reload }: Props) => {
    const [isChangeWhitelist, setIsChangeWhitelist] = useState<boolean>(false)
    const [whitelist, setWhitelist] = useState('')

    useEffect(() => {
        getWhitelist();
    }, [])

    const getWhitelist = async () => {
        try {
            const { data } = await axios.get('https://trolyao.tech.gov.vn/get_google_whitelist')
            let whitelistTmp = ''
            // forEach(data?.data, function (item, key) {
            //     console.log('item', item);
                
            //     whitelistTmp += item;
            // })
            if(data && data.data && data.data.length){
                whitelistTmp = data.data?.toString()
            }else {
                return ''
            }
            whitelistTmp = whitelistTmp.replaceAll(',', '\n')

            // if (whitelistTmp === 'success') {
            //     whitelistTmp = ''
            // }
            setWhitelist(whitelistTmp);

        } catch (e) {
            console.log(e)
        }
    };

    const updateWhitelist = async () => {
        try {
            const { data } = await axios.post('https://trolyao.tech.gov.vn/update_google_whitelist', {
                whitelist: whitelist
            })
            if (data) {
                notify();
            }
        } catch (e) {
            console.log(e)
        }
    };

    const onWhitelistChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const newWhitelist = newValue || ""
        setWhitelist(newWhitelist);
    };

    return (
        <>
            <Label className={styles.chatSettingsSeparator}>
                Whitelist
            </Label>
            <div className="textarea-whitelist">
                <TextField
                    defaultValue={whitelist}
                    value={whitelist}
                    multiline
                    autoAdjustHeight
                    onChange={onWhitelistChange}
                />
            </div>

            <div className={`${styles.chatSettingsSeparator} ${styles.flexEnd}`}>

                <PrimaryButton onClick={updateWhitelist}>
                    Apply
                </PrimaryButton>
            </div>
        </>
    )
};
