import { Stack } from "@fluentui/react";
import "./tabDraft.css";
import { observer } from "mobx-react-lite";
import warning from "../../../../assets/uiv2/warning_icon_light_mode.svg";
import { useStore } from "../../../../hooks/useStore";
import { DraftStatus } from "../../../../api/models";
import { UnsentSvg } from "../../draftIcon/unsentSvg";
import { SentSvg } from "../../draftIcon/sentSvg";
import { Form } from "react-bootstrap";

interface Props {
    isMobile: boolean;
}
export const TabDraft = observer(({ isMobile }: Props) => {
    const {
        rootStore: { draftStore }
    } = useStore();
    return (
        <>
            {isMobile ? (
                <>
                    <Form.Group className="tabs-draft" style={{ marginBottom: "10px" }}>
                        <Form.Check
                            id="idSent"
                            className="radio-draft"
                            value="draft"
                            type="radio"
                            label="Đã gửi"
                            onChange={() => draftStore.SET_ACTIVE_DRAFT(DraftStatus.sent)}
                            checked={draftStore.activeDraft === DraftStatus.sent}
                        />
                        <Form.Check
                            id="idUnsent"
                            className="radio-draft"
                            value="chat"
                            type="radio"
                            label="Chưa gửi"
                            onChange={() => draftStore.SET_ACTIVE_DRAFT(DraftStatus.unsent)}
                            checked={draftStore.activeDraft === DraftStatus.unsent}
                        />
                    </Form.Group>
                </>
            ) : (
                <Stack className="tabs-draft">
                    {/* <Stack.Item
                        grow
                        className={`tab-draft ${draftStore.activeDraft === DraftStatus.sent ? "active-draft" : null}`}
                        onClick={() => draftStore.SET_ACTIVE_DRAFT(DraftStatus.sent)}
                    >
                        <SentSvg color={draftStore.activeDraft === DraftStatus.sent ? "#FFF" : "#EE0033"} />
                        <span>Đã gửi</span>
                    </Stack.Item>
                    <Stack.Item
                        grow
                        className={`tab-draft ${draftStore.activeDraft === DraftStatus.unsent ? "active-draft" : null}`}
                        onClick={() => draftStore.SET_ACTIVE_DRAFT(DraftStatus.unsent)}
                    >
                        <UnsentSvg color={draftStore.activeDraft === DraftStatus.unsent ? "#FFF" : "#EE0033"} />
                        <span>Chưa gửi</span>
                    </Stack.Item> */}
                    <Form.Group className="tabs-draft" style={{ marginBottom: "10px" }}>
                        <Form.Check
                            id="radio-sent"
                            className="radio-draft"
                            value="draft"
                            type="radio"
                            label="Đã gửi"
                            onChange={() => draftStore.SET_ACTIVE_DRAFT(DraftStatus.sent)}
                            checked={draftStore.activeDraft === DraftStatus.sent}
                        />
                        <Form.Check
                            id="radio-unsent"
                            className="radio-draft"
                            value="chat"
                            type="radio"
                            label="Chưa gửi"
                            onChange={() => draftStore.SET_ACTIVE_DRAFT(DraftStatus.unsent)}
                            checked={draftStore.activeDraft === DraftStatus.unsent}
                        />
                    </Form.Group>
                </Stack>
            )}
        </>
    );
});
