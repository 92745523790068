import { useRef, useState, useEffect, useMemo } from "react";
import {
    Checkbox,
    Panel,
    DefaultButton,
    TextField,
    SpinButton,
    Label,
    PrimaryButton,
    Button,
    DialogFooter, Dialog,
    Dropdown,
} from "@fluentui/react";
import backPng from "../../assets/back.png"
import seeMore from "../../assets/icon-info-black.svg"
import { Card, FormCheck } from "react-bootstrap"
import { findIndex, isArray, forEach, uniqBy, find, isEmpty, filter, split, slice } from 'lodash'
import { AskResponsePrompt, CitationsByIdRequest, CitationsRequestHotIssue, EnterPrise, issue_buttons, RuleSearch, multiple_data_points } from "../../api/models";
import styles from "./Chat.module.css";
import { chatApi, chatApiVtccFlow, clearMessagesInChatSession, Approaches, AskResponse, addChatMessages, ChatRequest, createChatSession, initDefaultChat, listAllChatSession, ChatTurn, EditPrompt, setDefaultPrompt, getPrompt, PromptRequest, citationsCompareApi, citationsApi, CitationsRequest, getDefaultQuestion, chatSessionById, getConfiguration, citationsPopupApi, getEnterprise, citationsByIdApi } from "../../api";
import { Answer, AnswerError, AnswerLoading, AnswerInitChat, SwitchContext } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs, PopupCitationPanel } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import eventBus from "../../plugins/EventBus"
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import "react-tooltip/dist/react-tooltip.css";
import { getConversationStorage, setConversationStorage } from "../../utils/conversationExperience";
import { useViewport } from "../../hooks/useViewport";
import { ConfirmClearChatPopup } from "../../components/Chat";
import { CreatePrompt, DropDownPrompt, Password } from "../../components/Prompt";
import { CloseDebug } from "../../components/CloseDebug";
import { QuestionInit } from "../../components/Configuration/QuestionInit";
import { WhitelistInit } from "../../components/Configuration/WhitelistInit";
import { useNavigate, useParams } from "react-router-dom";
import { checkIsExperience } from "../../utils/checkIsExperience";
import { openWindowToUrl, replaceDomain } from "../../utils/openWindowUrl";
import { useStore } from "../../hooks/useStore";
import { isShowConfiguration } from "../../utils/isShowConfiguration";
import { checkBoxStyles } from "../../utils/checkBoxStyles";
import { errorChatResult, ERROR_COUNT_MAX, ERROR_DEFAULT, ERROR_NETWORK_MAX } from "../../utils/errorChatResult";
import { defaultConfiguration } from "../../utils/defaultConfiguration";
import ControlledTabs from "../../components/Tabs/Tabs";
import loadingTree from "../../assets/loading/bars-rotate-fade.svg"
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import PopupLawInfo from "../../components/Tabs/PopupLawInfo";
import ButtonCancelChat from "./ButtonCancel";
import { getDoneResultsWithFlatMap } from "@utils/chatSession";
import { observer } from "mobx-react-lite";
import { AccountList } from "@components/AccountList";
import { enterpriseIdLocal, nameAssistant, setEnterPriseLocal, subUnitNameAssistant, unitNameAssistant } from "@utils/localStorage";
import showToasty from "@hooks/useNotify";

const optionsRitrival: any = [
    { text: 'Vectors + Text(Hybrid)', key: 'hybrid' },
    { text: 'Vectors', key: 'vectors' },
    { text: 'Text', key: 'text' }
]
const optionsLargeLanguage: any = [
    { text: 'GPT-3.5-Turbo-0125-16k', key: 'gpt-35-turbo' },
    { text: 'GPT-4o-128K', key: 'gpt-4o' },
    { text: 'Gemini-1.5-Pro-001-2M', key: 'llm-google-model' },
    { text: 'VT-Llama-8B-Instruct-v0.3-4K', key: 'llm-vtcc-foundation' },
    { text: 'Llama-3-70B-Instruct-8K', key: 'llm-vtcc-foundation-70b' },
]
const optionsLargeLanguageModel: any = [
    { text: 'GPT-3.5-Turbo-0125-16k', key: 'gpt-35-turbo' },
    { text: 'GPT-4o-128K', key: 'gpt-4o' },
    { text: 'Gemini-1.5-Pro-001-2M', key: 'llm-google-model' },
    { text: 'VT-Llama-8B-Instruct-v0.3-4K', key: 'llm-vtcc-foundation' },
    { text: 'Llama-3-70B-Instruct-8K', key: 'llm-vtcc-foundation-70b' },
]

const Chat = observer(({
    messageRequest,
    setMessageRequest,
    idChatBySessionName}: any) => {
    const {
        rootStore: { sessionChatsStore, citationsStore, configurationStore },
        } = useStore();
    const params = useParams();
    const navigate = useNavigate();
    const viewPort = useViewport();
    const isMobile = viewPort.width <= 768;

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false)
    const [vtccFlow, setVtccFlow] = useState<boolean>(false)
    const [openPrompt, setOpenPrompt] = useState(false);
    const [openWhitelist, setOpenWhitelist] = useState(false);
    const [openQuestionInit, setOpenQuestionInit] = useState(false);

    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(0.2);
    const [temperatureGenQuery, setTemperatureGenQuery] = useState<number>(0);
    // const [searchSource, setSearchSource] = useState<string>("azure")
    const [typeFullTextSearch, setTypeFullTextSearch] = useState<string>("Hybrid Search")
    const [searchSource, setSearchSource] = useState<string>("VTCC")

    const [versionSearch, setVersionSearch] = useState<string>("gpt-35-turbo")
    const [retrieveCount, setRetrieveCount] = useState<number>(5);
    const [retrieveRuleCount, setRetrieveRuleCount] = useState<number>(20);
    const [retrieveLevelCount, setRetrieveLevelCount] = useState<number>(20);
    const [attachHistoryCount, setAttachHistoryCount] = useState<number>(1);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [useKg, setUseKg] = useState<boolean>(false);
    const [useConfirmAnswer, setUseConfirmAnswer] = useState<boolean>(true);
    const [subtype, setSubtype] = useState('Priority semantic');

    const [graphOption, setGraphOption] = useState<string>("option_1")
    const [haveRelationship, setHaveRelationship] = useState<boolean>(false)

    const [autoClearChat, setAutoClearChat] = useState<boolean>(false)
    const [showTextGen, setShowTextGen] = useState<boolean>(true)
    const [retrievalMode, setRetrievalMode] = useState<string>("text")
    const [largeLanguageModel, setLargeLanguageModel] = useState<string>("llm-vtcc-foundation-70b")
    const [llmGenQuery, setLlmGenQuery] = useState<string>("gpt-35-turbo")
    const [noPrompt, setNoPrompt] = useState<boolean>(false)
    const [noQueryGeneration, setNoQueryGeneration] = useState<boolean>(false)
    const [noSearchEngine, setNoSearchEngine] = useState<boolean>(false)
    const [isRerankRetrieve, setIsRerankRetrieve] = useState<boolean>(false)
    const [isSuggestionByLlm, setIsSuggestionByLlm] = useState<boolean>(true)
    const [isLlmFilter, setIsLlmFilter] = useState<boolean>(true)
    const [isConfirmDecision, setIsConfirmDecision] = useState<boolean>(true)


    const [useWhitelist, setUseWhitelist] = useState<boolean>(true)
    const [googleSeo, setGoogleSeo] = useState<boolean>(false)
    const [configuration, setConfiguration] = useState<any>(defaultConfiguration())

    // Play voice
    const [isPlayVoive, setIsPlayVoive] = useState<any>({
        status: false,
        config: false
    })


    const [promptArr, setPromptArr] = useState<AskResponsePrompt[]>([])
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [disabledSwitchButton, setDisabledSwitchButton] = useState<boolean>(true);
    const [sessionId, setSessionId] = useState<string>("")
    const [requestSocket, setRequestSocket] = useState<any>({})
    const [isMobileAnalysisPanel, setMobileAnalysisPanel] = useState<boolean>(false)
    const [prefixPrompt, setPrefixPrompt] = useState<string>("")
    const [followUpPrompt, setFollowUpPrompt] = useState<string>("")
    const [queryPrompt, setQueryPrompt] = useState<string>("")
    const [graphPrompt, setGraphPrompt] = useState<string>("")

    const [idPrompt, setIdPrompt] = useState<string>("")
    const lastQuestionRef = useRef<string>("");
    const errorSectionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const popupRefHuy = useRef<HTMLDivElement | null>(null);

    const [opentPopupConfirm, setOpenPopupConfirm] = useState<boolean>(false);
    const [passwordConfirm, setPasswordConfirm] = useState<string>("")
    const [errorEditPrompt, setErrorEditPrompt] = useState<string>("")
    const [saveChangePrompt, setSaveChangePrompt] = useState<boolean>(false);
    const [loadingCitation, setLoadingCitation] = useState<boolean>(false);
    // propmpt
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPromptChange, setIsPromptChange] = useState<boolean>(false);
    const [isPromptChangeToApply, setIsPromptChangeToApply] = useState<boolean>(false);
    const [promptCopy, setPromptCopy] = useState<any>({
        follow_up_prompt: "",
        prefix_prompt: "",
        query_prompt: "",
        graph_prompt: ""
    })
    const [promptSelected, setPromptSelected] = useState<any>(null)
    const [showCreatePrompt, setShowCreatePrompt] = useState<boolean>(false)
    const [showConfirmDefaultPrompt, setShowConfirmDefaultPrompt] = useState<boolean>(false)
    const [passwordTextError, setPasswordTextError] = useState<any>(undefined)

    const [error, setError] = useState<unknown>();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [openConfirmClearChat, setOpenConfirmClearChat] = useState<boolean>(false)
    const [openInfoLawDetails, setOpenInfoLawDetails] = useState<boolean>(false)
    const [answerLawDetails, setAnswerLawDetails] = useState(null)

    const [showAccount, setShowAccount] = useState(false)
    const [enterPrises, setEnterPrises] = useState<EnterPrise[]>([])


    // experience
    const [indexConversationActive, setIndexConversationActive] = useState<any>(0);
    const idConversationActive = useRef<string>("");
    const isExperienceUrl = checkIsExperience()
    //Default question
    const [defaultQuestion, setDefaultQuestion] = useState(null)
    const [loadingHoverSupcontainer, setLoadingHoverSupcontainer] = useState<boolean>(false)
    const [openHoverSupcontainer, setOpenHoverSupcontainer] = useState<boolean>(false)
    const [dataHoverSupcontainer, setDataHoverSupcontainer] = useState<any>({citation: null})
    const [elHoverSupcontainerParent, setElHoverSupcontainerParent] = useState<any>(null)
    const [elHoverSupcontainer, setElHoverSupcontainer] = useState<any>(null)

    const userIdLocal = JSON.parse(localStorage.getItem('_user') || '{}')

    const isShowChangeAccount = userIdLocal?.isShowEnterprise
    
    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onPromptPrefixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPrefixPrompt(newValue || "");
        listenPromptChangeToSave
        listenPromptChangeToApply
    };

    const onPromptFollowUpChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFollowUpPrompt(newValue || "");
        listenPromptChangeToSave
        listenPromptChangeToApply
    };

    const onPromptQueryChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQueryPrompt(newValue || "");
        listenPromptChangeToSave
        listenPromptChangeToApply
    };

    const onPromptGraphChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setGraphPrompt(newValue || "");
        listenPromptChangeToSave
        listenPromptChangeToApply
    };

    const onPasswordConfirmChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPasswordConfirm(newValue || "");
        setErrorEditPrompt("")
    };

    const onSelectedRetrivalMode = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: any) => {
        setRetrievalMode(newValue.key || "");
    };

    const onSelectedLargeLanguageModel = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: any) => {
        setVersionSearch("gpt-35-turbo")
        setLargeLanguageModel(newValue.key || "");
        if(newValue.key === 'llm-tiger-7b') {
            setNoPrompt(false)
        }
    };

    const onSelectedLlmGenQuery = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: any) => {
        setLlmGenQuery(newValue.key || "");
    };

    const onTemperatureChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setTemperature(parseFloat(newValue || "0.2"));
    };

    const onTemperatureGenQueryChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setTemperatureGenQuery(parseFloat(newValue || "0"));
    };
    
    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "5"));
    };
    const onRetrieveRuleCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveRuleCount(parseInt(newValue || "20"));
    };
    const onRetrieveLevelCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveLevelCount(parseInt(newValue || "20"));
    };

    const onAttachHistoryCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setAttachHistoryCount(parseInt(newValue || "1"));
    };

    const onAutoClearChatChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setAutoClearChat(!!checked);
        if (checked) {
            setAttachHistoryCount(0);
        } else {
            setAttachHistoryCount(1)
        }
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);

    };
    const onRenderFooterContent = () => (
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            <DefaultButton className={`${styles.chatSettingsSeparator}`} onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>
            <PrimaryButton className={styles.chatSettingsSeparator} onClick={() => { setOpenPrompt(true), setOpenQuestionInit(false), setOpenWhitelist(false) }}>
                Prompt
            </PrimaryButton>
            <PrimaryButton className={styles.chatSettingsSeparator} onClick={() => { setOpenQuestionInit(true), setOpenPrompt(false), setOpenWhitelist(false) }}>
                Question
            </PrimaryButton>
            <PrimaryButton className={`${styles.chatSettingsSeparator} ${styles.chatSettingsWitelist}`} onClick={() => { setOpenQuestionInit(false), setOpenWhitelist(true), setOpenPrompt(false) }}>
                Whitelist
            </PrimaryButton>
        </div>
    );
    const onNoRenderFooterContent = () => (
        <></>
    );


    const getApiEnterPrises = async () => {
        const result = await getEnterprise('66399eddea8428088126ed54');
        if(result?.data?.length) {
            const formartData =  result?.data.filter((item: any) => {
                return item.name !== 'Bộ Thông tin và Truyền thông'
            })
            setEnterPrises(formartData)
        }
    }

    const checkHadLaw = async (content: any, el: any, els: any) => {
        let contentRequest = content;
        contentRequest = contentRequest.trim();
        if (contentRequest && contentRequest.includes('. ')) {
            const contentPieces = contentRequest.split('. ')
            if (contentPieces && isArray(contentPieces) && contentPieces[0] && contentRequest.startsWith('-')) {
                contentRequest = contentPieces[0]
            }else if (contentPieces && isArray(contentPieces) && contentPieces[1]) {
                contentRequest = contentPieces[1]
            }
        }

        // if (contentRequest.startsWith('-')) {
        //     contentRequest = contentRequest.slice(0, 1);
        // }

        const request: CitationsRequest = { content: contentRequest };
        const result = await citationsApi(request);
        if (result && result.data && result.data.url) {
            el.classList.add('is-law-underline');
            el.addEventListener('click', function (e: any) {
                window.open(replaceDomain(result.data.url), '_blank');
            });
            el.addEventListener('mouseout', function (e: any) {
                forEach(els, function (e: any, key: any) {
                    e.classList.remove('is-law-underline')
                });
            });
        }
    };

    const getLawItem = (text: any, laws: any) => {
        let trimmedText = (text && typeof text === 'string') ? text.trim() : '';
        if (trimmedText) {
            const splitText = trimmedText.split(/\. |\- /);
            if (splitText.length > 1) {
                trimmedText = splitText[1];
            }
        }

       let law = null;
        if (laws && laws.length && trimmedText) {
            law = laws.find((law: any) => law && law.name && law.name.includes(trimmedText));
        }
        return law
    };

    const onResetChatSocket = () => {
        sessionChatsStore.changeQuestionFollowup('')
        sessionChatsStore.changeIsClickSpecific(false)
        sessionChatsStore.SET_ID_HOT_ISSUE('')
        sessionChatsStore.SET_ISSUE_BUTTON({
            display_name: '',
            normalized_names: [],
            type: '',
        })
        setIsLoading(false)
        sessionChatsStore.CLEAR_TRACK_SEARCH()
        sessionChatsStore.SET_STEP_SEARCH(0)
        sessionChatsStore.SET_EXPANDED_SEARCH(false)
        sessionChatsStore.SET_GENERIC_BUTTON('')
    }

    const setLastQuestionRefByAnswers = (answers: any) => {
        if (answers && answers.length) {
            const lastAnswers = answers[answers.length - 1];
            if (lastAnswers && lastAnswers[0]) {
                lastQuestionRef.current = lastAnswers[0];
            }
        } else {
            lastQuestionRef.current = '';
        }
    };
    const makeApiGetPrompt = () => {
        async function fetchMyAPI() {
            const result = await getPrompt();
            let cloneArray: any = result
            if (result && result.length) {
                result.map((v: any, ind: any) => (
                    cloneArray[ind].value = v.name,
                    cloneArray[ind].label = v.name
                ))
            }
            setPromptArr(cloneArray)
            cloneArray?.forEach((v: any, idx: any) => {
                if (v['is_apply']) {
                    setPromptSelected((prev: any) => ({ ...prev, ...v }))
                    setPromptCopy((prevState: object) => ({
                        ...prevState,
                        prefix_prompt: cloneArray[idx]["prefix_prompt"],
                        follow_up_prompt: cloneArray[idx]["follow_up_prompt"],
                        query_prompt: cloneArray[idx]["query_prompt"],
                        graph_prompt: cloneArray[idx]["graph_prompt"],
                    }))
                }
            })
        }

        fetchMyAPI()
    }
    const makeApiGetDefaultQuestion = async () => {
        try {
            const result = await getDefaultQuestion();
            if(result) {
                setDefaultQuestion(result)
            }
        } catch (e) {
            console.log(e)
        }
    }
    
    
    const makeApiGetChatById = async (id: string) => {
        const postData = {
            sessionId: id
        }
        try {
            const result = await chatSessionById(postData)
            citationsStore.addCitationLinks([])
            if(result && result.data) {
                const allChatById = result.data[0]?.messages
                if (allChatById && allChatById.length) {

                    let answerBySession: any[] = []
                    allChatById.forEach((v: any, ind: any) => {
                        const question = v.question
                        const temp = Object.assign({}, v)
                        // delete temp.question
                        answerBySession[ind] = [question, temp]
                    });
                    setAnswers(answerBySession);
                    setIsLoading(false)
                    setError(false)
                    lastQuestionRef.current = answerBySession[answerBySession.length - 1][0]
                } else if (idChatBySessionName) {
                    setAnswers([]);
                    setIsLoading(false)
                    setError(false)
                    lastQuestionRef.current = ""
                }
                setIsPlayVoive((prev: any) => ({...prev, status: false}))
            }
        } catch (e: any) {}   
    }
    
    const makeApiRequest = async (question: string, id = sessionId, retry: boolean = false) => {   
        if(!!(!unitNameAssistant() && isShowChangeAccount)) {
            showToasty("Chưa chọn đơn vị", "error");
            return
        }  

        const userIdLocal = JSON.parse(localStorage.getItem('_user') || '{}')
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        let answersByAttachHistoryCount = []
        // answersByAttachHistoryCount = attachHistoryCount != 0 && configurationStore.isHistory && !sessionChatsStore.getQuestionFollowup ? answers.slice(-attachHistoryCount) : [];
        answersByAttachHistoryCount = attachHistoryCount != 0 && !sessionChatsStore.getQuestionFollowup ? answers.slice(-attachHistoryCount) : [];

        const history: ChatTurn[] = !errorSectionRef.current ? answersByAttachHistoryCount.map(a => ({ user: a[0], bot: a[1].answer, gen_query: a[1].gen_query})) : [];
        const lastIsLegal = answersByAttachHistoryCount?.[answersByAttachHistoryCount.length - 1]?.[1]?.is_legal

        const promptBySave: any = saveChangePrompt ? {
            prefix_prompt: prefixPrompt,
            follow_up_prompt: followUpPrompt,
            query_prompt: queryPrompt,
            graph_prompt: graphPrompt
        } : {}

        let conversation_id = null
        const url = window.location.href;

        if (url && url.endsWith('experience/chat')) {
            let conversation: any = sessionStorage.getItem('conversation');
            if (conversation && typeof conversation === "string") {
                conversation = JSON.parse(conversation)
                if (conversation && conversation.length && conversation[indexConversationActive]) {
                    if (conversation[indexConversationActive].id) {
                        conversation_id = conversation[indexConversationActive].id
                    }
                }
            }
        }

        const isClickQuestionFollowup = sessionChatsStore.getIsClickFollowupQuestion

        const questionRequest = sessionChatsStore.getQuestionFollowup ? sessionChatsStore.getQuestionFollowup : question
        const need_search_specific = sessionChatsStore.is_click_specific ? {need_search_specific: sessionChatsStore.need_search_specific} : {}
        const continue_step_search = sessionChatsStore.continue_step_search ? {continue_step_search: sessionChatsStore.continue_step_search} : {}
        const expanded_search = sessionChatsStore.expanded_search ? {expanded_search: sessionChatsStore.expanded_search} : {}
        const payload = sessionChatsStore.payloadGeneric ? {payload: sessionChatsStore.payloadGeneric} : {}
        const is_used_history = configurationStore.isHistory ? {is_used_history: configurationStore.isHistory} : {}

        const request: ChatRequest = !vtccFlow ? {
            history: [...history, { user: questionRequest, bot: undefined, gen_query: undefined }],
            approach: Approaches.ReadRetrieveRead,
            username: userIdLocal?.username,
            fullname: userIdLocal?.fullname,
            overrides: {
                ...promptBySave,
                ...need_search_specific,
                ...continue_step_search,
                ...expanded_search,
                ...payload,
                ...is_used_history,
                prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                top: retrieveCount,
                rule_search: retrieveRuleCount,
                level_search: retrieveLevelCount,
                semantic_ranker: useSemanticRanker,
                semantic_captions: useSemanticCaptions,
                suggest_followup_questions: useSuggestFollowupQuestions,
                // semantic_law_retriever: searchSource === 'VTCC' ? true : false,
                semantic_law_retriever: typeFullTextSearch === 'Semantic Search' ? true : false,
                fulltext_search_retriever: typeFullTextSearch === 'Hybrid Search' ? true : false,
                use_whitelist: useWhitelist,
                google_seo: useWhitelist ? !googleSeo : false,
                temperature: temperature,
                temperature_gen_query: temperatureGenQuery,
                retrieval_mode: retrievalMode,
                no_prompt: noPrompt,
                no_search_engine: noSearchEngine,
                rerank_retrieve: isRerankRetrieve,
                use_llm_filter: isLlmFilter,
                confirm_decision: isConfirmDecision,
                suggestion_by_llm: isSuggestionByLlm,
                no_gen_query: isClickQuestionFollowup ? isClickQuestionFollowup : noQueryGeneration,
                // chatgpt_model: largeLanguageModel === 'gpt-35-turbo' ? versionSearch : largeLanguageModel,
                chatgpt_model: largeLanguageModel,
                chatgpt_model_gen_query: llmGenQuery,
                conversation_id: conversation_id,
                use_knowledge_graph: useKg,
                // knowledge_graph_option: useKg ? graphOption : null,
                graph_have_relationship: haveRelationship,
                last_is_legal: lastIsLegal ?? false,
                custom_enterprise_id: enterpriseIdLocal(),
                use_confirm_answer: useConfirmAnswer,
                type_hybrid_search: typeFullTextSearch === 'Hybrid Search' ? subtype : ''
            }
        } : {
            query: question
        };

        const makeApiRequestSocket = () => {
            setRequestSocket({ request, question, id, retry });
        };

        const makeApiRequestNormal = async () => {
            const url = window.location.href;
            let isExperience = false
            if (url && url.endsWith('experience/chat')) {
                isExperience = true
            }
            try {
                const idConversationActiveOld = idConversationActive.current;
                let result = vtccFlow ? await chatApiVtccFlow(request) : await chatApi(request);
                sessionChatsStore.updateErrorCountNetwork(0)
                sessionChatsStore.changeIsClickFollowupQuestion(false)
                // TODO check
                if (result && result.documents && isArray(result.documents) && result.documents?.length) {
                    result = transformDataSpecial(result, question);
                }

                if (!isExperience) {
                    if (!params.id) {
                        const requestCreate: any = {
                            userId: userIdLocal.userId,
                            question: question
                        };
                        const resultCreateChatSession = await initDefaultChat(requestCreate);
                        sessionChatsStore.getIdParams(resultCreateChatSession?.data?.id)
                        sessionChatsStore.addItem(resultCreateChatSession?.data)
                        navigate(resultCreateChatSession?.data?.id ?? '')                        
                    }
                    const requestAnswer: any = {
                        ...result,
                        question: question,
                        senderId: userIdLocal.userId,
                        sessionId: sessionChatsStore.idRouter
                    }

                    const resultAddMessage = await addChatMessages(requestAnswer)
                    if(answers && answers.length === 0) {
                        eventBus.dispatch('reloadMessageChat', {
                            id: params.id,
                            firstMessage: resultAddMessage?.data?.question
                        })
                    }
                    setIsPlayVoive((prev: any) => ({...prev, status: true}))

                    const idMessage = resultAddMessage.data.id
                    if (params.id === resultAddMessage?.data?.chatSessionId) {
                        if (result.hasOwnProperty('question')) {
                            result = { ...result, id: idMessage}
                        } else {
                            result = { ...result, question: question, id: idMessage}
                        }   
                        setAnswers([...answers, [question, result]]);
                    }

                } else {
                    const idConversationActiveNew = idConversationActive.current;
                    if (idConversationActiveOld === idConversationActiveNew) {
                        setAnswers([...answers, [question, result]]);
                    }
                }
                errorSectionRef.current = ''
                // reloadData()
            } catch (e: any) {
                const errorCount = sessionChatsStore.errorCountNetwork
                const messageErr = e && e?.toString()?.trim() && errorCount < ERROR_COUNT_MAX 
                    ? errorChatResult(e.toString().trim() || '') 
                    : e && e?.toString()?.trim() && errorCount >= ERROR_COUNT_MAX 
                    ? errorChatResult(ERROR_NETWORK_MAX)
                    : errorChatResult(ERROR_DEFAULT)
                
                setError(messageErr)
                errorSectionRef.current = question
            } finally {
                sessionChatsStore.changeQuestionFollowup('')
                sessionChatsStore.changeIsClickSpecific(false)
                setIsLoading(false);
                sessionChatsStore.SET_STEP_SEARCH(0)
                sessionChatsStore.SET_EXPANDED_SEARCH(false)
                sessionChatsStore.SET_GENERIC_BUTTON('')
            }
        };

        if (showTextGen) {
            makeApiRequestSocket()
        } else {
            await makeApiRequestNormal();
        }

    }

    const hasResponseSocket = async (data: AskResponse, question: string, id = sessionId, retry: boolean = false) => {
        const url = window.location.href;
        let isExperience = false
        if (url && url.endsWith('experience/chat')) {
            isExperience = true
        }
        try {
            const userIdLocal = JSON.parse(localStorage.getItem('_user') || '{}')
            let result = data;

            // transform data
            if (result && result.documents && isArray(result.documents) && result.documents?.length) {
                result = transformDataSpecial(data, question);
            }
            if(result && sessionChatsStore.track_searchs && sessionChatsStore.track_searchs.length > 0) {
                const doneResult = getDoneResultsWithFlatMap(sessionChatsStore.track_searchs);
                result.track_search = doneResult
            }

            if (!isExperience) {
                if (!params.id) {
                    const requestCreate: any = {
                        userId: userIdLocal.userId,
                        question: question
                    };
                    const resultCreateChatSession = await initDefaultChat(requestCreate);
                    sessionChatsStore.getIdParams(resultCreateChatSession?.data?.id)
                    sessionChatsStore.addItem(resultCreateChatSession?.data)
                    // navigate(resultCreateChatSession?.data?.id ?? '')
                }
                const requestAnswer: any = {
                    ...result,
                    question: question,
                    senderId: userIdLocal.userId,
                    sessionId: sessionChatsStore.idRouter
                };

                const resultAddMessage = await addChatMessages(requestAnswer);

                if(!params.id) {
                    navigate(sessionChatsStore.idRouter ?? '')
                }

                if(answers && answers.length === 0) {
                    eventBus.dispatch('reloadMessageChat', {
                        id: params.id,
                        firstMessage: resultAddMessage?.data?.question
                    })
                }
                
                setIsPlayVoive((prev: any) => ({...prev, status: true}))
                const idMessage = resultAddMessage.data.id;
                if (params.id === resultAddMessage?.data?.chatSessionId) {
                    if (result.hasOwnProperty('question')) {
                        result = { ...result, id: idMessage}
                    } else {
                        result = { ...result, question: question, id: idMessage}
                    }                    
                    setAnswers([...answers, [question, result]]);
                }

            } else {
                setAnswers([...answers, [question, result]]);
            }

            errorSectionRef.current = '';
        } catch (error) {
            const messageErr = errorChatResult(ERROR_NETWORK_MAX)
            setError(messageErr)
            errorSectionRef.current = question
            if (!isExperience) {
                errorSectionRef.current = question
            }
        } finally {
            sessionChatsStore.changeQuestionFollowup('')
            sessionChatsStore.changeIsClickSpecific(false)
            setIsLoading(false)
            sessionChatsStore.CLEAR_TRACK_SEARCH()
            sessionChatsStore.SET_STEP_SEARCH(0)
            sessionChatsStore.SET_EXPANDED_SEARCH(false)
            sessionChatsStore.SET_GENERIC_BUTTON('')
        }
    };

    const transformDataSpecial = (data: any, question: any) => {
        const result = data
        if (result && result.answer) {
            const answerPiece = result.answer.split("\n");

            let answersSort = answerPiece;
            let answerHtml = ''
            if (answersSort && isArray(answersSort)) {
                forEach(answersSort, function (item, key) {
                    answerHtml += `<div class="law-text-hover">${item}</div>`
                })
            }
            result.answer = answerHtml
        }
        return result
    };

    const onErrorSocket = (data: any, question: string, id = sessionId, retry: boolean = false) => {   
        sessionChatsStore.changeQuestionFollowup('') 
        sessionChatsStore.changeIsClickSpecific(false)    
        let toString = (obj: any) => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');        
        setIsLoading(false)
        errorSectionRef.current = question
        const messageErr = data && toString(data)?.trim() ? errorChatResult(toString(data)?.trim() || '') : errorChatResult(ERROR_DEFAULT)
        if(!messageErr?.includes('Do kết nối mạng không ổn định')) {
            sessionChatsStore.updateErrorCountNetwork(0)
            sessionChatsStore.changeIsClickFollowupQuestion(false)
            sessionChatsStore.CLEAR_TRACK_SEARCH()
            sessionChatsStore.SET_STEP_SEARCH(0)
            sessionChatsStore.SET_EXPANDED_SEARCH(false)
            sessionChatsStore.SET_GENERIC_BUTTON('')
        }
        setError(messageErr)
        // setError(data?.error ?? 'error');
    };

    const onEditPrompt = async () => {
        const request: PromptRequest = {
            bot: "GPT_OPENAI",
            password: passwordConfirm,
            prefixPrompt: prefixPrompt,
            followUpPrompt: followUpPrompt,
            queryPrompt: queryPrompt,
            graphPrompt: graphPrompt,
            idPrompt: idPrompt,
            is_apply: true
        };

        EditPrompt(request)
            .then(result => {
                notify("Cập nhật thành công")
                setPromptCopy((prevState: object) => ({
                    ...prevState,
                    prefix_prompt: result["prefix_prompt"],
                    follow_up_prompt: result["follow_up_prompt"],
                    query_prompt: result["query_prompt"],
                    graph_prompt: result["graph_prompt"],
                }))
                setPrefixPrompt(result["prefix_prompt"] || "");
                setFollowUpPrompt(result["follow_up_prompt"] || "");
                setQueryPrompt(result["query_prompt"] || "");
                setGraphPrompt(result["graph_prompt"] || "");
                setPasswordConfirm("")
                setOpenPopupConfirm(false)
                makeApiGetPrompt()
            })
            .catch(e => {
                console.log(e);
                setErrorEditPrompt(e)
            })
    }

    const onSetDefaultPrompt = async (e: any) => {
        if (e.target.checked) {
            setShowConfirmDefaultPrompt(true)
        }
    }

    const makeApiDefaultPrompt = async (p: string) => {
        const request: PromptRequest = {
            bot: "GPT_OPENAI",
            password: p,
            prefixPrompt: prefixPrompt,
            followUpPrompt: followUpPrompt,
            queryPrompt: queryPrompt,
            graphPrompt: graphPrompt,
            idPrompt: idPrompt,
            is_apply: true
        };
        setDefaultPrompt(request)
            .then(result => {
                notify("Cập nhật prompt mặc định thành công")
                setShowConfirmDefaultPrompt(false)
                makeApiGetPrompt()
            })
            .catch(e => {
                console.log(e);
                setPasswordTextError(e || undefined)
            })
    }

    const clearChat = async () => {
        const url = window.location.href;
        let isExperience = false;
        if (url && url.endsWith('experience/chat')) {
            isExperience = true
        }

        if (!isExperience) {
            try {
                const requestClear: any = {
                    sessionId: params.id
                };
                await clearMessagesInChatSession(requestClear)
                eventBus.dispatch('clearchatNavbar', { id: params.id })
                setOpenConfirmClearChat(false)
            } catch (e) {
                console.log(e)
            }
        } else {
            const conversation = getConversationStorage();
            if (indexConversationActive > -1 && conversation && conversation[indexConversationActive] && conversation[indexConversationActive].answers) {
                conversation[indexConversationActive].answers = [];
                setConversationStorage(conversation)
            }
        }
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setOpenConfirmClearChat(false);
    };
    const clearChatByDelete = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };
    const newChatSession = () => {
        lastQuestionRef.current = "";
        setSessionId('')
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    }
    

    const listenPromptChangeToSave = useMemo(() => {
        if (saveChangePrompt) {
            setIsPromptChange(true)
        } else {
            if (promptCopy.prefix_prompt !== prefixPrompt || promptCopy.follow_up_prompt !== followUpPrompt || promptCopy.query_prompt !== queryPrompt || promptCopy.graph_prompt !== graphPrompt) {
                setIsPromptChange(true)
            } else {
                setIsPromptChange(false)
            }
        }
    }, [prefixPrompt, followUpPrompt, queryPrompt, graphPrompt, promptCopy]);
    const listenPromptChangeToApply = useMemo(() => {
        if (promptCopy.prefix_prompt !== prefixPrompt || promptCopy.follow_up_prompt !== followUpPrompt || promptCopy.query_prompt !== queryPrompt || promptCopy.graph_prompt !== graphPrompt) {
            setIsPromptChangeToApply(true)
        } else {
            setIsPromptChangeToApply(false)
        }
    }, [prefixPrompt, followUpPrompt, queryPrompt, graphPrompt, promptCopy]);


    const scrollIntoView = () => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }
    const notify = (e: any) => toast.success(`${e}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const onExampleClicked = async (example: string) => {
        makeApiRequest(example);
    };

    const makeApiCitationRequest = async (content: string | CitationsRequestHotIssue, indexSelected: number) => {
        var dataResult = null
        const request: CitationsRequest = typeof content === 'string' ? { content: content } : { ...content };

        const result = await citationsApi(request);
        if (result && result.data) {
            dataResult = result.data
        } else {
            if (answers && answers[indexSelected] && answers[indexSelected][1]) {
                const textList = answers[indexSelected][1]['data_points']
                const requestCompare: any = { word: content, textList: textList };
                const resultCompare = await citationsCompareApi(requestCompare);
                if (resultCompare && resultCompare.data) {
                    const request: CitationsRequest = { content: resultCompare.data };
                    const result = await citationsApi(request);
                    dataResult = result && result.data ? result.data : null
                }
            }
        }
        return dataResult
    }
    const setHighlightCitation = (citation: any) => {
        if (citation && citation.noteChildContents && citation.noteChildContents.length) {
            forEach(citation.noteChildContents, function (note: any, keyNote: any) {
                const word = citation.noteChildContents[keyNote].word
                let wordHtml = word
                if (note.noteMetadatas && note.noteMetadatas.length) {
                    const noteMetadatas = uniqBy(note.noteMetadatas, 'to')
                    if (isHighlightCitation(noteMetadatas)) {
                        forEach(noteMetadatas, function (metadata: any, keyMetadata: any) {
                            if (metadata.to) {
                                const wordHighlight = word.substring(metadata.from, metadata.to + 1);
                                if (wordHighlight) {
                                    const wordHighlightHtml = `<span class="is-highlight citation-highlight" data-key-note="${keyNote}" data-key-metadata="${keyMetadata}">${wordHighlight}</span>`;
                                    wordHtml = wordHtml.replace(wordHighlight, wordHighlightHtml)
                                }
                            }
                        });
                    }
                }
                citation.noteChildContents[keyNote].wordHtml = wordHtml
            });
        }
        return citation
    };
    const isHighlightCitation = (noteMetadatas: any) => {
        let isHighlight = false
        if (noteMetadatas && noteMetadatas.length) {
            forEach(noteMetadatas, function (metadata: any, key: any) {
                if (metadata.guide) {
                    isHighlight = true
                    return false
                }
            })
        }

        return isHighlight
    }
    const openEditor = async (sfdt: string) => {
        const event = new CustomEvent('OPEN_EDITOR', {
            detail: {
                data: sfdt
            },
        });
        window.dispatchEvent(event);
    }

    const makeCitationById = async (id: string, typeArticle : string) => {
        let dataResult = null
        const request: CitationsByIdRequest = {
            id,
            typeArticle
        };
        const result = await citationsByIdApi(request);
        if (result && result?.sfdt) {
            dataResult = result
        } else if (result && result?.data) {
            dataResult = result.data
        }
        return dataResult
    }

    const onShowCitation = async (citation: string | CitationsRequestHotIssue, index: number, isWorkingProcessSteps?: boolean) => {
        citationsStore.updateCitationUrl("")
        // console.log('citation', citation);
        // console.log('selectedAnswer', answers[selectedAnswer][1]);


        const content = typeof citation === 'string' ? citation.replace('/content/', '') : citation;
        setLoadingCitation(true)
        let url = ''
        const analysisPanel = () => {
            if (typeof citation === 'string' && activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
                setActiveAnalysisPanelTab(undefined);
            } else {
                if (typeof citation === 'string') {
                    setActiveCitation(citation);
                }
                setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
            }
            sessionChatsStore.SET_CLICK_EXPANDED_FAQ({...sessionChatsStore.isClickExpandedFaq, isExpandedFaq: false})
            setSelectedAnswer(index);
        }

        if (!isEmpty(answers[index][1].working_process_steps) || (answers[index][1].use_fixed_citation && !answers[index][1].list_citation?.length)) {
            const updatedAnswer = [...answers];
            updatedAnswer[index][1].citation = citation;
            setAnswers(updatedAnswer);
            setLoadingCitation(false)
            analysisPanel()
        } else if (sessionChatsStore.isClickExpandedFaq.isExpandedFaq) {
            const updatedAnswer = [...answers];
            updatedAnswer[index][1].citation = citation;
            updatedAnswer[index][1].use_expanded_faq = true;
            setAnswers(updatedAnswer);
            setLoadingCitation(false)
            analysisPanel()
        }
         else {
            const make_citation_as_id = async (id: string, typeArticle : string, index: number) => {
                try {
                    const result = await makeCitationById(id, typeArticle)
                    let citation = result ? result : null
                    setAnswerLawDetails(null)
                    if (citation?.sfdt) {
                        openEditor(citation?.sfdt)
                        return
                    }
                    if (citation && citation.isSearchTitle && citation.url) {
                        setAnswerLawDetails(citation)
                        setOpenInfoLawDetails(true)
                        url = citation.url;
                        return;
                    }
    
                    if (answers && answers[index] && answers[index][1] && citation) {
                        if (citation.noteChildContent && typeof citation.noteChildContent === "string") {
                            citation.noteChildContent = JSON.parse(citation.noteChildContent)
                        }
                    }
                    sessionChatsStore.SET_CLICK_EXPANDED_FAQ({...sessionChatsStore.isClickExpandedFaq, isSearchFaq: false})

                    const updatedAnswer = [...answers];
                    citation = setHighlightCitation(citation)
                    updatedAnswer[index][1].citation = citation;
                    setAnswers(updatedAnswer);
                    setLoadingCitation(false)
                } catch (e) {
                    setLoadingCitation(false)
                } finally { }
                analysisPanel()
            }
            if (answers[index][1]?.multiple_data_points && answers[index][1]?.multiple_data_points?.length && typeof content === 'string') {                
                const multiple_data_points = answers[index][1]?.multiple_data_points
                const findByTitle: multiple_data_points | undefined = find(multiple_data_points, v => {
                    return v.title.trim() === content.trim()
                })
                if (findByTitle && findByTitle.type === "url" && findByTitle.url) {
                    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
                    if (navigator?.maxTouchPoints > 1 && !!userAgent.match(/Version\/[\d\.]+.*Safari/)) {
                        window.location.href = findByTitle.url;
                    } else {
                        openWindowToUrl(findByTitle.url);
                    }
                } else if (findByTitle && findByTitle.type === "faq") {
                    const updatedAnswer = [...answers];

                    updatedAnswer[index][1].citation = {
                        isFaqParsed: true,
                        title: findByTitle.title,
                        content: findByTitle.content,
                        document_citations: findByTitle.document_citations
                    }
                    setAnswers(updatedAnswer);
                    setLoadingCitation(false)
                    analysisPanel()
                } else {
                    if(findByTitle) {
                        make_citation_as_id(findByTitle.id, findByTitle.type, index)         
                    }
                }
            } else {
                if(typeof content !== 'string') {
                    make_citation_as_id(content.id, content.type, index)
                }
            }

        }
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };
    useEffect(() => {
        // getConfiguration()
        if(isShowChangeAccount) {
            getApiEnterPrises()
        }
        makeApiGetPrompt()
        makeApiGetDefaultQuestion()
        if ((window.innerWidth <= 800)) {
            setMobileAnalysisPanel(true);
        }

        let ctrlPress = false;
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 17) {
                ctrlPress = true;
            }
            if (ctrlPress && e.keyCode === 116) {
                sessionStorage.clear()
            }
        });

        document.addEventListener('keyup', (e) => {
            ctrlPress = false;
        });

        return () => { };
    }, [])

    useEffect(() => {
        if (messageRequest) {
            onExampleClicked(messageRequest)
            setMessageRequest("")
        }
    }, [messageRequest])

    useEffect(() => {
        setTypeFullTextSearch(isExperienceUrl ? "Semantic Search" : "Hybrid Search")
    }, [isExperienceUrl])

    useEffect(() => {
        const is_used_history = answers[answers.length - 1]?.[1]?.is_used_history
        // configurationStore.SET_IS_HISTORY(is_used_history)

        let isExperience = false
        const url = window.location.href;
        if (url && url.endsWith('experience/chat')) {
            isExperience = true
        }
        if (isExperience) {
            if (answers && answers.length) {
                if (indexConversationActive > -1) {
                    let conversation: any = sessionStorage.getItem('conversation');
                    if (conversation && typeof conversation === 'string') {
                        conversation = JSON.parse(conversation)
                        if (conversation && conversation[indexConversationActive]) {
                            conversation[indexConversationActive].answers = answers;
                            sessionStorage.setItem('conversation', JSON.stringify(conversation))
                        }
                    }

                    if (answers && answers.length === 1) {
                        eventBus.dispatch('update-conversation-storage', {})
                    }
                }
            }
        } else {}
    }, [answers])
    useEffect(() => {

    }, [sessionId])
    const firstUpdate = useRef(false);
    // event bus
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        eventBus.on("clearChat", (data: any) => {
            lastQuestionRef.current = "";
            clearChat()
        });
        eventBus.on("clearChatByDelete", (data: any) => {
            clearChatByDelete()
        });
        eventBus.on("closeAnalysisPanel", (data: any) => {
            setActiveAnalysisPanelTab(undefined);
        });
        eventBus.on("clearChatByDeleteAll", (data: any) => {
            clearChatByDelete()
        });
        eventBus.on("newChatSession", (data: any) => {
            lastQuestionRef.current = "";
            newChatSession()
        });

        eventBus.on('experience-change-conversation', async (data: any) => {
            if (data) {
                const conversationTmp = await getConversationStorage();
                if (data && data.idOld) {
                    const indexOldTmp = findIndex(conversationTmp, ['id', data.idOld]);
                    if (indexOldTmp > -1) {
                        data.indexOld = indexOldTmp;
                    }
                }

                if (data && data.idNew) {
                    const indexNewTmp = findIndex(conversationTmp, ['id', data.idNew]);
                    if (indexNewTmp > -1) {
                        data.indexNew = indexNewTmp;
                    }
                }

                if (data.indexNew > -1) {
                    setIndexConversationActive(data.indexNew);
                }
                if (conversationTmp) {
                    const conversationNew = conversationTmp[data.indexNew] || null;

                    if (conversationNew) {
                        idConversationActive.current = conversationNew.id;
                    }

                    if (conversationNew && conversationNew.answers && conversationNew.answers.length) {
                        setLastQuestionRefByAnswers(conversationNew.answers);
                        setAnswers(conversationNew.answers);
                    } else {
                        lastQuestionRef.current = '';
                        setAnswers([]);
                    }
                }
            }
        });

        eventBus.on('toggle-disabled-chat', (data: any) => {
            setIsLoading(data.isDisabled)
            // setDisabledChat(data.isDisabled)
        });

        firstUpdate.current = true;
        return () => { };
    }, [answers])

    // hover in law text
    useEffect(() => {
        let lastAnswer = null
        if (answers && answers.length) {
            lastAnswer = answers[answers.length - 1]
        }
        const result = lastAnswer && lastAnswer[1] ? lastAnswer[1] : null
        if (result && result.documents && isArray(result.documents) && result.documents.length > 1) {
            const lawsTextHover = document.getElementsByClassName("law-text-hover");
            if (lawsTextHover) {
                forEach(lawsTextHover, function (el:any, key) {
                    let text = el.childNodes[0] || el.textContent || el.innerText;
                    if (text && typeof text === 'object') {
                        text = text.nodeValue
                    }
                    const law = getLawItem(text, result.documents)

                    el.removeEventListener("mouseover", function () {}, false); // Fails
                    el.removeEventListener("mouseout", function () {}, false); // Fails
                    el.removeEventListener("click", function () {}, false); // Fails
                    el.addEventListener('mouseover', function (e: any) {
                        if (law) {
                            el.classList.add('is-law-underline');
                        } else {
                            checkHadLaw(text, el, lawsTextHover);
                        }
                    });
                    if (law && law.url) {
                        el.addEventListener('click', function (e: any) {
                            window.open(replaceDomain(law.url), '_blank');
                        });
                        el.addEventListener('mouseout', function (e: any) {
                            el.classList.remove('is-law-underline')
                        });
                    }
                })
            }
        }

        const dataCiTationStyle = document.getElementsByClassName("data-citation-styles");
        if (dataCiTationStyle) {
            forEach(dataCiTationStyle, function (el:any, key) {
                let dataType = el?.dataset?.type
                if (dataType && dataType === "content") {
                    el.classList.add('is-citation-type-content')
                } else if (dataType && dataType === "title") {
                    el.classList.add('is-citation-type-title')
                }
                
            })
        }

        const supTextHover = document.getElementsByClassName("supContainerHover");
        if (supTextHover) {
            forEach(supTextHover, function (el:any, key) {
                setElHoverSupcontainerParent(el)
                let text = el?.dataset?.tooltipLabelCitation
                let getdataPoint: any = null
                if(el?.dataset?.textList) {
                    getdataPoint = el?.dataset?.textList.split('., ')
                }
                
                el.removeEventListener("mouseover", function () {}, false); // Fails
                el.removeEventListener("mouseout", function () {}, false); // Fails
                el.removeEventListener("click", function () {}, false); // Fails
                el.addEventListener('mouseover', async function (e: any) {
                    e.preventDefault()
                    e.stopPropagation()
                    setOpenHoverSupcontainer(true)
                    setElHoverSupcontainer(e)
                    sessionChatsStore.changePopupVisible(true)
                    if(text) {
                        let timer1 = setTimeout(async () => {
                            try {
                                const request: CitationsRequest = { content: text };
                                const result = await citationsPopupApi(request);
        
                                
                                // if(result && result.data && result.data.isSearchTitle && result.data.url) return
                                if (result && result.data) {
                                    if(sessionChatsStore.getIsPopupVisible) {
                                        setDataHoverSupcontainer((prev: any) => ({...prev, citation: result.data}))
                                    }
                                } else {
                                    if (getdataPoint) {
                                        const requestCompare: any = { word: text, textList: getdataPoint };
                                        const resultCompare = await citationsCompareApi(requestCompare);
                                        if (resultCompare && resultCompare.data) {
                                            const request: CitationsRequest = { content: resultCompare.data };
                                            const result = await citationsApi(request);
                                            setDataHoverSupcontainer((prev: any) => ({...prev, citation: result.data}))
                                        }else {
                                            setOpenHoverSupcontainer(false)
                                        }
                                    }else {
                                        setOpenHoverSupcontainer(false)
                                    }
                                }
                            } catch (error) {
                                setOpenHoverSupcontainer(false)
                            }
                        }, 100);
                        return () => {
                            clearTimeout(timer1);
                        };
                    }
                });
                el.addEventListener('mouseleave', async function (e: any) {
                    e.preventDefault()
                    e.stopPropagation()
                    sessionChatsStore.changePopupVisible(false)
                    const clearTimeout = setTimeout(() => {
                        if(sessionChatsStore.getIsHoverQuestion) return
                        setOpenHoverSupcontainer(false)
                        setDataHoverSupcontainer((prev: any) => ({...prev, citation: null}))
                    }, 300)
                });
            })
        }
    }, [answers])
    useEffect(() => {
        const doc = document.documentElement;
        const body = doc.getElementsByTagName("body")[0];
        const y = window.innerHeight || doc.clientHeight || body.clientHeight;
        const x = window.innerWidth || doc.clientWidth || body.clientWidth;
        if(popupRefHuy.current && openHoverSupcontainer) {
            if(popupRefHuy.current) {
                const widthAnswerContainer = document.getElementById('idAnswerText')?.offsetWidth
                let widthFloor = 0
                let widthAnswerText = 0
                if(widthAnswerContainer && widthAnswerContainer > 0) {
                    widthFloor = widthAnswerContainer/2
                    widthAnswerText = widthAnswerContainer
                }
                
                if (y - elHoverSupcontainer.clientY < 350) {
                    popupRefHuy.current.style.bottom = y - elHoverSupcontainer.clientY + 0 + "px";
                    popupRefHuy.current.style.maxHeight = elHoverSupcontainer.clientY - 100 + "px";
                } else {
                    popupRefHuy.current.style.top = elHoverSupcontainer.clientY + 13 + "px";
                    popupRefHuy.current.style.maxHeight = y - elHoverSupcontainer.clientY - 50 + "px";
                }

                if(x > 1023) {
                    if (elHoverSupcontainer.clientX < widthAnswerText) {
                        popupRefHuy.current.style.left = elHoverSupcontainer.clientX + 0 + "px";
                    } else {
                        popupRefHuy.current.style.right = x - elHoverSupcontainer.clientX - widthFloor + "px";
                    }
                }else {
                    popupRefHuy.current.style.left = elHoverSupcontainer.clientX - 50 + "px";
                    // popupRefHuy.current.style.minHeight = 300 + "px";
                }

                popupRefHuy.current.style.width = widthAnswerContainer + "px";
            }
            setTimeout(() => {
                const getTolltipClass = document.getElementsByClassName('court-tooltip-scroll')
                if (getTolltipClass) {
                    forEach(getTolltipClass, function (el:any, key) {
                        
                        el.removeEventListener("mouseover", function () {}, false); // Fails
                        el.removeEventListener("mouseout", function () {}, false); // Fails
                        el.removeEventListener("click", function () {}, false); // Fails
                        let isHover = false
                        el.addEventListener('mouseover', async function (e: any) {
                            sessionChatsStore.changeIsHoverPopup(true)
                        });
                        el.addEventListener('mouseleave', async function (e: any) {
                            e.preventDefault()
                            e.stopPropagation()
                            sessionChatsStore.changeIsHoverPopup(false)
                            sessionChatsStore.changePopupVisible(false)
                            setOpenHoverSupcontainer(false)
                            setDataHoverSupcontainer((prev: any) => ({...prev, citation: null}))
                        });
                    })
                }
            }, 500);
            const getChatMessagesScroll = document.getElementById('chatMessageStreamId')
                if(getChatMessagesScroll) {
                    getChatMessagesScroll.addEventListener("scroll", function () {   
                        setOpenHoverSupcontainer(false)
                        setDataHoverSupcontainer((prev: any) => ({...prev, citation: null}))
                    }, false);
                }
        }
        
    }, [popupRefHuy.current, openHoverSupcontainer])

    useEffect(() => {
        if (promptSelected) {
            setPrefixPrompt(promptSelected["prefix_prompt"] || "");
            setFollowUpPrompt(promptSelected["follow_up_prompt"] || "");
            setQueryPrompt(promptSelected["query_prompt"] || "");
            setGraphPrompt(promptSelected["graph_prompt"] || "");

            setIdPrompt(promptSelected.id)
        } else {
            setPrefixPrompt("");
            setFollowUpPrompt("");
            setQueryPrompt("");
            setGraphPrompt("")
            setIdPrompt("")
        }

        //disable switch button default prompt
        setDisabledSwitchButton(promptSelected && promptSelected['is_apply'] ? true : !promptSelected ? true : false)

    }, [promptSelected])

    useEffect(() => {
        const assistant = nameAssistant()
        let enterPriseLocal = localStorage.getItem("_enterpriseIdLocal");
        if(assistant && isEmpty(enterPriseLocal)) {
            const userIdLocal = JSON.parse(localStorage.getItem("_user") || "{}");
            //check tài khoản bộ thông tin
            const userBtt = userIdLocal?.enterpriseId === '66399eddea8428088126ed54'
            if(userBtt) return
            setEnterPriseLocal(userIdLocal?.enterpriseId);
            localStorage.setItem("unit_name", `${assistant}`);
            sessionChatsStore.SET_UNIT_NAME(assistant);
        }
    }, [nameAssistant()])
    
    useEffect(() => {
        if(params.id) {
            makeApiGetChatById(params.id || '')
        }
    }, [params])
    useEffect(() => {
        setActiveAnalysisPanelTab(undefined);
    }, [idChatBySessionName])
    useEffect(() => {
        if (searchSource === 'VTCC') {
            setRetrievalMode("text");
            setUseSemanticRanker(false)
            setUseSemanticCaptions(false)
        } else setUseSemanticRanker(false)
    }, [searchSource])
    useEffect(() => {
        if (activeAnalysisPanelTab && !isMobileAnalysisPanel) {
            eventBus.dispatch('closeMenuChat', {})
        }
    }, [activeAnalysisPanelTab])

    useEffect(() => scrollIntoView(), [isLoading, lastQuestionRef?.current]);

    useEffect(() => {
        setVersionSearch(configuration["chatgpt_model"])
        setLlmGenQuery(configuration["chatgpt_model_gen_query"])
        setTypeFullTextSearch(configuration["fulltext_search_retriever"] ? "Hybrid Search" : "Semantic Search")
        setNoQueryGeneration(configuration["no_gen_query"])
        setNoPrompt(configuration["no_prompt"])
        setNoSearchEngine(configuration["no_search_engine"])
        setIsRerankRetrieve(configuration["rerank_retrieve"])
        setIsLlmFilter(configuration["use_llm_filter"])
        setIsConfirmDecision(configuration["confirm_decision"])
        setIsSuggestionByLlm(configuration["suggestion_by_llm"])
        setRetrievalMode(configuration["retrieval_mode"]);
        setUseSemanticCaptions(configuration["semantic_captions"])
        setUseSemanticRanker(configuration["semantic_ranker"])
        setUseSuggestFollowupQuestions(configuration["suggest_followup_questions"])
        setTemperature(configuration.temperature)
        setRetrieveCount(configuration.top)
        setRetrieveRuleCount(configuration["rule_search"])
        setRetrieveLevelCount(configuration["level_search"])
        setUseWhitelist(configuration["use_whitelist"])
        setUseKg(configuration["use_knowledge_graph"])
        // setGraphOption(configuration["knowledge_graph_option"])
        setHaveRelationship(configuration["graph_have_relationship"])
    }, [configuration])

    return (
        <>
            <div style={{ display: "flex", flex: "1 1 auto", height: "100%", overflowY: "auto" }}>
                <div className={styles.container}>
                    <div className={styles.boxHeaderCommand} style={{justifyContent: 'space-between'}}>
                        <div>
                            { !isEmpty(enterPrises) && isShowChangeAccount && <div className={styles.headerOptions} onClick={() => setShowAccount(true)}>
                                <div className={styles.switchAccountContainer}>
                                    <div className={styles.switchAccountButton}>
                                        <span title={unitNameAssistant() ? unitNameAssistant() : 'Chưa chọn đơn vị'} className={styles.switchAccount}>{unitNameAssistant() ? unitNameAssistant() : 'Chưa chọn đơn vị'}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <g clipPath="url(#clip0_2520_11776)"> <path opacity="0.3" d="M8.50012 8.62V15.38L5.12012 12L8.50012 8.62Z" fill="black"/> <path d="M8.5 8.62V15.38L5.12 12L8.5 8.62ZM10 5L3 12L10 19V5ZM14 5V19L21 12L14 5Z" fill="#575758"/> </g> <defs> <clipPath id="clip0_2520_11776"> <rect width="24" height="24" fill="white"/> </clipPath> </defs> </svg>
                                    </div>
                                </div>
                            </div> }
                        </div>
                        <div className={styles.commandsContainer}>
                            {/* <SwitchContext />                     */}
                            {
                                activeAnalysisPanelTab && !isMobileAnalysisPanel &&
                                <CloseDebug
                                    className={styles.commandButton}
                                    onClick={() => setActiveAnalysisPanelTab(undefined)}
                                    activeAnalysisPanelTab={activeAnalysisPanelTab}
                                />
                            }
                            {
                                lastQuestionRef.current &&
                                <>
                                    <ClearChatButton className={styles.commandButton}
                                        disabledRate = {!isEmpty(sessionChatsStore.getMessageRating)}
                                        onClick={() => { (!lastQuestionRef.current || isLoading) ? null : setOpenConfirmClearChat(true) }}
                                        disabled={!lastQuestionRef.current || isLoading || !isEmpty(sessionChatsStore.getMessageRating)}
                                    />
                                    <ConfirmClearChatPopup
                                        clearChat={clearChat}
                                        setOpenConfirmClearChat={setOpenConfirmClearChat}
                                        openConfirmClearChat={openConfirmClearChat}
                                    />
                                </>
                            }
                            {
                                isShowConfiguration() ? (
                                    <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                                ) : null
                            }
                        </div>
                    </div>
                    <div className={styles.chatRoot}>
                        <div className={styles.chatContainer}>
                            {!lastQuestionRef.current ? (
                                <div id="chatMessageStreamId" className={`${styles.chatMessageStream} ${isMobile ? styles.chatMessageStreamMobile : ''}`}>
                                    <div>
                                        <AnswerInitChat
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            getDefaultQuestion={defaultQuestion}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.chatMessageStreamContainer} >
                                    <div id="chatMessageStreamId" className={`chat-message-stream ${styles.chatMessageStream} ${isMobile ? styles.chatMessageStreamMobile : ''}`}>
                                        {answers.map((answer, index) => {
                                            return (
                                                <div key={index}>
                                                    <UserChatMessage message={answer[0]} />
                                                    <div className={styles.chatMessageGpt}>
                                                        {/* <div className=""> */}
                                                            <Answer
                                                                key={index}
                                                                answer={answer[1]}
                                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                onCitationClicked={c => onShowCitation(c, index)}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                // showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                showFollowupQuestions={useSuggestFollowupQuestions}
                                                                isPlayVoive={isPlayVoive}
                                                                onSend={question => makeApiRequest(question)}
                                                                disable_switch_history={!(index === answers.length - 1)}
                                                                is_used_history={answer[1].is_used_history}
                                                            />
                                                            {/* <RelatedQuestion getDefaultQuestion={defaultQuestion}/> */}
                                                        {/* </div>                                         */}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {isLoading && (
                                            <>
                                                <UserChatMessage message={lastQuestionRef.current} />
                                                <div className={showTextGen ? styles.chatMessageGpt : styles.chatMessageGptMinWidth} >
                                                    <AnswerLoading
                                                        onChangeHeight={scrollIntoView}
                                                        onErrorSocket={onErrorSocket}
                                                        requestSocket={requestSocket}
                                                        receivedSocketData={hasResponseSocket}
                                                        isShowTextGen={showTextGen} />
                                                </div>
                                            </>
                                        )}
                                        {error
                                            && errorSectionRef.current
                                            && errorSectionRef.current.length
                                            && errorSectionRef.current.length > 0
                                            && errorSectionRef.current === lastQuestionRef.current ? (
                                            <>
                                                <UserChatMessage message={errorSectionRef.current} />
                                                <div className={styles.chatMessageGptMinWidth}>
                                                    <AnswerError error={error.toString()} onRetry={() => { makeApiRequest(errorSectionRef.current, "", true) }} />
                                                </div>
                                            </>
                                        ) : null}
                                        <div ref={chatMessageStreamEnd} />
                                    </div>
                                </div>
                            )}


                            <div className={`${styles.chatInput} ${isMobile ? styles.chatMessageStreamMobile : ''} question-input`}>
                                {isLoading && <div style={{justifyContent: 'center', alignItems: 'center', display: "flex", paddingBottom: 12, marginTop: 12}}>
                                    <ButtonCancelChat onClick={() => {
                                        setLastQuestionRefByAnswers(answers)
                                        onResetChatSocket()
                                    }}/>
                                </div>}
                                <QuestionInput
                                    clearOnSend
                                    placeholder="Type a new question (e.g. does my plan cover annual eye exams?)"
                                    disabled={isLoading}
                                    onSend={question => makeApiRequest(question)}
                                />
                            </div>
                        </div>

                        {answers.length > 0 && activeAnalysisPanelTab && !isMobileAnalysisPanel && (
                            <AnalysisPanel
                                className={styles.chatAnalysisPanel}
                                activeCitation={activeCitation}
                                onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                citationHeight="810px"
                                answer={answers[selectedAnswer][1]}
                                activeTab={activeAnalysisPanelTab}
                                loadingCitation={loadingCitation}
                            />
                        )}

                        {answers.length > 0 && activeAnalysisPanelTab && isMobileAnalysisPanel && (
                            
                            <Panel
                                isOpen={activeAnalysisPanelTab ? true : false}
                                isBlocking={false}
                                onDismiss={() => setActiveAnalysisPanelTab(undefined)}
                                closeButtonAriaLabel="Close"
                                isFooterAtBottom={true}
                                className="panelCitation"
                            >
                                <AnalysisPanel
                                    className={styles.chatAnalysisPanel}
                                    activeCitation={activeCitation}
                                    onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                    citationHeight="810px"
                                    answer={answers[selectedAnswer][1]}
                                    activeTab={activeAnalysisPanelTab}
                                    loadingCitation={loadingCitation}
                                />
                            </Panel>
                        )}

                        <Panel
                            headerText="Configuration"
                            isOpen={isConfigPanelOpen}
                            isBlocking={false}
                            onDismiss={() => setIsConfigPanelOpen(false)}
                            closeButtonAriaLabel="Close"
                            onRenderFooterContent={!openPrompt ? onRenderFooterContent : onNoRenderFooterContent}
                            isFooterAtBottom={true}
                        >
                            {!openPrompt && !openQuestionInit && !openWhitelist && (
                                <div className="bbbbb">
                                    <Checkbox
                                        styles={() => checkBoxStyles(autoClearChat ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={autoClearChat}
                                        label="No chat history"
                                        onChange={onAutoClearChatChange}
                                    />
                                    <SpinButton
                                        style={{ marginBottom: '5px' }}
                                        disabled={vtccFlow ? true : autoClearChat ? true : false}
                                        className={styles.chatSettingsSeparator}
                                        label="Number of chat history attached:"
                                        min={0}
                                        max={50}
                                        defaultValue={attachHistoryCount.toString()}
                                        value={attachHistoryCount.toString()}
                                        onChange={onAttachHistoryCountChange}
                                    />
                                    <Checkbox
                                        disabled={vtccFlow ? true : false}
                                        styles={() => checkBoxStyles(showTextGen ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={showTextGen}
                                        label="Show text generation"
                                        onChange={(_ev, checked) => setShowTextGen(!!checked)}
                                    />
                                    <Checkbox
                                        styles={() => checkBoxStyles(noQueryGeneration ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={noQueryGeneration}
                                        label="No query generation"
                                        onChange={(_ev, checked) => { setNoQueryGeneration(!!checked), setLlmGenQuery('llm-vtcc-foundation-70b') }}
                                    />
                                    <Checkbox
                                        disabled={largeLanguageModel === 'llm-tiger-7b' ? true : false}
                                        styles={() => checkBoxStyles(noPrompt ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={noPrompt}
                                        label="No Prompt"
                                        onChange={(_ev, checked) => setNoPrompt(!!checked)}
                                    />
                                    <Checkbox
                                        styles={() => checkBoxStyles(noSearchEngine ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={noSearchEngine}
                                        label="No search engine"
                                        onChange={(_ev, checked) => { setNoSearchEngine(!!checked), setSearchSource("azure"), setRetrievalMode("text") }}
                                    />
                                    <Checkbox
                                        styles={() => checkBoxStyles(isRerankRetrieve ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={isRerankRetrieve}
                                        label="Rerank Retrieve"
                                        onChange={(_ev, checked) => { 
                                            if(!!checked) setIsLlmFilter(false)

                                            setIsRerankRetrieve(!!checked)
                                         }}
                                    />

                                    <Checkbox
                                        styles={() => checkBoxStyles(isLlmFilter ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={isLlmFilter}
                                        label="Rerank by LLM"
                                        onChange={(_ev, checked) => { 
                                            if(!!checked) setIsRerankRetrieve(false);

                                            setIsLlmFilter(!!checked) 
                                        }}
                                    />
                                    
                                    <Checkbox
                                        styles={() => checkBoxStyles(isPlayVoive.config ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={isPlayVoive.config}
                                        label="Play voice"
                                        onChange={(_ev, checked) => { setIsPlayVoive((prev: any) => ({...prev, config: !!checked})) }}
                                    />

                                    <Checkbox
                                         styles={() => checkBoxStyles(useKg ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={useKg}
                                        label="Use knowledge graph"
                                        onChange={(_ev, checked) => { setUseKg(!!checked), setHaveRelationship(false) }}
                                    />
                                    <Checkbox
                                         styles={() => checkBoxStyles(useConfirmAnswer ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={useConfirmAnswer}
                                        label="Use confirm answer"
                                        onChange={(_ev, checked) => { setUseConfirmAnswer(!!checked)}}
                                    />

                                    <Checkbox
                                         styles={() => checkBoxStyles(isSuggestionByLlm ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={isSuggestionByLlm}
                                        label="Confirm multi intent"
                                        onChange={(_ev, checked) => { setIsSuggestionByLlm(!!checked)}}
                                    />

                                    <Checkbox
                                         styles={() => checkBoxStyles(isConfirmDecision ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={isConfirmDecision}
                                        label="Confirm decision"
                                        onChange={(_ev, checked) => { setIsConfirmDecision(!!checked)}}
                                    />
                                    
                                    {useKg ? (
                                        <Checkbox
                                           styles={() => checkBoxStyles(haveRelationship ? 'checked' : '')}
                                           className={styles.chatSettingsSeparator}
                                           checked={haveRelationship}
                                           label="Have relationship"
                                           onChange={(_ev, checked) => { setHaveRelationship(!!checked) }}
                                       />
                                        ) : null}
                                    {/* <Checkbox
                                         styles={() => checkBoxStyles(haveRelationship ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={haveRelationship}
                                        label="Have relationship"
                                        onChange={(_ev, checked) => { setHaveRelationship(!!checked) }}
                                    /> */}
                                    <div style={{ position: "relative" }}>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Retrieve from rule search:
                                        </Label>
                                        <SpinButton
                                            min={1}
                                            max={50}
                                            defaultValue={retrieveRuleCount.toString()}
                                            onChange={onRetrieveRuleCountChange}
                                        />
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Retrieve from level search:
                                        </Label>
                                        <SpinButton
                                            min={1}
                                            max={50}
                                            defaultValue={retrieveLevelCount.toString()}
                                            onChange={onRetrieveLevelCountChange}
                                        />
                                    </div>
                                    <div>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Search Engine
                                        </Label>
                                        <div className={`${styles.chatSettingsSource} mb-3`} style={{ marginTop: '5px' }}>
                                            <Checkbox
                                                styles={() => checkBoxStyles(typeFullTextSearch === 'Semantic Search' ? 'checked' : '')}
                                                checked={typeFullTextSearch === 'Semantic Search'}
                                                label="Semantic Search"
                                                onChange={() => setTypeFullTextSearch("Semantic Search")}
                                            />
                                            <Checkbox
                                                styles={() => checkBoxStyles(typeFullTextSearch === 'Hybrid Search' ? 'checked' : '')}
                                                checked={typeFullTextSearch === 'Hybrid Search'}
                                                label="Hybrid Search"
                                                onChange={() => setTypeFullTextSearch("Hybrid Search")}
                                            />
                                        </div>
                                        {typeFullTextSearch === 'Hybrid Search' && (
                                            <div className={styles.chatSettingsSource} style={{ marginTop: '5px' }}>
                                                <Checkbox
                                                    styles={() => checkBoxStyles(subtype === 'Priority semantic' ? 'checked' : '')}
                                                    checked={subtype === 'Priority semantic'}
                                                    label="Priority semantic"
                                                    onChange={() => setSubtype('Priority semantic')}
                                                />
                                                <Checkbox
                                                    styles={() => checkBoxStyles(subtype === 'Priority hybrid' ? 'checked' : '')}
                                                    checked={subtype === 'Priority hybrid'}
                                                    label="Priority hybrid"
                                                    onChange={() => setSubtype('Priority hybrid')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Use whitelist
                                        </Label>

                                        <div className="mb-3">
                                            <Checkbox
                                                styles={() => checkBoxStyles(useWhitelist ? 'checked' : '')}
                                                checked={useWhitelist}
                                                label="Use whitelist"
                                                onChange={(_ev, checked) => {setUseWhitelist(!!checked), setGoogleSeo(false)}}
                                            />
                                        </div>

                                        {useWhitelist && (
                                            <div className={styles.chatSettingsSource} style={{ marginTop: '5px' }}>
                                                <Checkbox
                                                    styles={() => checkBoxStyles(!googleSeo ? 'checked' : '')}
                                                    checked={!googleSeo}
                                                    label="Use whitelist with initial search keywords"
                                                    onChange={() => setGoogleSeo(false)}
                                                />
                                                <Checkbox
                                                    styles={() => checkBoxStyles(googleSeo ? 'checked' : '')}
                                                    checked={googleSeo}
                                                    label="Use whitelist for search results"
                                                    onChange={() => setGoogleSeo(true)}
                                                />
                                            </div>
                                        )}

                                    </div>

                                    <SpinButton
                                        disabled={vtccFlow ? true : false}
                                        className={styles.chatSettingsSeparator}
                                        label="Retrieve this many documents from search:"
                                        min={1}
                                        max={50}
                                        defaultValue={retrieveCount.toString()}
                                        onChange={onRetrieveCountChange}
                                    />
                                    {
                                        !noQueryGeneration &&
                                        <div>
                                            <Label className={styles.chatSettingsSeparator}>
                                                LLM gen query
                                            </Label>
                                            <div>
                                                <Dropdown
                                                    // disabled={vtccFlow ? true : false}
                                                    // label={''}
                                                    onChange={onSelectedLlmGenQuery}
                                                    defaultSelectedKey="gpt-35-turbo"
                                                    selectedKey={llmGenQuery}
                                                    options={optionsLargeLanguage}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Large Language Model
                                        </Label>
                                        <div>
                                            <Dropdown
                                                disabled={vtccFlow ? true : false}
                                                // label={''}
                                                onChange={onSelectedLargeLanguageModel}
                                                defaultSelectedKey="llm-vtcc-foundation-70b"
                                                selectedKey={largeLanguageModel}
                                                options={optionsLargeLanguageModel}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Temperature gen query
                                        </Label>
                                        <SpinButton
                                            disabled={vtccFlow ? true : false}
                                            min={0}
                                            max={1}
                                            step={.1}
                                            defaultValue={temperatureGenQuery.toString()}
                                            onChange={onTemperatureGenQueryChange}
                                        />
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <Label className={styles.chatSettingsSeparator}>
                                            Temperature
                                            <img id="Temperature-title" className={styles.headerNavPageLinkIcon} src={seeMore} alt="" style={{ "width": "16px", "height": "16px", cursor: "pointer", marginLeft: "6px" }} />
                                            <ReactTooltip
                                                className={styles.customTooltip}
                                                anchorId="Temperature-title"
                                                place="top"
                                                content="The LLM temperature is a hyperparameter that regulates the randomness, or creativity, of the AI’s responses. A higher temperature value typically makes the output more diverse and creative but might also increase its likelihood of straying from the context. Conversely, a lower temperature value makes the AI’s responses more focused and deterministic, sticking closely to the most likely prediction"
                                            />
                                        </Label>
                                        <SpinButton
                                            // className={styles.chatSettingsSeparator}
                                            // label="Temperature:"
                                            disabled={vtccFlow ? true : false}
                                            min={0}
                                            max={1}
                                            step={.1}
                                            defaultValue={temperature.toString()}
                                            onChange={onTemperatureChange}
                                        />
                                    </div>

                                    <TextField
                                        disabled={vtccFlow ? true : false}
                                        className={styles.chatSettingsSeparator}
                                        label="Exclude category"
                                        onChange={onExcludeCategoryChanged}
                                    />
                                    <Checkbox
                                        disabled={vtccFlow ? true : false}
                                        styles={() => checkBoxStyles(useSuggestFollowupQuestions ? 'checked' : '')}
                                        className={styles.chatSettingsSeparator}
                                        checked={useSuggestFollowupQuestions}
                                        label="Suggest follow-up questions"
                                        onChange={onUseSuggestFollowupQuestionsChange}
                                    />
                                </div>
                            )}
                            {openQuestionInit && (
                                <>
                                    <Button className={styles.chatSettingsSeparator} onClick={() => { setOpenQuestionInit(false), makeApiGetPrompt() }}>
                                        <img className={styles.headerNavPageLinkIcon} src={backPng} alt="" style={{ "width": "16px", "height": "16px" }} />
                                    </Button>
                                    <QuestionInit 
                                        getDefaultQuestion={defaultQuestion} 
                                        notify = {() => notify("Cập nhật thành công")}
                                        reload = {makeApiGetDefaultQuestion}
                                    />
                                    <ToastContainer />
                                </>
                            )}

                            {openWhitelist && (
                                <>
                                    <Button className={styles.chatSettingsSeparator} onClick={() => { setOpenWhitelist(false), makeApiGetPrompt() }}>
                                        <img src={backPng} alt="" style={{ "width": "16px", "height": "16px" }} />
                                    </Button>
                                    <WhitelistInit
                                        notify = {() => notify("Cập nhật thành công")}
                                        reload = {makeApiGetDefaultQuestion}
                                    />
                                    <ToastContainer />
                                </>
                            )}

                            {
                                openPrompt && (
                                    <div className="">
                                        <div className={styles.chatSettingsSource}>
                                            <Button className={styles.chatSettingsSeparator} onClick={() => { setOpenPrompt(false), makeApiGetPrompt() }}>
                                                <img className={styles.headerNavPageLinkIcon} src={backPng} alt="" style={{ "width": "16px", "height": "16px" }} />
                                            </Button>
                                            <PrimaryButton className={styles.chatSettingsSeparator} text="Thêm mới" onClick={() => { setShowCreatePrompt(true), setIsConfigPanelOpen(false) }} />
                                        </div>
                                        <div>
                                            <Label className={styles.chatSettingsSeparator}>
                                                Prompt
                                            </Label>

                                            <DropDownPrompt
                                                optionsPrompt={promptArr}
                                                setPromptSelected={setPromptSelected}
                                                promptSelected={promptSelected}
                                            />

                                        </div>
                                        <FormCheck className={styles.chatSettingsSeparator}// prettier-ignore
                                            disabled={disabledSwitchButton ? true : false}
                                            type="switch"
                                            id="custom-switch"
                                            label="Xét làm prompt mặc định"
                                            onChange={onSetDefaultPrompt}
                                            checked={promptSelected && promptSelected['is_apply'] ? true : false}
                                        />
                                        {
                                            showConfirmDefaultPrompt ? (
                                                <Password
                                                    opentPopupConfirm={showConfirmDefaultPrompt}
                                                    setOpenPopupConfirm={setShowConfirmDefaultPrompt}
                                                    makeApiDefaultPrompt={p => makeApiDefaultPrompt(p)}
                                                    passwordTextError={passwordTextError}
                                                    setPasswordTextError={setPasswordTextError}
                                                />
                                            ) : null
                                        }
                                        <TextField
                                            className={styles.chatSettingsSeparator}
                                            defaultValue={prefixPrompt}
                                            value={prefixPrompt}
                                            label="Prompt tạo phản hồi"
                                            multiline
                                            autoAdjustHeight
                                            onChange={onPromptPrefixChange}
                                        />
                                        <TextField
                                            className={styles.chatSettingsSeparator}
                                            defaultValue={followUpPrompt}
                                            value={followUpPrompt}
                                            label="Prompt tạo câu gợi ý tiếp theo"
                                            multiline
                                            autoAdjustHeight
                                            onChange={onPromptFollowUpChange}
                                        />
                                        <TextField
                                            className={styles.chatSettingsSeparator}
                                            defaultValue={queryPrompt}
                                            value={queryPrompt}
                                            label="Prompt tạo câu truy vấn"
                                            multiline
                                            autoAdjustHeight
                                            onChange={onPromptQueryChange}
                                        />
                                        <TextField
                                            className={styles.chatSettingsSeparator}
                                            defaultValue={graphPrompt}
                                            value={graphPrompt}
                                            label="Prompt đồ thị tri thức"
                                            multiline
                                            autoAdjustHeight
                                            onChange={onPromptGraphChange}
                                        />
                                        <div className={`${styles.chatSettingsSeparator} ${styles.flexEnd}`}>
                                            <PrimaryButton
                                                disabled={!promptSelected ? true : !isPromptChange ? true : false}
                                                className={`${styles.chatSettingsSeparator} ${styles.mr15}`}
                                                onClick={() => {
                                                    setSaveChangePrompt(true), setIsPromptChange(false),
                                                        notify("Prompt đã đưọc lưu cho phiên sử dụng này")
                                                }}
                                            >
                                                Save
                                            </PrimaryButton>
                                            <PrimaryButton
                                                disabled={promptSelected && !promptSelected['is_apply'] ? true
                                                    : !promptSelected ? true
                                                        : !isPromptChangeToApply ? true
                                                            : false}
                                                className={styles.chatSettingsSeparator}
                                                onClick={() => setOpenPopupConfirm(true)}
                                            >
                                                Apply
                                            </PrimaryButton>
                                        </div>
                                        <Dialog isOpen={opentPopupConfirm} onDismiss={() => { setErrorEditPrompt(""), setPasswordConfirm(""), setOpenPopupConfirm(false) }}
                                            styles={{
                                                main: {
                                                    selectors: {
                                                        ['@media (min-width: 480px)']: {
                                                            width: 500,
                                                            minWidth: 300,
                                                            maxWidth: '1000px',
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <div className="5555">
                                                <p style={{ fontSize: "20px", fontWeight: "600", marginTop: "0px" }}>Chỉnh sửa Prompt</p>
                                                <TextField
                                                    className={styles.chatSettingsSeparator}
                                                    defaultValue={passwordConfirm}
                                                    label="Mật khẩu"
                                                    type='password'
                                                    autoComplete="new-password"
                                                    required={true}
                                                    errorMessage={errorEditPrompt}
                                                    autoAdjustHeight
                                                    onChange={onPasswordConfirmChange}
                                                />
                                            </div>
                                            <DialogFooter>
                                                <PrimaryButton disabled={!passwordConfirm ? true : false} onClick={() => onEditPrompt()} text="Cập nhật" />
                                                <Button onClick={() => { setErrorEditPrompt(""), setPasswordConfirm(""), setOpenPopupConfirm(false) }} text="Đóng" />
                                            </DialogFooter>
                                        </Dialog>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="light"
                                        />
                                    </div>
                                )
                            }
                        </Panel>
                        {showCreatePrompt ? (
                            <CreatePrompt
                                showCreatePrompt={showCreatePrompt}
                                setShowCreatePrompt={setShowCreatePrompt}
                                promptArr={promptArr}
                                makeApiGetPrompt={makeApiGetPrompt}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {openInfoLawDetails ? (
                <ControlledTabs
                    answer={answerLawDetails}
                    openDialog={openInfoLawDetails}
                    onCloseModal={() => setOpenInfoLawDetails(false)}
                />
            ) : null}
            <div className="renderStaticCitationPanel"  >
                {openHoverSupcontainer ? (
                    <div ref={popupRefHuy} className={`court-tooltip-citation_popup ${dataHoverSupcontainer?.citation ? 'court-tooltip-scroll': ''}`}>
                        {
                            dataHoverSupcontainer && dataHoverSupcontainer.citation ? 
                            <div className="">
                                {dataHoverSupcontainer.citation && dataHoverSupcontainer.citation.isSearchTitle && dataHoverSupcontainer.citation.url ?
                                    <PopupLawInfo answer={dataHoverSupcontainer.citation}/> :
                                    <PopupCitationPanel answer={dataHoverSupcontainer}/> 
                                }
                            </div>
                            : 
                            <div className={styles.loadingTree}>
                                <img src={loadingTree} />
                            </div>
                        }
                    </div>
                ) : null}
            </div>

            {showAccount &&  <AccountList show={showAccount} setShow={setShowAccount} data={enterPrises}/>}
        </>
    );
});

export default Chat;
