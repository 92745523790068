import { action, computed, makeObservable, observable } from "mobx";
import { IRootStore } from "./RootStore";
import { EnterPrise } from "../api";

export class ConfigurationStore {
    searchEngine: string = "";
    dataEnterprise: EnterPrise[] = [];
    is_history: boolean = false;
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            searchEngine: observable,
            is_history: observable,
            SET_SEARCH_ENGINE: action,
            search_engine: computed,
            SET_IS_HISTORY: action,
            dataEnterprise: observable,
            SET_DATA_ENTERPRISE: action,
            data_enterprise: computed,
            isHistory: computed
        });
        this.rootStore = rootStore;
    }

    SET_SEARCH_ENGINE = (search: string) => {
        this.searchEngine = search;
    };

    get search_engine() {
        return this.searchEngine;
    }

    SET_IS_HISTORY = (bol: boolean) => {
        this.is_history = bol
    }

    get isHistory() {
        return this.is_history
    }


    SET_DATA_ENTERPRISE = (enterprises: EnterPrise[]) => {
        this.dataEnterprise = enterprises;
    };

    get data_enterprise() {
        return this.dataEnterprise;
    }
}
