export class Feedback {
    name: string;
    reason: string;
    id: string
  
    constructor(name: string, reason: string, id: string) {
      this.name = name;
      this.reason = reason;
      this.id = id;
    }
  }
  
  export const getDislikeList = () => {
    let profiles = Array<Feedback>();
    profiles.push(new Feedback("Thiếu thông tin/ dữ liệu", "Thiếu thông tin/ dữ liệu", "checkDislike1"));
    profiles.push(new Feedback("Chưa chính xác/ Không liên quan", "Chưa chính xác/ Không liên quan", "checkDislike2"));
    profiles.push(new Feedback("Dữ liệu/ dẫn chứng đã cũ", "Dữ liệu/ dẫn chứng đã cũ", "checkDislike3"));
    profiles.push(new Feedback("Trình bày chưa rõ ràng", "Trình bày chưa rõ ràng", "checkDislike4"));

    return profiles;
  };

  export const getRateList = () => {
    let profiles = Array<Feedback>();
    profiles.push(new Feedback("Chưa chính xác/ Không liên quan", "Chưa chính xác/ Không liên quan", "checkDislike1"));
    profiles.push(new Feedback("Dữ liệu/ dẫn chứng bị trùng lặp", "Dữ liệu/ dẫn chứng bị trùng lặp", "checkDislike2"));
    profiles.push(new Feedback("Thiếu thông tin/ dữ liệu", "Thiếu thông tin/ dữ liệu", "checkDislike3"));
    return profiles;
  };


  export const getLikeList = () => {
    let profiles = Array<Feedback>();
    profiles.push(new Feedback("Chính xác", "Chính xác", "checkLike1"));
    profiles.push(new Feedback("Dễ hiểu", "Dễ hiểu", "checkLike2"));
    profiles.push(new Feedback("Hoàn chỉnh", "Hoàn chỉnh", "checkLike3"));
    return profiles;
  };
  