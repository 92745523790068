interface Props {
  color: string
}

export const SessionDraftSvg = ({ color }: Props) => {

  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8 2.5C4 2.5 2 4.5 2 8.5V13.5C2 18.5 4 19.5 8 19.5H8.5C8.77 19.5 9.14 19.68 9.3 19.9L10.8 21.9C11.46 22.78 12.54 22.78 13.2 21.9L14.7 19.9C14.89 19.65 15.19 19.5 15.5 19.5H16C20 19.5 22 17.5 22 13.5" stroke={color} strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16.398 3.15399L10.1769 9.37499C9.9401 9.61183 9.70326 10.0776 9.65589 10.4171L9.31642 12.7934C9.19011 13.6539 9.798 14.2539 10.6585 14.1355L13.0348 13.796C13.3664 13.7486 13.8322 13.5118 14.0769 13.275L20.2979 7.05396C21.3716 5.98028 21.8769 4.73292 20.2979 3.15399C18.719 1.57505 17.4716 2.08031 16.398 3.15399Z" stroke={color} strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /> <path d="M15.5049 4.04688C16.0338 5.9337 17.5101 7.41001 19.4049 7.94685" stroke={color} strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /> </svg>       </>
  );
};
