import { parseSupportingContentItem } from "@components/SupportingContent/SupportingContentParser";
import _, { filter } from "lodash";
import { forEach } from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import { useStore } from "../../hooks/useStore";
import { initTooltipCitation } from "../../utils/tooltip";

type HtmlParsedAnswer = {
    answerText: string;
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
    linkcitations: any[]
};

const UNDEFINED_REGEX = /(không có nguồn|source|history|none|nguồn|không có thông tin|chưa có thông tin|Document|Other types of documents|Law|Decree|Circular|FAQ)/i;

const checkUndefinedCitation = (citation?: string) => {
    if (citation?.length) {
        const checkCitation = UNDEFINED_REGEX.test(citation.toLowerCase());
        return checkCitation
    }
    return false
}

export const parseAnswerToHtml = (answer: string = '', onCitationClicked: (citationFilePath: string) => void, data_points: string[], use_fixed_citation: boolean, is_working_process: boolean, reference_urls: string[], reference_titles: string[], handleShowPopover: () => void, handleHidePopover: () => void, showPopover: boolean): HtmlParsedAnswer => {
    const citations: string[] = [];
    const followupQuestions: string[] = [];

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = ''
    if (answer) {
        parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
            followupQuestions.push(content);
            return "";
        });
    }

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    let answerText = ''

    // check số điều
    const checkLawNumber = (strPart: string) => {
        function getSecondPart(str: string) {
            return str.toLowerCase().split('điều')[1];
        }
        const isLawNumber = getSecondPart(strPart)?.trim()?.split(' ')[0]
        const regexNumber = /\d/.test(isLawNumber)
        return regexNumber
    }

    //check tên điều
    const checkLawName = (strPart: string) => {
        const splits = strPart.split(' ');
        const output = splits.slice(2).join(' ');
        if (output.trim()) {
            return true
        }
        return false
    }

    //compare số điều

    const CompareLawNumber = (strPart: string, strDataPoint: string) => {
        function compare(str: string) {
            const n = 2; // second space
            const a = str.trim().split(' ')
            const output = a.slice(0, n).join(' ').replace(/[:\]\]'",\/;\.]+$/g, "")
            return output
        }
        return compare(strPart) === compare(strDataPoint)
    }

    //Replace điều (ex: Điều 3.6 => điều 3, khoản 6; điều 2, mục 4 => Điều 2, khoản 4)

    const replacePart = (originalText: string) => {
        let updatedText = ''
        if (originalText.match(/[Đđ]iều \d+\.\d+/)) {
            //Điều 3.6 => điều 3, khoản 6
            updatedText = originalText.replace(/[Đđ]iều (\d+)\.(\d+)/, 'Điều $1, khoản $2');
        } else if (originalText.match(/[Đđ]iều \d+, mục \d+/)) {
            //điều 2, mục 4 => Điều 2, khoản 4
            updatedText = originalText.replace(/[Đđ]iều (\d+), mục (\d+)/, 'Điều $1, khoản $2');
        } else {
            updatedText = originalText
        }
        return updatedText
    }

    // rút gọn trích dẫn từ danh sách data_point
    const compactDatapoint = (dataPoint: string) => {
        const result = dataPoint.includes(': ') ? dataPoint.substring(0, dataPoint.indexOf(': ')) : dataPoint;
        return result
    }

    // hàm check trích dẫn Generate answer đưọc trả ra hoàn toàn từ danh sách data_point
    const isPartMatch = (dataPoint: string, part: string) => {
        const subStr = compactDatapoint(dataPoint);
        return subStr && part.trim() === subStr.trim();
      };
    const parsedPart = (part: string) => {

        if (data_points && data_points.length && part?.toLowerCase()?.includes('điều')) {
            const partPerfect: any[] = filter(data_points, (dataPoint) => isPartMatch(dataPoint, part));

            const substringPart = part.includes(',') ? `${part.substring(0, part.indexOf(','))}` : part;
            const n = 2; // second space
            const a = substringPart.trim().split(' ')
            const first = a.slice(0, n).join(' ')
            const newPart = data_points.filter((v) => _.startsWith(v.trim().toLowerCase(), first.toLowerCase()) && CompareLawNumber(first.toLowerCase(), v.trim().toLowerCase()))
            
            if(partPerfect && partPerfect?.length) { // danh sách data_point trả ra hoàn toàn Generate answer
                const result = compactDatapoint(partPerfect[0]);
                return checkLawNumber(result) ? result : ""
            }else if (newPart && newPart.length) { //danh sách data_point trả ra một phần Generate answer
                const result = compactDatapoint(newPart[0]);
                return checkLawNumber(result) ? result : ""
            } else {
                return checkLawNumber(part) && checkLawName(part) ? part : ""
            }
        } else {
            const newPart = data_points.filter((v) => v.toLowerCase().includes(part.toLowerCase()))
            if (newPart && newPart.length) {
                const result = compactDatapoint(newPart[0]);
                return result
            }
        }
        return part
    }

    const parsedCitation = (tempart: string) => {
        if(use_fixed_citation) {
            const parsed = parseSupportingContentItem(tempart);
            let citationIndex: number;
            if (citations.indexOf(tempart) !== -1) {
                if (citations && citations.length < citations.indexOf(tempart) + 2) return
                citationIndex = citations.indexOf(tempart) + 1;
            } else {
                citations.push(tempart);
                citationIndex = citations.length;
            }
            const path = getCitationFilePath(tempart);
            <sup>{citationIndex}</sup>
            return renderToStaticMarkup(
                <a className="supContainer" 
                    id="supContainerId"
                    onClick={() => onCitationClicked(tempart)}
                >
                    <sup       
                        className="supContainerHover"     
                        data-tooltip-citation-container
                        data-tooltip-label-citation={parsed.title} 
                        data-text-list={data_points}
                    >
                        {citationIndex}
                    </sup>    
                </a>
            );
        }else {
            let citationIndex: number;
            if (citations.indexOf(tempart) !== -1) {
                if (citations && citations.length < citations.indexOf(tempart) + 2) return
                citationIndex = citations.indexOf(tempart) + 1;
            } else {
                citations.push(tempart);
                citationIndex = citations.length;
            }
            const path = getCitationFilePath(tempart);
            <sup>{citationIndex}</sup>
    
            return renderToStaticMarkup(
                <a className="supContainer" 
                    id="supContainerId"
                    onClick={() => onCitationClicked(use_fixed_citation ? tempart : path)}
                >
                    <sup       
                        className="supContainerHover"     
                        data-tooltip-citation-container
                        data-tooltip-label-citation={tempart} 
                        data-text-list={data_points}
                    >
                        {citationIndex}
                    </sup>    
                </a>
            );
        }

    }

    function validateNumber(input: any) {
        const regex = /^\d+$/;
        return regex.test(input);
      }
    
    const fragments: (string | undefined)[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            answerText += part
            return part;
        } else if (reference_urls?.length) {
            return ''
        } else if (reference_titles?.length) {
            return ''
        } else if (checkUndefinedCitation(part)) {
            return ''
        } else if (validateNumber(part) && data_points.length) {
            const indexPart = Number(part) - 1;
            if (indexPart < data_points.length) {
                return parsedCitation(use_fixed_citation ? data_points[indexPart] : compactDatapoint(data_points[indexPart]));
            }
        }  else {
            let tempart = ''
            const regexTest = /^Điều\s+(?:\d+(?:,\s+\d+)*)+$/g     // test "Điều 6, 7, 8, 9, 11, 24, 28, 32, 33, 42, 45";
            const regex = /\d+/g;

            const result = regexTest.test(part) ? part.replace(regex, (match) => {
            return `Điều ${match}`;
            }) : part;            
            const count = (result?.toLowerCase()?.match(new RegExp("điều", "g")) || [])?.length
            if (count > 1) {
                let splitPart: string[] = []
                splitPart = result.toLowerCase()?.replaceAll(/\. (đ|Đ)iều|và (đ|Đ)iều|; (đ|Đ)iều|;\. (đ|Đ)iều|(đ|Đ)iều/g, '++Điều').split('++').filter(v => v != '')
                if(splitPart && splitPart.length > 5) {
                    splitPart = splitPart.filter((item, index) => {
                        return index < 5;
                    });
                }
                forEach(splitPart, (v) => {
                    tempart = parsedPart(v) || ""
                    return data_points && data_points.length && tempart ? parsedCitation(tempart) : ''
                })
            } else {
                tempart = parsedPart(part) || ""
                tempart = replacePart(tempart) || ""          
                return data_points && data_points.length && tempart ? parsedCitation(tempart) : ''
            }
        }
    });

    answerText.trim()
    return {
        answerText,
        answerHtml: fragments.join(""),
        citations,
        followupQuestions,
        linkcitations: []
    };
}

export function stripHtml(html: any) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}
