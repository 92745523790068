import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import loadingTree from "../../../assets/loading/loading-generate.svg"
import styles from "../Answer.module.css";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./answer.css";
import { ITrackSearch } from "../../../api";

interface Props {
    trackSearch: ITrackSearch
}
export const TransitionSearchingDone = ({ trackSearch }: Props) => {
    const trigger= trackSearch;
    const nodeRef = useRef<any>(null);
    return (
        <Stack>
            <Stack.Item>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        nodeRef={nodeRef}
                        addEndListener={(done: () => void) => {
                            nodeRef.current?.addEventListener("transitionend", done, false);
                        }}
                        classNames="fade-generate"
                        key={trigger.name}
                    >
                        <div ref={nodeRef}>
                            <div className={`${styles.generateSearching}`}>
                                {trigger.status === 'searching' ?
                                    <div className={styles.loadingTree}>
                                        <img src={loadingTree} />
                                    </div> :
                                    <div className={`${trigger.result !== 'fail' ? styles.checkGenerate : styles.checkGenerateNull}`}>
                                        <input
                                            type="checkbox"
                                            id="id-check-generate"
                                            defaultChecked={true}
                                        >
                                        </input>
                                        <label htmlFor="id-check-generate"></label>
                                    </div>
                                }
                                <p className={styles.textGenerating}>Tìm kiếm với <span>{trigger.name}</span></p>
                            </div>
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </Stack.Item>
        </Stack>

    );
};
