import { filter, forEach } from 'lodash';
import { useEffect, useState } from 'react';
import { Accordion, Button, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getLawDiagram } from '../../api';
import { RiArrowRightSLine } from "react-icons/ri";
import styles from "./ControlledTabs.module.css";
import { initTooltip } from '../../utils/tooltip';
import loadingTree from "../../assets/loading/bars-rotate-fade.svg"
import { replaceDomain } from '../../utils/openWindowUrl';


interface Props {
    answer: any
    openDialog?: boolean;
    onCloseModal: () => void
}
function ControlledTabs({ openDialog, onCloseModal, answer }: Props) {
    const [key, setKey] = useState<string>('thông tin văn bản');
    const [itemDiagrams, setItemDiagrams] = useState<any>(null)
    const [loadingLawtree, setLoadingLawTree] = useState<boolean>(false)
    const closeLawDocumentSupportPopup = () => onCloseModal()
    const getDiagrams = async () => {
        setLoadingLawTree(true)
        try {
            const { data } = await getLawDiagram(answer)
            if (data) {
                let itemDiagramsData = data

                forEach(data.diagrams, function (item, key) {
                    forEach(item.documentList, function (lawRelate, keyRelate) {
                        let html =
                            '<div class="grid sm:grid-cols-2 grid-cols-1 gap-x-4 text-gray-800 text-sm" style="text-align: left">'
                        if (lawRelate) {
                            if (lawRelate.documentCode) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Số hiệu văn bản</span>: ' +
                                    lawRelate.documentCode
                                html += '    </div>'
                            }
                            if (lawRelate.validityStatus) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Tình trạng hiệu lực</span>: ' +
                                    lawRelate.validityStatus
                                html += '    </div>'
                            }
                            if (lawRelate.agencyIssued) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Cơ quan ban hành</span>: ' +
                                    lawRelate.agencyIssued
                                html += '    </div>'
                            }
                            if (lawRelate.applyDate) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Ngày áp dụng</span>: ' +
                                    lawRelate.applyDate
                                html += '    </div>'
                            }
                            if (lawRelate.passedDate) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Ngày thông qua</span>: ' +
                                    lawRelate.passedDate
                                html += '    </div>'
                            }
                            if (lawRelate.documentType) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Loại văn bản</span>: ' +
                                    lawRelate.documentType
                                html += '    </div>'
                            }
                            if (lawRelate.publicationDate) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Ngày công báo</span>: ' +
                                    lawRelate.publicationDate
                                html += '    </div>'
                            }
                            if (lawRelate.signedBy) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Người ký</span>: ' +
                                    lawRelate.signedBy
                                html += '    </div>'
                            }
                            if (lawRelate.dateIssued) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Ngày ban hành</span>: ' +
                                    lawRelate.dateIssued
                                html += '    </div>'
                            }
                            if (lawRelate.field) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Lĩnh vực</span>: ' +
                                    lawRelate.field
                                html += '    </div>'
                            }
                            if (lawRelate.decision) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Quyết định</span>: ' +
                                    lawRelate.decision
                                html += '    </div>'
                            }
                            if (lawRelate.legalRelation) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Quan hệ pháp luật</span>: ' +
                                    lawRelate.legalRelation
                                html += '    </div>'
                            }
                            if (lawRelate.courtType) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Tòa án</span>: ' +
                                    lawRelate.courtType
                                html += '    </div>'
                            }
                            if (lawRelate.level) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Cấp</span>: ' + lawRelate.level
                                html += '    </div>'
                            }
                            if (lawRelate.applyPrecedent) {
                                html += '<div class="mb-2">'
                                html += lawRelate.applyPrecedent
                                html += '    </div>'
                            }
                            if (lawRelate.expirationDate) {
                                html += '<div class="mb-2">'
                                html +=
                                    '<span class="font-medium">Ngày hết hiệu lực</span>: ' +
                                    lawRelate.expirationDate
                                html += '    </div>'
                            }
                        }

                        html += '</div>'
                        itemDiagramsData.diagrams[key].documentList[
                            keyRelate
                        ].tooltip = html

                        setItemDiagrams(itemDiagramsData)
                    })
                })
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoadingLawTree(false)
        }
    }
    const itemDiagramsQuantity = (itemDiagrams: any) => {
        setTimeout(() => {
            initTooltip();
        });
        const result = filter(itemDiagrams, (v: any) => v.quantityDocument)
        return result
    }
    useEffect(() => {
        if (key === 'lưọc đồ văn bản') {
            getDiagrams()
        }
    }, [key])

    return (
        <Modal
            className="modal-document-support modal-law-Details modal-bootstrap"
            show={openDialog}
            size="lg"
            aria-labelledby="contained-modal-title-center"
            // centered
            scrollable
            onHide={closeLawDocumentSupportPopup}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-center">
                    Thông tin văn bản
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowX: 'inherit' }}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="thông tin văn bản" title="Thông tin văn bản">
                        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-x-4 pb-2 text-gray-800 text-sm">
                            {answer.documentCode && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Số hiệu văn bản:</strong> {answer.documentCode}</span>
                                </div>
                            )}
                            {answer.agencyIssued && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Cơ quan ban hành:</strong> {answer.agencyIssued}</span>
                                </div>
                            )}
                            {answer.passedDate && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Ngày thông qua:</strong> {answer.passedDate}</span>
                                </div>
                            )}
                            {answer.documentType && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Loại văn bản:</strong> {answer.documentType}</span>
                                </div>
                            )}
                            {answer.publicationDate && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Ngày công báo:</strong> {answer.publicationDate}</span>
                                </div>
                            )}
                            {answer.signedBy && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Người ký:</strong> {answer.signedBy}</span>
                                </div>
                            )}
                            {answer.dateIssued && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Ngày ban hành:</strong> {answer.dateIssued}</span>
                                </div>
                            )}
                            {answer.field && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Lĩnh vực:</strong> {answer.field}</span>
                                </div>
                            )}
                            {answer.decision && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Quyết định:</strong> {answer.decision}</span>
                                </div>
                            )}
                            {answer.legalRelation && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Quan hệ pháp luật:</strong> {answer.legalRelation}</span>
                                </div>
                            )}
                            {answer.courtType && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Tòa án:</strong> TAND {answer.courtType}</span>
                                </div>
                            )}
                            {answer.level && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Cấp:</strong> {answer.level}</span>
                                </div>
                            )}
                            {answer.expirationDate && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Ngày hết hiệu lực:</strong> {answer.expirationDate}</span>
                                </div>
                            )}
                            {answer.totalArticles && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Tổng số điều:</strong> {answer.totalArticles}</span>
                                </div>
                            )}
                            {answer.judgmentDate && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Ngày công bố:</strong>Ngày công bố: {answer.judgmentDate}</span>
                                </div>
                            )}
                            {answer.applyDate && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Ngày áp dụng:</strong> {answer.applyDate}</span>
                                </div>
                            )}
                            {answer.validityStatus && (
                                <div className="mb-2">
                                    <span className="font-medium block sm:inline"><strong>Tình trạng hiệu lực:</strong> {answer.validityStatus}</span>
                                </div>
                            )}
                            <div className="col-span-2">
                                <a href={replaceDomain(answer.url)} target="_blank" className="my-2 text-indigo-700 underline text-base"
                                >Xem văn bản web</a
                                >
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="tóm tắt" title="Tóm tắt">
                        {answer.tomTat ? (
                            <div className="" style={{ whiteSpace: 'pre-line' }}>
                                {answer.tomTat}
                            </div>
                        ) : <div>Không có dữ liệu...</div>}
                    </Tab>
                    <Tab eventKey="lưọc đồ văn bản" title="Lược đồ văn bản">
                        {loadingLawtree ?
                            <div className={styles.loadingTree}>
                                <img src={loadingTree} />
                            </div> :
                            <div className="">
                                {
                                    itemDiagrams && itemDiagrams.diagrams ?
                                        <Accordion>
                                            {itemDiagrams && itemDiagrams.diagrams && itemDiagramsQuantity(itemDiagrams.diagrams).map((item: any, key: any) =>
                                                <div key={key} className="">
                                                    <Accordion.Item eventKey={key}>
                                                        <Accordion.Header>{`${item?.relatedDocumentType} (${item.quantityDocument})`}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className="relate-items">
                                                                {item && item.documentList && item.documentList.map((document: any, keyD: number) =>
                                                                    <li key={keyD} className="relate-item text-base mb-2">
                                                                        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span style={{ display: 'flex', color: 'var(--color-text-code-law)' }}>
                                                                                {document?.documentType}
                                                                            </span>
                                                                            <RiArrowRightSLine />
                                                                            <span style={{ display: 'flex', color: 'var(--color-text-code-law)' }}>
                                                                                Mã văn bản: {document?.documentCode}
                                                                            </span>
                                                                        </div>
                                                                        <span className='text-indigo-700' data-tooltip-container data-tooltip-label={document.tooltip}>{document?.documentName}</span>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div>

                                            )}
                                        </Accordion> :
                                        <div>Không có dữ liệu...</div>
                                }
                            </div>
                        }
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeLawDocumentSupportPopup}>Đóng</Button>
            </Modal.Footer>
        </Modal >

    );
}

export default ControlledTabs;