import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
// import logoInit from "../../assets/uiv2/logo_chat_init_v2.svg";
import logoInit from "../../assets/logo.png"

import warning from "../../assets/uiv2/warning_icon_light_mode_2.svg";
import chatInstruct from "../../assets/uiv2/ic_chat_instruct.svg";

import DownIcon from "../../assets/down-arrow.png"
import { getConversationStorage } from "../../utils/conversationExperience";
import eventBus from "../../plugins/EventBus";
import { findIndex } from "lodash";
import { Col, Row, Modal, Card } from "react-bootstrap";
import {IconContext} from "react-icons";
import {LuCopyCheck} from "react-icons/lu";
import { nameAssistant, textWelcome, userNameLocal } from "@utils/localStorage";

interface Props {
    onFollowupQuestionClicked?: (question: string) => void;
    getDefaultQuestion: any
}

export const AnswerInitChat = ({ onFollowupQuestionClicked, getDefaultQuestion }: Props) => {
    const [displayInitQuestion, setDisplayInitQuestion] = useState<boolean>(true)
    const [style, setStyle] = useState({ width: '12px', height: '12px', marginLeft: '6px', cursor: 'pointer', transform: 'rotate(180deg)' })
    const [questionInit, setQuestionInit] = useState([])

    const [isExperienceChat, setIsExperienceChat] = useState<boolean>(false);
    const [conversationExperienceActive, setConversationExperienceActive] = useState<any>(null);
    const [conversationActiveName, setConversationActiveName] = useState<string>('');
    const [isEmptyConversation, setIsEmptyConversation] = useState<boolean>(false);

    const [showModalQuestionExample, setShowModalQuestionExample] = useState(false);
    const [dataQuestionExample, setDataQuestionExample] = useState<any>(null);
    const [copied, setCopied] = useState<number>(0)
    const [width, setWidth] = useState(window.innerWidth);

    const handleCloseModalQuestionExample = () => setShowModalQuestionExample(false);
    const handleShowModalQuestionExample = (key: any) => {
        if (questionInit[key]) {
            setDataQuestionExample(questionInit[key]);
        }
        setCopied(0)
        setShowModalQuestionExample(true)
    }
    const onCoppy  = (key: any, answerText: any) => {
        navigator.clipboard.writeText(answerText?.content)
        setCopied(key)
        // setTimeout(() => {
        //     setCopied(false)
        // },  15 * 100)
    }

    const Svg_copy = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5.335" y="5.335" width="9.33" height="9.33" rx="1.665" stroke="var(--color_ic_interact)" strokeWidth="1.33" strokeLinejoin="round"/>
                <path d="M2.6875 10H2C1.44772 10 1 9.55228 1 9V2C1 1.44772 1.44772 1 2 1H9C9.55228 1 10 1.44772 10 2V2.6875" stroke="var(--color_ic_interact)" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    const isMobile = width <= 600;
    useEffect(() => {
        setStyle((prev) => ({ ...prev, transform: `${displayInitQuestion ? 'rotate(180deg)' : ''}` }))
    }, [displayInitQuestion])

    const firstUpdate = useRef(false);
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }

        const url = window.location.href;
        if (url && url.endsWith('experience/chat')) {
            setIsExperienceChat(true);
        }

        eventBus.on('experience-change-conversation-init-chat', async (data: any) => {
            if (data) {
                const conversationTmp = await getConversationStorage();

                if (data && data.idOld) {
                    const indexOldTmp = findIndex(conversationTmp, ['id', data.idOld]);
                    if (indexOldTmp > -1) {
                        data.indexOld = indexOldTmp;
                    }
                }

                if (data && data.idNew) {
                    const indexNewTmp = findIndex(conversationTmp, ['id', data.idNew]);
                    if (indexNewTmp > -1) {
                        data.indexNew = indexNewTmp;
                    }
                }

                if (conversationTmp) {
                    const conversationActive = conversationTmp[data.indexNew] || null;
                    if (conversationActive) {
                        conversationActive.conversationName = conversationActive.document_name ? conversationActive.document_name : conversationActive.file_name
                        setConversationExperienceActive(conversationActive)
                        setConversationActiveName(conversationActive.conversationName)
                    }
                }
            } else {
                setConversationExperienceActive(null)
                setConversationActiveName('')
            }
        });

        eventBus.on('empty-conversation', (data: any) => {
            setIsEmptyConversation(data)
        });

        firstUpdate.current = true;
        return () => { };
    }, []);
    useEffect(() => {
        if (getDefaultQuestion && getDefaultQuestion.data) {
            setQuestionInit(getDefaultQuestion.data)
        }
    }, [getDefaultQuestion])
    return (
        <>
            {isExperienceChat ? (
                <Stack>
                    {conversationActiveName && (
                        <Stack.Item>
                            <Stack className={`${styles.answerContainer}`} verticalAlign="space-between">
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between">
                                        <div className="" style={{ borderRadius: '50%', padding: '2px' }}>
                                            <AnswerIcon />
                                        </div>
                                    </Stack>
                                </Stack.Item>

                                <Stack.Item grow>
                                    {conversationActiveName && (
                                        <div className={styles.answerText}>
                                            Mời bạn thực hiện hỏi đáp với "{conversationActiveName}". Sau 1 giờ kể từ khi bắt đầu tải lên văn bản, hệ thống sẽ tự động xóa cuộc hội thoại
                                        </div>
                                    )}
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    )}

                    {isEmptyConversation && (
                        <Stack.Item>
                            <Stack className={`${styles.answerContainer}`} verticalAlign="space-between">
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between">
                                        <div className="" style={{ borderRadius: '50%', padding: '2px' }}>
                                            <AnswerIcon />
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item grow>
                                    <div className={styles.answerText}>
                                        Thêm mới trò chuyện ngay để sử dụng tính năng này
                                    </div>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
            ) : (
                <Stack>
                    {/* <Stack.Item>
                        <Stack className={`${styles.boxInitChatTop}`} verticalAlign="space-between">
                            <Stack.Item>
                                <Stack horizontal horizontalAlign="space-between">
                                    <div className="" style={{ borderRadius: '50%', padding: '2px' }}>
                                        <img src={logoInit} />
                                    </div>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item grow>
                                <div className={styles.answerText}>
                                    Trợ lý ảo tỉnh Yên Bái, một người đồng hành đáng tin cậy trong công việc của bạn. Mặc dù tôi có những giới hạn kiến thức, nhưng tôi cam kết cố gắng hết sức để hỗ trợ bạn một cách tốt nhất.
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <div className={styles.answerText} style={{ textAlign: 'center', paddingTop: '12px' }}>
                                    Người đồng hành đáng tin cậy trong công việc của bạn.
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <div className={styles.answerTextInstruct}>
                                    <div className="">
                                        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.4907 6H20.4907V2C20.4907 1.46957 20.28 0.960859 19.9049 0.585786C19.5299 0.210714 19.0212 0 18.4907 0H2.49072C1.96029 0 1.45158 0.210714 1.07651 0.585786C0.701436 0.960859 0.490723 1.46957 0.490723 2V18C0.49131 18.1881 0.544957 18.3723 0.6455 18.5313C0.746044 18.6903 0.889405 18.8177 1.05911 18.8989C1.22882 18.9801 1.418 19.0118 1.6049 18.9903C1.79181 18.9689 1.96886 18.8951 2.11572 18.7775L6.49072 15.25V19C6.49072 19.5304 6.70144 20.0391 7.07651 20.4142C7.45158 20.7893 7.96029 21 8.49072 21H20.1895L24.8657 24.7775C25.0427 24.9206 25.2631 24.9991 25.4907 25C25.7559 25 26.0103 24.8946 26.1978 24.7071C26.3854 24.5196 26.4907 24.2652 26.4907 24V8C26.4907 7.46957 26.28 6.96086 25.9049 6.58579C25.5299 6.21071 25.0212 6 24.4907 6ZM5.80947 13.2225L2.49072 15.9062V2H18.4907V13H6.43822C6.20935 13 5.98742 13.0786 5.80947 13.2225ZM24.4907 21.9062L21.172 19.2225C20.995 19.0794 20.7746 19.0009 20.547 19H8.49072V15H18.4907C19.0212 15 19.5299 14.7893 19.9049 14.4142C20.28 14.0391 20.4907 13.5304 20.4907 13V8H24.4907V21.9062Z" fill="var(--color-text-no-active)" fillOpacity="0.9" />
                                        </svg>

                                    </div>
                                    <div className={styles.answerTextWarning}>
                                        Hỏi bất cứ điều gì hoặc thử một ví dụ liên quan đến pháp luật
                                    </div>
                                </div>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item> */}
                    <Stack.Item>
                        <Stack className={`${styles.boxInitChatTop}`} verticalAlign="space-between">
                            <Stack.Item>
                                <Stack horizontal horizontalAlign="space-between">
                                    <div className="" style={{ borderRadius: '50%', padding: '2px'}}>
                                        <img src={logoInit}  style={{width: '44px', height: '44px' }}/>
                                    </div>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item grow>
                                <div className={styles.answerTextTitle}>
                                    {`Trợ lý ảo ${nameAssistant() ? `${nameAssistant()}` : 'Bộ Thông tin và Truyền thông'}`}
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                               <div className={`${styles.answerText} ${styles.answerTextIntro}`} style={{ textAlign: 'center' }}>
                                    {textWelcome()}
                               </div>
                               <div className={`${styles.answerText} ${styles.answerTextIntroMobile}`} style={{ textAlign: 'center' }}>
                                    {textWelcome()}
                               </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <div className={styles.boxWaring}>
                                    <img src={warning} />
                                    <span className={styles.answerTextWarning}>
                                        Cảnh báo
                                    </span>
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <div className={styles.answerTextWarningDes}>
                                    Tôi sẽ không hỗ trợ những nội dung mang tính chất nhạy cảm
                                </div>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        {(questionInit.length > 0 && questionInit[0] && typeof questionInit[0] === 'string' && onFollowupQuestionClicked) ? (
                            <Row className={styles.boxBottomContainer}>
                                {questionInit.map((question, i) => {
                                    return (
                                        <Col key={i} xs={12} md={4} className={styles.itemInitLeft}>
                                            <Stack className={`${styles.boxInitChatQuestion}`} verticalAlign="space-between">
                                                <Stack.Item grow style={{display: 'flex', alignItems: "center"}}>
                                                    <div className={styles.answerTextInit} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onFollowupQuestionClicked(question)}>
                                                        {question}
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </Col>
                                    )
                                })}
                            </Row>
                        ) : null}
                    </Stack.Item>
                    <Stack.Item>

                        {(questionInit.length > 0 && questionInit[0] && typeof questionInit[0] === 'object'  && onFollowupQuestionClicked) ? (
                            <Row>
                                {questionInit.map((question: any, i: any) => {
                                    return (
                                        <Col key={i} xs={12} md={4} className={styles.itemInitLeft}>
                                            <Stack className={`${styles.boxInitChatQuestionYB}`} verticalAlign="space-between">
                                                <Stack.Item grow style={{display: 'flex'}}>
                                                    <div className={styles.answerTextMain}>
                                                        <div className={styles.answerTextHeader}>
                                                            <div className={styles.answerTextIcon}>
                                                                { i === 0 && (
                                                                    <span>
                                                                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.5 6.94C16.4896 6.84813 16.4695 6.75763 16.44 6.67V6.58C16.3919 6.47718 16.3278 6.38267 16.25 6.3L10.25 0.3C10.1673 0.222216 10.0728 0.158081 9.97 0.11H9.87C9.77276 0.058003 9.66826 0.020922 9.56 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H13.5C14.2956 20 15.0587 19.6839 15.6213 19.1213C16.1839 18.5587 16.5 17.7956 16.5 17V7C16.5 7 16.5 7 16.5 6.94ZM10.5 3.41L13.09 6H11.5C11.2348 6 10.9804 5.89464 10.7929 5.70711C10.6054 5.51957 10.5 5.26522 10.5 5V3.41ZM14.5 17C14.5 17.2652 14.3946 17.5196 14.2071 17.7071C14.0196 17.8946 13.7652 18 13.5 18H3.5C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H8.5V5C8.5 5.79565 8.81607 6.55871 9.37868 7.12132C9.94129 7.68393 10.7044 8 11.5 8H14.5V17Z" fill="#1573FE"/> </svg>
                                                                    {/*<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.5 6.94C16.4896 6.84813 16.4695 6.75763 16.44 6.67V6.58C16.3919 6.47718 16.3278 6.38267 16.25 6.3L10.25 0.3C10.1673 0.222216 10.0728 0.158081 9.97 0.11H9.87C9.77276 0.058003 9.66826 0.020922 9.56 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H13.5C14.2956 20 15.0587 19.6839 15.6213 19.1213C16.1839 18.5587 16.5 17.7956 16.5 17V7C16.5 7 16.5 7 16.5 6.94ZM10.5 3.41L13.09 6H11.5C11.2348 6 10.9804 5.89464 10.7929 5.70711C10.6054 5.51957 10.5 5.26522 10.5 5V3.41ZM14.5 17C14.5 17.2652 14.3946 17.5196 14.2071 17.7071C14.0196 17.8946 13.7652 18 13.5 18H3.5C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H8.5V5C8.5 5.79565 8.81607 6.55871 9.37868 7.12132C9.94129 7.68393 10.7044 8 11.5 8H14.5V17Z" fill="#919191"/> </svg>*/}
                                                                    </span>
                                                                )}
                                                                { i === 1 && (
                                                                    <span>
                                                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12.4997 13.5C9.0419 13.5 6.13061 15.84 5.26307 19.0229C4.9726 20.0886 5.89517 21 6.99974 21H17.9997C19.1043 21 20.0269 20.0886 19.7364 19.0229C18.8689 15.84 15.9576 13.5 12.4997 13.5Z" stroke="#049C6B" stroke-width="1.7" stroke-linecap="round"/> <path d="M15.9995 7C15.9995 8.933 14.4325 10.5 12.4995 10.5C10.5665 10.5 8.99951 8.933 8.99951 7C8.99951 5.067 10.5665 3.5 12.4995 3.5C14.4325 3.5 15.9995 5.067 15.9995 7Z" stroke="#049C6B" stroke-width="1.7" stroke-linecap="round"/> </svg>
                                                                    </span>
                                                                )}
                                                                { i === 2 && (
                                                                    <span>
                                                                        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.5 15C13.5 16.6569 14.8431 18 16.5 18C18.1569 18 19.5 16.6569 19.5 15C19.5 13.3431 18.1569 12 16.5 12C14.8431 12 13.5 13.3431 13.5 15ZM13.5 15H7.5M7.5 15C7.5 16.6569 6.15685 18 4.5 18C2.84315 18 1.5 16.6569 1.5 15C1.5 13.3431 2.84315 12 4.5 12C6.15685 12 7.5 13.3431 7.5 15ZM5.93924 12.3671L9.0675 6.63656M11.9413 6.63172L15.1123 12.3395M13.5 4C13.5 5.65685 12.1569 7 10.5 7C8.8431 7 7.5 5.65685 7.5 4C7.5 2.34315 8.8431 1 10.5 1C12.1569 1 13.5 2.34315 13.5 4Z" stroke="#20A9C7" stroke-width="1.7" stroke-linecap="round"/> </svg>
                                                                    </span>
                                                                )}
                                                                { i === 3 && (
                                                                    <span>
                                                                        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.5 4C17.8431 4 16.5 2.65685 16.5 1M1.5 4C3.15685 4 4.5 2.65685 4.5 1M19.5 8C17.8431 8 16.5 9.3431 16.5 11M1.5 8C3.15685 8 4.5 9.3431 4.5 11M3.5 1H17.5C18.6046 1 19.5 1.89543 19.5 3V9C19.5 10.1046 18.6046 11 17.5 11H3.5C2.39543 11 1.5 10.1046 1.5 9V3C1.5 1.89543 2.39543 1 3.5 1ZM12.5 6C12.5 7.1046 11.6046 8 10.5 8C9.3954 8 8.5 7.1046 8.5 6C8.5 4.8954 9.3954 4 10.5 4C11.6046 4 12.5 4.8954 12.5 6Z" stroke="#DD5B13" stroke-width="1.7" stroke-linecap="round"/> </svg>
                                                                    </span>
                                                                )}
                                                                { i === 4 && (
                                                                    <span>
                                                                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.65362 14.3232C1.40126 14.8812 1.3591 15.3553 1.56294 15.7084C2.42631 17.2037 7.37579 15.9625 12.6179 12.9359C17.8601 9.90937 21.4098 6.2436 20.5464 4.74821C20.3426 4.39516 19.911 4.19465 19.3015 4.13416M14.7525 16.5007L15.7497 18.2279M6.24969 1.77344L8.11294 5.00067M18.4997 10.0007C18.4997 14.1428 15.1418 17.5007 10.9997 17.5007C6.85756 17.5007 3.49969 14.1428 3.49969 10.0007C3.49969 5.85854 6.85756 2.50068 10.9997 2.50068C15.1418 2.50068 18.4997 5.85854 18.4997 10.0007Z" stroke="#961B27" stroke-width="1.7" stroke-linecap="round"/> </svg>
                                                                    </span>
                                                                )}
                                                                { i === 5 && (
                                                                    <span>
                                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12.5 1.61719C17.034 2.33636 20.5 6.26313 20.5 10.9994C20.5 13.0786 19.8321 15.0017 18.6991 16.566M9.5 1.61719C4.96602 2.33636 1.5 6.26313 1.5 10.9994C1.5 13.0833 2.17101 15.0106 3.30876 16.5768M5.42198 18.69C6.98834 19.8281 8.91574 20.4994 11 20.4994C13.0842 20.4994 15.0116 19.8282 16.578 18.6902M7.95136 5.83057C8.84478 5.30249 9.887 4.99943 11 4.99943C12.113 4.99943 13.1552 5.30249 14.0486 5.83057M5.83114 7.95079C5.30305 8.84422 5 9.88645 5 10.9994C5 13.7951 6.91216 16.1443 9.5 16.8104M16.1686 7.95049C16.6968 8.84399 17 9.88625 17 10.9994C17 13.7951 15.0878 16.1443 12.5 16.8104M13 10.9994C13 12.104 12.1046 12.9994 11 12.9994C9.8954 12.9994 9 12.104 9 10.9994C9 9.89485 9.8954 8.99943 11 8.99943C12.1046 8.99943 13 9.89485 13 10.9994Z" stroke="#DF1525" stroke-width="1.7" stroke-linecap="round"/> </svg>
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className={`${styles.answerTextTitle}`}>
                                                                { i === 0 && question && question.category && (
                                                                    <span style={{ color: '#1573FE' }}>{question.category}</span>
                                                                )}
                                                                { i === 1 && question && question.category && (
                                                                    <span style={{ color: '#049C6B' }}>{question.category}</span>
                                                                )}
                                                                { i === 2 && question && question.category && (
                                                                    <span style={{ color: '#20A9C7' }}>{question.category}</span>
                                                                )}
                                                                { i === 3 && question && question.category && (
                                                                    <span style={{ color: '#DD5B13' }}>{question.category}</span>
                                                                )}
                                                                { i === 4 && question && question.category && (
                                                                    <span style={{ color: '#961B27' }}>{question.category}</span>
                                                                )}
                                                                { i === 5 && question && question.category && (
                                                                    <span style={{ color: '#DF1525' }}>{question.category}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {question && question.question && (
                                                            <>
                                                                <div className={`${styles.answerTextBody}`} onClick={() => onFollowupQuestionClicked ? onFollowupQuestionClicked(question.question) : null}>
                                                                    {question.question}
                                                                </div>
                                                                {question && question.questions && question.questions.length > 1 && (
                                                                    <div className={styles.answerTextFooter} onClick={() => handleShowModalQuestionExample(i)}>
                                                                        <span>Xem thêm →</span>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </Col>
                                    )
                                })}
                            </Row>
                        ) : null}

                        {/*<Row>*/}
                        {/*    <Col key={1} xs={12} md={4} className={styles.itemInitLeft}>*/}
                        {/*        <Stack className={`${styles.boxInitChatQuestionYB}`} verticalAlign="space-between">*/}
                        {/*            <Stack.Item grow style={{display: 'flex', alignItems: "center"}}>*/}
                        {/*                <div className={styles.answerTextMain}>*/}
                        {/*                    <div className={styles.answerTextHeader}>*/}
                        {/*                        <div className={styles.answerTextIcon}>*/}
                        {/*                            <span>*/}
                        {/*                                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.5 6.94C16.4896 6.84813 16.4695 6.75763 16.44 6.67V6.58C16.3919 6.47718 16.3278 6.38267 16.25 6.3L10.25 0.3C10.1673 0.222216 10.0728 0.158081 9.97 0.11H9.87C9.77276 0.058003 9.66826 0.020922 9.56 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H13.5C14.2956 20 15.0587 19.6839 15.6213 19.1213C16.1839 18.5587 16.5 17.7956 16.5 17V7C16.5 7 16.5 7 16.5 6.94ZM10.5 3.41L13.09 6H11.5C11.2348 6 10.9804 5.89464 10.7929 5.70711C10.6054 5.51957 10.5 5.26522 10.5 5V3.41ZM14.5 17C14.5 17.2652 14.3946 17.5196 14.2071 17.7071C14.0196 17.8946 13.7652 18 13.5 18H3.5C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H8.5V5C8.5 5.79565 8.81607 6.55871 9.37868 7.12132C9.94129 7.68393 10.7044 8 11.5 8H14.5V17Z" fill="#919191"/> </svg>*/}
                        {/*                            </span>*/}
                        {/*                        </div>*/}
                        {/*                        <div className={styles.answerTextTitle}>Văn bản pháp luật</div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={styles.answerTextBody}>*/}
                        {/*                        "Ai là cơ quan quyết định phát hành tem Bưu chính Việt Nam?"*/}
                        {/*                    </div>*/}
                        {/*                    <div className={styles.answerTextFooter} onClick={handleShowModalQuestionExample}>*/}
                        {/*                        <span>Xem thêm ví dụ →</span>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </Stack.Item>*/}
                        {/*        </Stack>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}


                        {/*{(questionInit.length > 0 && onFollowupQuestionClicked) ? (*/}
                        {/*    <Row className={styles.boxBottomContainer}>*/}
                        {/*        {questionInit.map((question, i) => {*/}
                        {/*            return (*/}
                        {/*                <Col key={i} xs={12} md={4} className={styles.itemInitLeft}>*/}
                        {/*                    <Stack className={`${styles.boxInitChatQuestion}`} verticalAlign="space-between">*/}
                        {/*                        <Stack.Item grow style={{display: 'flex', alignItems: "center"}}>*/}
                        {/*                            <div className={styles.answerTextInit} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onFollowupQuestionClicked(question)}>*/}
                        {/*                                {question}*/}
                        {/*                            </div>*/}
                        {/*                        </Stack.Item>*/}
                        {/*                    </Stack>*/}
                        {/*                </Col>*/}
                        {/*            )*/}
                        {/*        })}*/}
                        {/*    </Row>*/}
                        {/*) : null}*/}
                    </Stack.Item>
                    <Stack.Item>
                        <div style={{ textAlign: 'center'}}>
                            <div className={styles.answerTextWarningDesBottom}>
                                Trợ lý ảo sử dụng công nghệ AI nên có thể xảy ra những sai sót không mong muốn. Vui lòng đánh giá và phản hồi để giúp hệ thống cải tiến liên tục.
                            </div>
                        </div>
                    </Stack.Item>

                    {/*<Stack.Item className={styles.boxWaringContainer} grow>*/}
                    {/*    <div className={styles.boxWaring}>*/}
                    {/*        <img src={warning} style={{marginRight: '5px'}}/>*/}
                    {/*        <span className={styles.answerTextWarning}>*/}
                    {/*            Cảnh báo*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*</Stack.Item>*/}
                    {/*<Stack.Item grow>*/}
                    {/*    <div className={styles.answerTextWarningDes}>*/}
                    {/*        Tôi sẽ không hỗ trợ những nội dung mang tính chất nhạy cảm*/}
                    {/*    </div>*/}
                    {/*</Stack.Item>*/}

                    <Modal className={styles.modalQuestionExample} contentClassName="modalQuestionExampleContent" show={showModalQuestionExample} onHide={handleCloseModalQuestionExample} centered  size="xl">
                        <Modal.Header className={styles.modalQuestionExampleHeader} closeButton>
                            <Modal.Title className={styles.modalQuestionExampleTitle}>
                                {dataQuestionExample && dataQuestionExample.category && (
                                    <span>{dataQuestionExample.category}</span>
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className={styles.questionExampleItems}>
                                {(dataQuestionExample && dataQuestionExample.questions && dataQuestionExample.questions.length > 0) ? (
                                    <>
                                        {dataQuestionExample.questions.map((question: any, i: any) => {
                                            return (
                                                <>
                                                    <Col className={styles.questionExampleItem} xs={12} md={12} lg={6} xl={4}>
                                                        <Card className={styles.questionExampleItemCard}>
                                                            <Card.Body className={styles.questionExampleItemText}>
                                                                <div className={styles.questionExampleItemTextMain}>
                                                                    {question.format && (
                                                                        <div className={styles.questionExampleItemTextMainType}>{question.format}</div>
                                                                    )}
                                                                    {question.content && (
                                                                        <div className={styles.questionExampleItemTextMainContent}>{question.content}</div>
                                                                    )}
                                                                </div>
                                                                <span className={styles.questionExampleItemCopy}>
                                                                <div onClick={() => onCoppy(i + 1, question)} className={`${styles.boxIcon} ${styles.boxIconQuestionExample} ${copied === (i + 1) ? styles.boxStyle : ''}`}>
                                                                    <IconContext.Provider value={{style: {color: 'var(--color_ic_interact)'}, className: styles.likeIcon }}>
                                                                        {copied === (i + 1) ?
                                                                            <LuCopyCheck />
                                                                            :
                                                                            <Svg_copy/>
                                                                        }
                                                                    </IconContext.Provider>
                                                                </div>
                                                                    {/*<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="6.4893" y="6.4893" width="11.1762" height="11.1762" rx="1.665" stroke="#C4C4C4" strokeWidth="1.33" strokeLinejoin="round"/> <path d="M3.07692 12.0769H2C1.44772 12.0769 1 11.6292 1 11.0769V2C1 1.44771 1.44772 1 2 1H11.0769C11.6292 1 12.0769 1.44772 12.0769 2V3.07692" stroke="#C4C4C4" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/> </svg>*/}
                                                            </span>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </>
                                            )
                                        })}
                                    </>
                                ) : null}
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Stack>
            )}
        </>
    );
};
