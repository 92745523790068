import { parseSupportingContentItem } from "./SupportingContentParser";

import styles from "./SupportingContent.module.css";
import DOMPurify from "dompurify";

interface Props {
    supportingContent: string[];
    supportingScore: string[];
    rerankScore: string[]
}

export const SupportingContent = ({ supportingContent, supportingScore, rerankScore }: Props) => {
    return (
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);
                const sanitizedAnswerHtml = DOMPurify.sanitize((parsed.content));
                return (
                    <li key={i} className={styles.supportingContentItem}>
                        <h4 className={styles.supportingContentItemHeader}>
                            {parsed.title}
                            {supportingScore[i] && (
                                <>
                                    ________Score: {supportingScore[i]}
                                    {rerankScore && rerankScore.length ? `________Rerank Score: ${rerankScore[i]}` : ''}
                                </>
                            )}
                        </h4>
                        {/* <p className={styles.supportingContentItemText}>{parsed.content}</p> */}
                        <div className={styles.supportingContentItemText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>

                    </li>
                );
            })}
        </ul>
    );
};
