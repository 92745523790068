import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define Toast options with default configuration
const toastOptions: any = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light' // Choose 'dark' for dark theme
};

const showToasty = (message: string, type: 'success' | 'error' | 'info' | 'warning' = 'info') => {
  toast[type](message, toastOptions);
};

export default showToasty;