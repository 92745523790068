import { Accordion, Button, Card, Col, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import isEmpty from 'lodash/isEmpty'
import styles from "./Search.module.css";
import { openWindowToUrl } from "../../utils/openWindowUrl";
import { useState } from "react";
import { forEach } from "lodash";


interface Props {
  className?: string;
  noteContents?: any
}

export const PopoverAnswer = ({ noteContents, className }: Props) => {
  const [showHighlightCitation, setShowHighlightCitation] = useState<boolean>(false);

  const navigate = (item: any) => {
    if (item.type === 'popup') {
      return
    } else {
      switch (item.typeJump) {
        case 'internal':
          break;
        case 'external':
          if (item.url) {
            openWindowToUrl(item.url);
          }
          break;
        case 'disable ':
          break;
      }
    }
  }
  const navigateLaw = (item: string) => {
    openWindowToUrl(item);
  }
  const notePopover = () => {
    if (noteContents && typeof noteContents === 'string') {
      let _url = ""
      if (noteContents.includes('trolyao.toaan.gov.vn')) {
        _url = noteContents.replace("trolyao.toaan.gov.vn", "tlacc.cyberbot.vn/static");
      } else {
        _url = noteContents
      }
      return (
        <div className={styles.contentMetadatas}>
          <span onClick={() => navigateLaw(noteContents)} className={styles.textNoteMetedatas}>{_url}</span>
        </div>
      )
    } else if (noteContents) {
      return (
        <div className={styles.contentMetadatas}>
          {noteContents && noteContents[0] && noteContents[0].noteMetadatas && noteContents[0].noteMetadatas.map((item: any, idx: number) => (
            <span key={idx} onClick={() => navigate(item)} className={styles.textNoteMetedatas}>{item.word}</span>
          ))}
        </div>
      )
    }
  }
  const popover = (
    <Popover id="popover-basic" className={styles.popoverOverlay}>
      <Popover.Body>
        <div className="">
          {notePopover()}
        </div>
      </Popover.Body>
    </Popover>
  );
  const isHighlightCitation = (noteMetadatas: any) => {
    let isHighlight = false
    if (noteMetadatas && noteMetadatas.length) {
      forEach(noteMetadatas, function (metadata: any, key: any) {
        if (metadata.guide) {
          isHighlight = true
          return false
        }
      })
    }

    return isHighlight
  }
  const onShowHighlightCitation = () => {
    if (noteContents && noteContents[0] && isHighlightCitation(noteContents[0].noteMetadatas)) {
      setShowHighlightCitation(true);
    }
  };
  const onCloseHighlightCitation = () => {
    setShowHighlightCitation(false)
  }
  return (
    <>
      {/*<OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>*/}
      {/*    <Button className={`${styles.popoverButton} ${noteContents && typeof noteContents === 'string' ? '' : className}`} variant="success">Chỉ dẫn</Button>*/}
      {/*</OverlayTrigger>*/}
      <Button className={`${styles.popoverButton} ${noteContents && typeof noteContents === 'string' ? '' : className}`} variant="success" onClick={onShowHighlightCitation}>Chỉ dẫn</Button>

      <Modal show={showHighlightCitation} onHide={onCloseHighlightCitation} size="xl" centered className="modal-citation-container" dialogClassName="modal-highlight-citation" scrollable>
        <Modal.Header
          closeButton
        // style={{background: '#e5e7eb'}}
        >
          <Modal.Title>Các nội dung sửa đổi, hướng dẫn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            {noteContents && noteContents[0] && noteContents[0].noteMetadatas && noteContents[0].noteMetadatas.length && noteContents[0].noteMetadatas.map((meta: any, index: any) => (
              (
                <div key={index}>
                  {meta.guide && (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{meta.guide}</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={6} md={12} className='accordionColMobile'>
                            <div>
                              <div className={styles.cardHeaderContent}>Nội dung gốc</div>
                              <div className={styles.cardHeaderHightlight}></div>

                              <div className={styles.cardBodyContent}>
                                <div className={styles.cardTextContent}>
                                  {meta.originalContent}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={12}>
                            <div>
                              <div className={styles.cardHeaderContent}>Nội dung sửa đổi, hướng dẫn</div>
                              <div className={styles.cardHeaderHightlight}></div>

                              <div className={styles.cardBodyContent}>
                                <div className={styles.cardTextContent}>
                                  {meta.editContent}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </div>
              )
            ))}
          </Accordion>
        </Modal.Body>
      </Modal>

    </>
  )
};
