import { Text } from "@fluentui/react";
import { Delete24Regular } from "@fluentui/react-icons";

import styles from "./ClearChatButton.module.css";
import showToasty from "../../hooks/useNotify";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    disabledRate?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick, disabledRate }: Props) => {
    return (
        <div
            className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`}
            onClick={() => {
                if (disabledRate) {
                    showToasty("Vui lòng hoàn thành đánh giá!", "error");
                    return;
                }
                onClick && onClick();
            }}
        >
            <Delete24Regular />
            <Text className="text-layout-chat">{"Xóa lịch sử chat"}</Text>
        </div>
    );
};
