import EmptyChatLight from "../../assets/uiv2/No_Chat_History_White.svg"
import EmptyChatDark from "../../assets/uiv2/No_Chat_History_Dark.svg"


import styles from "./ChatHistory.module.css";
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";
import { DraftStatus, RuleChat } from "../../api/models";
interface Props {
    themeLocal: string | null;
}
export const EmptyChatHistory = observer(({themeLocal}: Props) => {
    const {
        rootStore: { sessionChatsStore, draftStore},
      } = useStore();
    return (
        <div className={styles.boxchatEmpty}>
            <div className={styles.boxIcEmpty}>
                <img src={themeLocal === 'dark' ? EmptyChatDark : EmptyChatLight} className={styles.icchatEmpty} />
            </div>
            <div className={styles.textEmpty}>
                {
                    sessionChatsStore.ruleChat === RuleChat.chat ?
                    <>
                <p className={styles.textEmptyHistory}>Chưa có lịch sử chat</p>
                <p className={styles.textEmptyStartChat}>Chưa có trò chuyện nào. Hãy bắt đầu!</p>
                    </> : sessionChatsStore.ruleChat === RuleChat.draft && draftStore.activeDraft === DraftStatus.sent ? 
                    <p className={styles.textEmptyHistory}>Chưa có đóng góp</p> :
                    <p className={styles.textEmptyHistory}>Chưa có bản nháp được lưu</p>
                }
            </div>
        </div>
    );
});
