import { Dispatch } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Label, TextField } from '@fluentui/react';
import './styles.css';
import { Delete24Regular } from '@fluentui/react-icons';
import { AsyncDropDown } from '../AsyncSelect';
import { AsyncDropDownArticle } from '../AsyncSelectArticle';

interface Props {
    documentCitation: any[]
    setDocumentCitation: Dispatch<any>
}
const CitationColumn = ({ documentCitation, setDocumentCitation }: Props) => {
    const handleChangeLaw = (field: any, ind: number) => {
        setDocumentCitation((prev: any) => {
            if (ind >= 0 && ind < prev.length && prev[ind] && prev[ind]?.type === 'law') {
                return prev.map((citation: any, i: number) =>
                    i === ind ? { ...citation, law: field } : citation
                );
            } else {
                return prev;
            }
        });
    };
    const handleChangeArtical = (field: any, ind: number) => {
        setDocumentCitation((prev: any) => {
            if (ind >= 0 && ind < prev.length && prev[ind] && prev[ind]?.type === 'law') {
                return prev.map((citation: any, i: number) =>
                    i === ind ? { ...citation, article: field } : citation
                );
            } else {
                return prev;
            }
        });
    };

    const handleDeleteDocumentCitation = (ind: any) => {
        setDocumentCitation((prev: any) => prev.filter((_c: any, i: any) => i !== ind));
    }

    return (
        <> {
            !!(documentCitation && documentCitation.length) && documentCitation.map((citation, ind: any) => {
                return (
                    <div className='citation-law-container' key={ind}>
                        {
                            citation?.type === 'law' &&
                            <>
                                <Accordion className="mb-3 accordion-thoughts-citation" defaultActiveKey={ind}>
                                    <Accordion.Item eventKey={ind}>
                                        <Accordion.Header>
                                            <div className="button-header">
                                                <span>Trích dẫn văn bản</span>
                                                <span className="me-3" onClick={() => handleDeleteDocumentCitation(ind)}>
                                                    <Delete24Regular style={{ width: "20px", height: "20px", color: '#EE0033' }} />
                                                </span>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col lg={6} md={12}>
                                                    <Label>
                                                        Văn bản
                                                    </Label>
                                                    <div>
                                                        <AsyncDropDown setFieldSelected={field => handleChangeLaw(field, ind)} fieldSelected={citation?.law} />
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={12}>
                                                    <Label>
                                                        Điều
                                                    </Label>
                                                    <div>
                                                        <AsyncDropDownArticle 
                                                            setFieldSelected={field => handleChangeArtical(field, ind)} 
                                                            fieldSelected={citation?.article} 
                                                            documentId={citation?.law?.documentId}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={12} className="mt-4">
                                                    <TextField
                                                        placeholder="Khoản"
                                                        onChange={(e, newValue) => {
                                                            setDocumentCitation((prev: any) => {
                                                                if (ind >= 0 && ind < prev.length && prev[ind] && prev[ind]?.type === 'law') {
                                                                    return prev.map((citation: any, i: number) =>
                                                                        i === ind ? { ...citation, clauseTitle: newValue || "" } : citation
                                                                    );
                                                                } else {
                                                                    return prev;
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg={6} md={12} className="mt-4">
                                                    <TextField
                                                        placeholder="Điểm"
                                                        onChange={(e, newValue) => {
                                                            setDocumentCitation((prev: any) => {
                                                                if (ind >= 0 && ind < prev.length && prev[ind] && prev[ind]?.type === 'law') {
                                                                    return prev.map((citation: any, i: number) =>
                                                                        i === ind ? { ...citation, pointTitle: newValue || "" } : citation
                                                                    );
                                                                } else {
                                                                    return prev;
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>


                            </>
                        }
                        {
                            citation?.type === 'url' &&
                            <div className='mb-4 mt-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className="" style={{ width: '100%' }}>
                                    <TextField
                                        placeholder="Nhập đường dẫn"
                                        onChange={(e, newValue) => {
                                            setDocumentCitation((prev: any) => {
                                                if (ind >= 0 && ind < prev.length && prev[ind] && prev[ind]?.type === 'url') {
                                                    return prev.map((citation: any, i: number) =>
                                                        i === ind ? { ...citation, urlDisplay: newValue || "" } : citation
                                                    );
                                                } else {
                                                    return prev;
                                                }
                                            });
                                        }}
                                    />
                                </div>
                                <div className="ms-3" style={{ cursor: 'pointer' }} title="Xóa trích dẫn url" onClick={() => handleDeleteDocumentCitation(ind)}>
                                    <Delete24Regular style={{ width: "20px", height: "20px", color: '#EE0033' }} />
                                </div>
                            </div>
                        }

                    </div>
                )
            })
        }
        </>
    );
};

export default CitationColumn;