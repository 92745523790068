import { useEffect, useRef, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import ic_setting_v2 from "../../../assets/uiv2/ic_setting_v2.svg"
import eventBus from '../../../plugins/EventBus';
import './styles.css';

const Svg_change_pass = () => {
    return (
        <svg style={{ color: '#3DBFFE', marginRight: '8px', marginLeft: '-2px' }} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1702 0.50145C5.92856 0.384784 2.45356 3.78478 2.45356 8.00145H0.961891C0.586891 8.00145 0.403557 8.45145 0.670224 8.70978L2.99522 11.0348C3.16189 11.2015 3.42022 11.2015 3.58689 11.0348L5.92022 8.70978C6.17022 8.45145 5.98689 8.00145 5.61189 8.00145H4.12022C4.12022 4.75978 6.78689 2.11812 10.0369 2.16812C13.1286 2.20978 15.7369 4.81812 15.7869 7.91812C15.8369 11.1765 13.2036 13.8348 9.95356 13.8348C8.62856 13.8348 7.41189 13.3931 6.42856 12.6431C6.09522 12.3931 5.62856 12.4181 5.33689 12.7181C4.97856 13.0765 5.01189 13.6681 5.41189 13.9681C6.67022 14.9265 8.24522 15.5015 9.95356 15.5015C14.1702 15.5015 17.5702 12.0265 17.4536 7.79312C17.3452 3.87645 14.0786 0.609784 10.1702 0.50145ZM11.6202 7.16812V6.33478C11.6202 5.41812 10.8702 4.66812 9.95356 4.66812C9.03689 4.66812 8.28689 5.41812 8.28689 6.33478V7.16812C7.82856 7.16812 7.45356 7.54312 7.45356 8.00145V10.5015C7.45356 10.9598 7.82856 11.3348 8.28689 11.3348H11.6202C12.0786 11.3348 12.4536 10.9598 12.4536 10.5015V8.00145C12.4536 7.54312 12.0786 7.16812 11.6202 7.16812ZM10.7869 7.16812H9.12022V6.33478C9.12022 5.87645 9.49522 5.50145 9.95356 5.50145C10.4119 5.50145 10.7869 5.87645 10.7869 6.33478V7.16812Z" fill="var(--color_ic_boxsetting)" />
        </svg>
    )
}

const Svg_logout = () => {
    return (
        <svg style={{ color: '#3DBFFE', marginRight: '8px' }} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5625 11.25V9H7.9375V6.75H13.5625V4.5L16.9375 7.875L13.5625 11.25ZM12.4375 10.125V14.625H6.8125V18L0.0625 14.625V0H12.4375V5.625H11.3125V1.125H2.3125L6.8125 3.375V13.5H11.3125V10.125H12.4375Z" fill="var(--color_ic_boxsetting)" />
        </svg>
    )
}
const DropdownMenuInfor = () => {
    const [user, setUser] = useState<any>(localStorage.getItem('_user'))

    const handleChangePass = () => {
        eventBus.dispatch('handleChangePass', {});
    }
    const logOut = () => {
        eventBus.dispatch('logOutNavbar', {});
    }
    const getCurrentUser = (string: string) => {
        if(string) {
            const words = string.split(" ");
            const firstLetterOfLastWord = words[words.length - 1][0];
            const result = firstLetterOfLastWord?.toUpperCase()
            return result
        }
        return ""
    }

    const firstUpdate = useRef(false);

    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        if (user) {
            const userTmp = JSON.parse(user)
            setUser(userTmp)
        }
        firstUpdate.current = true;
        return () => { };
    }, []);
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <div className="settingInfor">
                    {/* <img src={ic_avatar_v2}/> */}
                    <div className="infor">
                        <span className='inforText'>{getCurrentUser(user?.fullname)}</span>
                    </div>
                    <div className="settingIcon">
                        <img src={ic_setting_v2} />
                    </div>
                </div>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                    <DropdownMenu.Item className="DropdownMenuItemInfor">
                        <div className="DropdownMenuInfor" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="itemInfor">
                                <span className='inforText'>{getCurrentUser(user?.fullname)}</span>
                            </div>
                            <div className="tla-infor-des">
                                <span className='tla-infor-des-fullname'>{user?.fullname}</span>
                                <span className='tla-infor-des-more'>{user?.position}</span>
                            </div>
                        </div>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator className="DropdownMenuSeparator" />
                    <DropdownMenu.Item className="DropdownMenuItem" onClick={handleChangePass}>
                        <div className="boxItemDropdown" style={{ display: 'flex', alignItems: 'center' }}>
                            <Svg_change_pass/> Đổi mật khẩu
                        </div>
                    </DropdownMenu.Item>
                    {/* <DropdownMenu.Item className="DropdownMenuItem" onClick={logOut}>
                        <div className="boxItemDropdown" style={{ display: 'flex', alignItems: 'center' }}>
                            <Svg_logout/>Đăng xuất
                        </div>
                    </DropdownMenu.Item> */}

                    <DropdownMenu.Arrow className="DropdownMenuArrow" />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default DropdownMenuInfor;