import styles from "./AnalysisPanel.module.css";
import { AskResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { useEffect, useState } from "react";
import { PopoverAnswer } from "../Common";
import { openWindowToUrl } from "../../utils/openWindowUrl";
import { Modal, Accordion, Card, Row, Col } from "react-bootstrap";

interface Props {
    answer?: any;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const PopupCitationPanel = ({ answer }: Props) => {
    const [lawName, setLawName] = useState<string>("");
    const [lawPointName, setLawPointName] = useState<string>("");

    const [showHighlightCitation, setShowHighlightCitation] = useState<boolean>(false);
    const [noteMetadatasHighlight, setNoteMetadatasHighlight] = useState<any>(null);

    const handleCloseHighlightCitation = () => {
        setShowHighlightCitation(false);
    };
    const handleShowHighlightCitation = () => {
        setShowHighlightCitation(true);
    };

    const onClickHighlightCitation = (keyNote: any, keyMetadata: any) => {
        if (answer && answer.citation && answer.citation.noteChildContents && answer.citation.noteChildContents[keyNote]) {
            handleShowHighlightCitation();
            if (answer.citation.noteChildContents[keyNote].noteMetadatas && answer.citation.noteChildContents[keyNote].noteMetadatas.length) {
                setNoteMetadatasHighlight(answer.citation.noteChildContents[keyNote].noteMetadatas);
            }
        }
    };

    const navigateLaw = (url: string) => {
        if (url && typeof url === "string") {
            openWindowToUrl(url);
        }
    };

    useEffect(() => {
        if (answer && answer.citation && answer.citation.nameDisplay) {
            const namePiece = answer.citation.nameDisplay.split("\n");
            if (namePiece && namePiece.length) {
                if (namePiece[0]) {
                    setLawName(namePiece[0]);
                }
                if (namePiece[1]) {
                    setLawPointName(namePiece[1]);
                }
            }
        }

        if (answer && answer.citation && answer.citation.noteChildContents && answer.citation.noteChildContents.length) {
            setTimeout(() => {
                const highlightCitations = document.getElementsByClassName("citation-highlight");
                if (highlightCitations && highlightCitations.length) {
                    for (let i = 0; i < highlightCitations.length; i++) {
                        highlightCitations[i].removeEventListener("click", function () {}, false);
                        highlightCitations[i].addEventListener("click", function (e: any) {
                            if (e && e.target && e.target.dataset && e.target.dataset.hasOwnProperty("keyMetadata")) {
                                const keyMetadata = e.target.dataset["keyMetadata"];
                                const keyNote = e.target.dataset["keyNote"];
                                onClickHighlightCitation(keyNote, keyMetadata);
                            }
                        });
                    }
                }
            });
        }
    }, [answer]);

    return (
        <>
            {answer && answer.citation && answer.citation.noteChildContents ? (
                <div className={styles.cardPopup}>
                    <div className={styles.cardBody}>
                        {lawName && (
                            <div className={styles.quoteContainer}>
                                <div
                                    className={`${styles.textBold} ${styles.textBoldLink}`}
                                    style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}
                                    onClick={() => (answer.citation && answer.citation.url ? navigateLaw(answer.citation.url) : null)}
                                >
                                    {lawName}
                                </div>
                            </div>
                        )}
                        <div className={styles.quoteContainer}>
                            <div className={`${styles.textBold}`} style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}>
                                {answer.citation.chapterName}
                            </div>
                        </div>

                        {lawPointName && (
                            <div style={{ marginBottom: "0.5rem" }}>
                                <div className={`${styles.textLight} ${styles.text}`} style={{ display: "inline" }}>
                                    {lawPointName}
                                </div>
                                {/* {
                                        answer.citation.noteContents && answer.citation.noteContents.length ?
                                            <div style={{display: 'inline-block', marginLeft: '5px'}}>
                                                <PopoverAnswer noteContents={answer.citation.noteContents} />
                                            </div>
                                            : null
                                    } */}
                            </div>
                        )}
                        <div style={{ marginBottom: "1rem" }}>
                            {answer.citation.noteChildContents.map((note: any, index: any) => (
                                <>
                                    <div key={index} style={{ marginBottom: "0.25rem" }}>
                                        <span
                                            style={{ backgroundColor: note.highlight ? "var(--bg-is-hightlight_not_active)" : "none" }}
                                            dangerouslySetInnerHTML={{ __html: note.wordHtml ? note.wordHtml : note.word }}
                                        ></span>
                                    </div>
                                </>
                            ))}
                        </div>

                        <div className={styles.tag}>
                            {answer.citation.type && <span className={styles.tagItem}>{answer.citation.type}</span>}
                            {answer.citation.field && <span className={styles.tagItem}>{answer.citation.field}</span>}
                            {answer.citation.courtType && <span className={styles.tagItem}>{answer.citation.courtType}</span>}
                            {answer.citation.level && <span className={styles.tagItem}>{answer.citation.level}</span>}
                            {answer.citation.agencyIssued && <span className={styles.tagItem}>{answer.citation.agencyIssued}</span>}
                            {answer.citation.signedBy && <span className={styles.tagItem}>Người ký: {answer.citation.signedBy}</span>}
                            {answer.citation.expirationDate && <span className={styles.tagItem}>Ngày hết hiệu lực: {answer.citation.expirationDate}</span>}
                            {answer.citation.legalRelation && <span className={styles.tagItem}>{answer.citation.legalRelation}</span>}
                            {answer.citation.publicStatus && <span className={styles.tagItem}>{answer.citation.publicStatus}</span>}
                            {answer.citation.applyDate && <span className={styles.tagItem}>Áp dụng: {answer.citation.applyDate}</span>}
                            {answer.citation.applyPrecedent && <span className={styles.tagItem}>{answer.citation.applyPrecedent}</span>}
                            {answer.citation.validityStatus && <span className={styles.tagItem}>{answer.citation.validityStatus}</span>}
                            {answer.citation.delict && <span className={styles.tagItem}>{answer.citation.delict}</span>}
                            {answer.citation.judgmentDate && <span className={styles.tagItem}>Ngày công bố: {answer.citation.judgmentDate}</span>}
                        </div>
                    </div>
                    <Modal
                        show={showHighlightCitation}
                        onHide={handleCloseHighlightCitation}
                        size="xl"
                        centered
                        className="modal-citation-container"
                        dialogClassName="modal-highlight-citation"
                        scrollable
                    >
                        <Modal.Header
                            closeButton
                            // style={{ background: '#e5e7eb' }}
                        >
                            <Modal.Title>Các nội dung sửa đổi, hướng dẫn</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Accordion defaultActiveKey="0">
                                {noteMetadatasHighlight &&
                                    noteMetadatasHighlight.map((meta: any, index: any) => (
                                        <>
                                            {meta.guide && (
                                                <Accordion.Item key={index} eventKey={index}>
                                                    <Accordion.Header>{meta.guide}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col lg={6} md={12} className="accordionColMobile">
                                                                <div>
                                                                    <div className={styles.cardHeaderContent}>Nội dung gốc</div>
                                                                    <div className={styles.cardHeaderHightlight}></div>

                                                                    <div className={styles.cardBodyContent}>
                                                                        <div className={styles.cardTextContent}>{meta.originalContent}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} md={12}>
                                                                <div>
                                                                    <div className={styles.cardHeaderContent}>Nội dung sửa đổi, hướng dẫn</div>
                                                                    <div className={styles.cardHeaderHightlight}></div>

                                                                    <div className={styles.cardBodyContent}>
                                                                        <div className={styles.cardTextContent}>{meta.editContent}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                        </>
                                    ))}
                            </Accordion>
                        </Modal.Body>
                    </Modal>
                </div>
            ) : <div>Không tìm thấy nội dung</div>
            }
        </>
    );
};
