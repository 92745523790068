interface Props {
  color: string
}

export const SessionChatSvg = ({ color }: Props) => {

  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.5 19.5H8C4 19.5 2 18.5 2 13.5V8.5C2 4.5 4 2.5 8 2.5H16C20 2.5 22 4.5 22 8.5V13.5C22 17.5 20 19.5 16 19.5H15.5C15.19 19.5 14.89 19.65 14.7 19.9L13.2 21.9C12.54 22.78 11.46 22.78 10.8 21.9L9.3 19.9C9.14 19.68 8.77 19.5 8.5 19.5Z" stroke={color} strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /> <path d="M15.9965 11.5H16.0054" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M11.9955 11.5H12.0045" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M7.99451 11.5H8.00349" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>    </>
  );
};
