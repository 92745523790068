import { useEffect, useState, ChangeEvent } from "react";
import { Stack } from "@fluentui/react";
import styles from "./Answer.module.css";
import {
    TextField,
    DefaultButton,
} from "@fluentui/react";
import { IDraftContribute, IFeedback, likeUnlikeChatSession, saveFeedback } from "../../api";
import { getDislikeList, getLikeList } from "./Reason";


import close from "../../assets/close.png"
import { ModalContribute } from "./ModalContribute";
import showToasty from "../../hooks/useNotify";
import { useStore } from "../../hooks/useStore";


interface Props {
    messageId: string
    sanitizedAnswerHtml: any
    setOpenFeedback: any
    setOpenThankFeedback: any
    actionFeedback: string;
    setActionFeedback: React.Dispatch<React.SetStateAction<string>>;

    genQuery: string;
}

export const AnswerFeedback = ({ messageId, sanitizedAnswerHtml, setOpenFeedback, setOpenThankFeedback, actionFeedback, setActionFeedback, genQuery }: Props) => {
    const {
        rootStore: { sessionChatsStore },
    } = useStore();
    const [anotherReason, setAnotherReason] = useState<string>("")
    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
    const [openContribute, setOpenContribute] = useState<boolean>(false)
    const onReasonDislikeChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setAnotherReason(newValue || "");
    };
    const sendFeedback = async (data?: IFeedback) => {
        const feedbackAnswer = actionFeedback === 'contribute' ? { ...data } : {}
        const request: any = {
            like: actionFeedback === 'like' ? 1 : actionFeedback === 'dislike' ? 2 : 3,
            messageId: messageId,
            reasonDislike: actionFeedback === 'dislike' ? [...selectedReasons, anotherReason] : [],
            reasonLike: actionFeedback === 'like' ? [...selectedReasons, anotherReason] : [],

        }
        const result = actionFeedback === 'contribute' ? await saveFeedback({...data, isDraft: false, messageId: messageId}) : await likeUnlikeChatSession(request)
        if (actionFeedback === 'contribute') {
            showToasty("Gửi đóng góp thành công", 'success');
            if(result?.data) {
                sessionChatsStore.ADD_DRAFT(result?.data)
            }
        }
        setAnotherReason("")
        setSelectedReasons([])
        setOpenFeedback(false)

        setOpenThankFeedback(actionFeedback === 'contribute' ? false : true)
        setActionFeedback('')
    }
    const draftContribute = async (draft: IDraftContribute) => {
        const {data} = await saveFeedback(draft)
        sessionChatsStore.ADD_DRAFT(data)

    }
    const saveDraftSucces = () => {
        showToasty("Lưu bản nháp thành công", 'success');
        closeFeedback()
    }
    const closeFeedback = () => {
        setAnotherReason("")
        setActionFeedback("")
        setSelectedReasons([])
        setOpenFeedback(false)
    }
    const onChangeProfileCheckbox = (
        ev?: ChangeEvent<HTMLInputElement>,
        reason?: string,
    ) => {
        const value = reason;
        const checked = ev?.target?.checked;
        if (checked && value) {
            let _selectedReasons = selectedReasons.slice()
            _selectedReasons.push(value);
            setSelectedReasons(_selectedReasons)
        } else {
            setSelectedReasons((prev) => prev.filter((x) => x !== value));
        }
        checkDisableSubmit()
    };
    const getProfileCheckboxes = () => {
        const Feedbacks = actionFeedback === 'like' ? getLikeList() : actionFeedback === 'dislike' ? getDislikeList() : [];
        const checkboxes = Feedbacks.map((feedback, ind) => (
            <li key={messageId + '_' + ind}>
                <input
                    type="checkbox"
                    id={messageId + feedback.id}
                    value={feedback.reason}
                    defaultChecked={false}
                    onChange={(ev: any) => onChangeProfileCheckbox(ev, feedback.reason)}
                >
                </input>
                <label htmlFor={messageId + feedback.id}>
                    <span style={{ lineHeight: '24px' }}>
                        {feedback.name}
                    </span>
                </label>
            </li>
        ));
        return checkboxes;
    };

    const reasonsDom = () => {
        const dom = <div className={styles.expMembers}>
            <ul>
                {getProfileCheckboxes()}
            </ul>
        </div>
        return dom
    }
    const checkDisableSubmit = () => {
        const result = (anotherReason || selectedReasons.length > 0) ? false : true
        return result
    }
    let isDisable = checkDisableSubmit()

    useEffect(() => {

        if (actionFeedback === 'contribute') {
            setOpenContribute(true)
        } else {
            setOpenContribute(false)
        }
    }, [actionFeedback])

    return (

        <>
            {actionFeedback !== 'contribute' ?
                <Stack className={styles.feedbackContainer}>
                    {
                        actionFeedback !== 'contribute' ?
                            <>
                                <Stack.Item className={styles.boxFeedHeader}>
                                    <div>
                                        <span className={`${styles.textFeedTitle}`}>
                                            {
                                                actionFeedback === 'like' ? 'Bạn có muốn đóng góp dữ liệu hỏi đáp (FAQ) không?' : 'Tại sao bạn đánh giá như vậy?'
                                            }
                                        </span>
                                        <span className={`${styles.textFeedTitleDes}`}>{` (không bắt buộc)`}</span>
                                    </div>
                                    <div className={styles.closeIconBox} onClick={closeFeedback}>
                                        <img className={styles.closeIcon} src={close} />
                                    </div>
                                </Stack.Item>
                                {
                                    actionFeedback === 'dislike' ?
                                        <>
                                            <Stack.Item className={styles.boxCheckbox}>
                                                {reasonsDom()}
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField
                                                    className={`${styles.chatSettingsSeparator} textfieldCustom`}
                                                    defaultValue={anotherReason}
                                                    value={anotherReason}
                                                    label="Lý do khác"
                                                    placeholder='Đưa ra lý do khác'
                                                    multiline
                                                    autoAdjustHeight
                                                    onChange={onReasonDislikeChange}
                                                />
                                            </Stack.Item>
                                        </>
                                        : null
                                }
                                {
                                    actionFeedback !== 'contribute' ?
                                        <Stack.Item className={`${styles.buttonContribute}`}>
                                            <DefaultButton className='buttonConfirm'
                                                onClick={() => setActionFeedback('contribute')}
                                            >
                                                Đóng góp ngân hàng câu hỏi (FAQ)
                                            </DefaultButton>
                                        </Stack.Item> : null
                                }
                                {
                                    actionFeedback === 'dislike' ?
                                        <Stack.Item className={`${styles.buttonFeedback}`}>
                                            <DefaultButton disabled={isDisable} className='buttonConfirm'
                                                onClick={() => sendFeedback()}
                                            >Gửi</DefaultButton>

                                        </Stack.Item> : null
                                }
                            </> : null
                    }
                </Stack> : null

            }
            <ModalContribute
                messageId={messageId}
                sanitizedAnswerHtml={sanitizedAnswerHtml}
                openContribute={openContribute}
                genQuery={genQuery}
                sendFeedback={(request: IFeedback) => sendFeedback(request)}
                draftContribute={(draft) => draftContribute(draft)}
                closeModalContribute={saveDraftSucces}
                closeFeedback={closeFeedback}
            />
        </>

    );
};
