import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { useStore } from "../../../hooks/useStore";
import _ from 'lodash';
import { observer } from "mobx-react-lite";
import { ITrackSearch } from "../../../api/models";
import { TransitionSearching } from "./TransitionSearching";
import "./answer.css";


export const GenerateSearching = observer(() => {
    const {
        rootStore: { sessionChatsStore },
    } = useStore();
    const [trigger, setTrigger] = useState<any>([]);

    function groupBy<T extends ITrackSearch>(results: T[]): T[][] {
        const groupedResults: T[][] = [];
        const names = new Set(results.map((result) => result.name));
      
        for (const name of names) {
          const resultsForName = results.filter((result) => result.name === name);
          groupedResults.push(resultsForName);
        }
      
        return groupedResults;
      }

      
    useEffect(() => {
        if(sessionChatsStore.track_searchs && sessionChatsStore.track_searchs.length) {
            const groupedResults = groupBy(sessionChatsStore.track_searchs);
            setTrigger(groupedResults)
        } 
    }, [sessionChatsStore.track_searchs])

    
    return (
        <Stack>
            <Stack.Item className="box-transition">
                {trigger && trigger.length ? trigger.map((trackSearch: ITrackSearch[]) => (
                    <TransitionSearching trackSearch={trackSearch}/> 
                )) : null}
            </Stack.Item>
        </Stack>

    );
});
