import { useEffect, useRef, useState } from "react";
import { Modal, Form, Alert } from 'react-bootstrap';
import "react-tooltip/dist/react-tooltip.css";
import styles from "./PromptModel.module.css";
import { Dialog, DialogFooter, Label, PrimaryButton, TextField, Button } from "@fluentui/react";


interface Props {
    promptArr?: any;
    makeApiDefaultPrompt?: (p: string) => void
    opentPopupConfirm?: boolean
    setOpenPopupConfirm: any
    passwordTextError?: any
    setPasswordTextError: any
}

export const Password = ({
    opentPopupConfirm,
    setOpenPopupConfirm,
    makeApiDefaultPrompt,
    passwordTextError,
    setPasswordTextError }: Props) => {
    const [passwordConfirm, setPasswordConfirm] = useState<string>("")

    const onPasswordConfirmChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPasswordConfirm(newValue || "");
        setPasswordTextError(newValue ? undefined : "Mật khẩu là bắt buộc")
    };
    const handleClosePopup = () => {
        setPasswordTextError(""),
            setPasswordConfirm(""),
            setOpenPopupConfirm(false)
    }

    return (
        <>
            <div className="">
                <Dialog isOpen={opentPopupConfirm} onDismiss={handleClosePopup}
                    styles={{
                        main: {
                            selectors: {
                                ['@media (min-width: 480px)']: {
                                    width: 500,
                                    minWidth: 300,
                                    maxWidth: '1000px',
                                }
                            }
                        }
                    }}
                >
                    <div className="5555">
                        <p style={{ fontSize: "20px", fontWeight: "600", marginTop: "0px" }}>Xét Prompt mặc định</p>
                        <TextField
                            className={styles.chatSettingsSeparator}
                            defaultValue={""}
                            label="Mật khẩu"
                            type='password'
                            autoComplete="new-password"
                            required={true}
                            errorMessage={passwordTextError}
                            autoAdjustHeight
                            onChange={onPasswordConfirmChange}
                        />
                        {/* <span style={{ fontSize: "14px", color: "#bc3433" }}>{passwordTextError}</span> */}

                    </div>
                    <DialogFooter>
                        {makeApiDefaultPrompt &&

                            <PrimaryButton disabled={!passwordConfirm ? true : false} onClick={() => makeApiDefaultPrompt(passwordConfirm)} text="Cập nhật" />
                        }
                        <Button onClick={handleClosePopup} text="Đóng" />
                    </DialogFooter>
                </Dialog>
            </div>
        </>
    )
};
