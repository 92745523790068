
import { replaceDomain } from '../../utils/openWindowUrl';


interface Props {
    answer: any

}
function PopupLawInfo({ answer }: Props) {


    return (
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-x-4 pb-2 text-gray-800 text-sm">
            {answer.documentCode && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Số hiệu văn bản:</strong> {answer.documentCode}</span>
                </div>
            )}
            {answer.agencyIssued && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Cơ quan ban hành:</strong> {answer.agencyIssued}</span>
                </div>
            )}
            {answer.passedDate && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Ngày thông qua:</strong> {answer.passedDate}</span>
                </div>
            )}
            {answer.documentType && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Loại văn bản:</strong> {answer.documentType}</span>
                </div>
            )}
            {answer.publicationDate && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Ngày công báo:</strong> {answer.publicationDate}</span>
                </div>
            )}
            {answer.signedBy && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Người ký:</strong> {answer.signedBy}</span>
                </div>
            )}
            {answer.dateIssued && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Ngày ban hành:</strong> {answer.dateIssued}</span>
                </div>
            )}
            {answer.field && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Lĩnh vực:</strong> {answer.field}</span>
                </div>
            )}
            {answer.decision && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Quyết định:</strong> {answer.decision}</span>
                </div>
            )}
            {answer.legalRelation && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Quan hệ pháp luật:</strong> {answer.legalRelation}</span>
                </div>
            )}
            {answer.courtType && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Tòa án:</strong> TAND {answer.courtType}</span>
                </div>
            )}
            {answer.level && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Cấp:</strong> {answer.level}</span>
                </div>
            )}
            {answer.expirationDate && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Ngày hết hiệu lực:</strong> {answer.expirationDate}</span>
                </div>
            )}
            {answer.totalArticles && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Tổng số điều:</strong> {answer.totalArticles}</span>
                </div>
            )}
            {answer.judgmentDate && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Ngày công bố:</strong>Ngày công bố: {answer.judgmentDate}</span>
                </div>
            )}
            {answer.applyDate && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Ngày áp dụng:</strong> {answer.applyDate}</span>
                </div>
            )}
            {answer.validityStatus && (
                <div className="mb-2">
                    <span className="font-medium block sm:inline"><strong>Tình trạng hiệu lực:</strong> {answer.validityStatus}</span>
                </div>
            )}
            <div className="col-span-2">
                <a href={replaceDomain(answer.url)} target="_blank" className="my-2 text-indigo-700 underline text-base"
                >Xem văn bản web</a
                >
            </div>
        </div>

    );
}

export default PopupLawInfo;